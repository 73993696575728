import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

import Point from '../Point';

import { PricingPackageSellingPointType } from '../../types';

const useStyles = makeStyles(theme => ({
    pointContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            margin: theme.spacing(1.2, 0, 0, 0)
        },
    }
}));

interface Props {
    points: Array<PricingPackageSellingPointType>;
}

const Points = ({ points }: Props): JSX.Element => {
    const classes = useStyles();

    return points.length > 0 ? (
        <Box className={classes.pointContainer}>
            {
                points.map((point) => <Point key={point.id} text={point.text} />)
            }
        </Box>
    ) : (
        <></>
    );
};

export default Points;
