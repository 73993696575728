import React from 'react';
import intl from 'react-intl-universal';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useBrandConfigurationContext } from '../../../common/hooks';

const useStyles = makeStyles((theme) => ({
    button: {
        ...theme.typography.button,
    }
}));

interface Props {
    isFree: boolean;
    stripePayed: boolean;
    openStripe?: () => void;
    updated: boolean;
}

const DirectoryFormCompleted = ({ isFree, stripePayed, openStripe, updated }: Props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const brandConfig = useBrandConfigurationContext();

    const handleUserDirectories = () => {
        history.push('/directory/update');
    };

    const handleDirectoryHome = () => {
        history.push('/directory');
    };

    const handleOpenStripe = () => {
        openStripe && openStripe();
    };

    const { companyName } = brandConfig;
    const currentCompanyName = companyName ? companyName : 'Racing Edge';

    const titleCreated = intl.get('directory.form.completed.titleCreated').d('Directory Created');
    const titleUpdated = intl.get('directory.form.completed.titleUpdated').d('Directory Updated');
    const description = intl.get('directory.form.completed.description', { companyName: currentCompanyName }).d(`Thank you for using ${currentCompanyName} Directory!`);

    const paymentNotReceived = intl.get('directory.form.completed.paymentNotReceived').d('Your directory will not benefit from the featured benefits until a payment has been made. You may pay now or at a later time by updating this directory.');
    const paymentReceived = intl.get('directory.form.completed.paymentReceived').d('Your payment has been received.');

    const actionPay = intl.get('directory.form.completed.action.pay').d('Make Payment');
    const actionMyDirectories = intl.get('directory.form.completed.action.myDirectories').d('My Directories');
    const actionHome = intl.get('directory.form.completed.action.home').d('Directory Home');

    const title = updated ? titleUpdated : titleCreated;

    let extra = <></>;
    if (!isFree) {
        // This is a paid directory
        if (!stripePayed) {
            // Payment has not been received
            extra = (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">{paymentNotReceived}</Typography>
                    <Box>
                        <Button className={classes.button} color="secondary" variant="contained" onClick={handleOpenStripe}>{actionPay}</Button>
                    </Box>
                </Box>
            );
        } else {
            // Payment has been received
            extra = (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">{paymentReceived}</Typography>
                </Box>
            );
        }
    }

    return (
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant="h2">{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body1">{description}</Typography>
                    {extra}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} variant="contained" onClick={handleUserDirectories}>{actionMyDirectories}</Button>
                <Button className={classes.button} color="secondary" variant="contained" onClick={handleDirectoryHome}>{actionHome}</Button>
            </DialogActions>
        </Dialog>
    );
};


export default DirectoryFormCompleted;
