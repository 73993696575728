import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper, Typography } from '@mui/material';
import parse from 'html-react-parser';

import { ServiceInfoType } from '../../types';
import { getImageUrl } from '../../helpers';

const useStyles = makeStyles(theme => ({
    container: {
        // width: '100%',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        position: 'relative',
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 2)
        },
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        fontSize: 25

    },
    titleContainer: {
        // margin: theme.spacing(2, 0, 4, 0),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 2)
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    imageContainer: {
        width: '100%',
        maxWidth: 500,
        maxHeight: 300
    },
    sectionOne: {
        width: '100%',
        maxWidth: 600
    },
    sectionTwo: {
        // width: '100%',
        // maxWidth: 600
    },
    descriptionContainer: {
        // maxWidth: 400
    },
    priceContainer: {
        borderRadius: 10,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        width: '100%',
        maxWidth: 100,
        height: 40,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    price: {
        fontWeight: 'bold',
        fontSize: 21
    },
    priceFrequency: {
        fontWeight: 'bold',
        fontSize: 13
    },
    accent: {
        backgroundColor: theme.palette.primary.main,
        width: 12,
        height: '100%',
        position: 'absolute'
    },
    left: {
        top: 0,
        left: 0
    },
    right: {
        top: 0,
        right: 0
    }
}));

type Props = ServiceInfoType;

const Service = ({ title, description, image, price, priceFrequency, isRight }: Props): JSX.Element => {
    const classes = useStyles();
    const imageSource = image && getImageUrl(image, 'small');

    const sectionOne = (
        <Box className={classes.sectionOne}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>{title}</Typography>
                {
                    price && priceFrequency && (
                        <Box className={classes.priceContainer}>
                            <Typography className={classes.price} variant="h3">${price}</Typography>
                            <Typography className={classes.priceFrequency} variant="h5">&nbsp;/{priceFrequency}</Typography>
                        </Box>
                    )
                }
            </Box>
            <Box className={classes.descriptionContainer}>
                <Typography variant="body2">
                    { parse(description) }
                </Typography>
            </Box>
        </Box>
    );

    const sectionTwo = (
        <Box className={classes.sectionTwo}>
            {
                imageSource && (
                    <Box className={classes.imageContainer}>
                        <img className={classes.image} src={imageSource} alt={image?.alternativeText || ''} />
                    </Box>
                )
            }
        </Box>
    );

    return (
        <Paper className={classes.container} square>
            <Box className={`${classes.accent} ${isRight ? classes.right : classes.left}`} />
            {
                isRight ? (
                    <>
                        {
                            sectionTwo
                        }
                        {
                            sectionOne
                        }
                    </>
                ) : (
                    <>
                        {
                            sectionOne
                        }
                        {
                            sectionTwo
                        }
                    </>
                )
            }
        </Paper>
    );
};

export default Service;
