import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import LimegreenLabel from '../../../common/components/LimegreenLabel';


import { PriceType } from '../../../common/types';
import { localizeCurrency } from '../../../common/helpers';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2),
        alignItems: 'center',
        textAlign: 'center',
        background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 3px 3px #00000029',
        border: '1px solid #EBEBEB',
        borderRadius: '10px',
    },
    totalBids: {
        color: theme.palette.grey[500],
        [theme.breakpoints.down('xl')]: {
            fontSize: 9
        }
    },
    item: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        flexDirection: 'column',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0.5, 0, 0, 0)
        },
    },
    currency: {
        fontSize: 10,
        color: theme.palette.grey[500],
    },
    price: {
        fontSize: 16,
        fontWeight: 'bold',
        [theme.breakpoints.down('xl')]: {
            fontSize: 13
        }
    },
    stretch: {
        flex: '0 0 165px'
    },
    priceContainer: {
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'row',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 0.5)
        },
    }
}));

interface Props {
    price: PriceType;
    totalBids: number;
}

const CardPrice = ({ price, totalBids }: Props): JSX.Element => {
    const classes = useStyles();

    const formattedPrice = useMemo(() => localizeCurrency(price.amount, price.currency), [price.amount, price.currency]);

    const currentBidText = intl.get('auction.page.currentBid').d('Current Bid');
    const startPriceText = intl.get('auction.page.startPrice').d('Starting Bid');
    const totalBidsText = intl.get('auction.page.totalBids').d('Total Bids');

    let bidText = currentBidText;
    if (!!price.amount && totalBids === 0) {
        bidText = startPriceText;
    }

    return (
        <Box className={classes.paper}>
            <Box className={classes.item}>
                <LimegreenLabel text={bidText} pill />
                <Box className={classes.priceContainer}>
                    <Typography className={classes.currency}>{price.currency}</Typography>
                    <Typography className={classes.price}>{formattedPrice}</Typography>
                </Box>
            </Box>
            <Box className={classes.item}>
                <Typography className={classes.totalBids} variant="overline">{totalBidsText}</Typography>
                <Typography className={classes.price}>{totalBids}</Typography>
            </Box>
        </Box>
    );
};

export default CardPrice;
