import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Dedupe as DedupeIntegration, Transaction as TransactionIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import App from './App';
import Initialization from './modules/common/containers/Initialization';
// import ErrorMessage from './modules/common/components/ErrorMessage';
import MaintenanceMessage from './modules/common/components/MaintenanceMessage';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import packageJson from '../package.json';
import Helmet from 'react-helmet';
import { IconContext } from 'react-icons';

const appVersion = packageJson.version;


const theme = createTheme();

// Initialize Sentry Report Tracking
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `racing-edge@${appVersion}`,
    integrations: [
        new Integrations.BrowserTracing(),
        new DedupeIntegration(),
        new TransactionIntegration()
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'development'
});

ReactDOM.render(
    <React.StrictMode>
        <Helmet>
            <meta property="og:title" content={process.env.REACT_APP_META_TITLE} />
            <meta property="og:image" content={process.env.REACT_APP_META_IMAGE} />
            <meta property="og:description" content={process.env.REACT_APP_META_DESCRIPTION} />
        </Helmet>
        <Sentry.ErrorBoundary
            showDialog
            fallback={<MaintenanceMessage />}
            beforeCapture={(scope) => {
                // Tag the Error boundary to know where it came from
                scope.setTag('location', 'Init');
            }}
        >
            {/* With Material UI v5 Alpha, we need to make sure our injection order of CSS is correct. */}
            <ThemeProvider theme={theme}>
                <IconContext.Provider value={{
                    style: {
                        display: 'inline-block',
                        fill: 'currentColor',
                        fontSize: '1.5rem',
                        height: '1em',
                        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1)',
                        userSelect: 'none',
                        width: '1em'
                    }
                }}>
                    <Initialization>
                        <App />
                    </Initialization>
                </IconContext.Provider>
            </ThemeProvider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// Completely remove service worker from browser. This is causing issues with caching.
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (const registration of registrations) {
            registration.unregister();
        }
    }).catch(function(err) {
        // eslint-disable-next-line no-console
        console.log('Service Worker registration failed: ', err);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
