import React from 'react';
import { Divider, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Container from '../Container';
// import Breadcrumbs from '../Breadcrumbs';
import PageTitle from '../PageTitle';
import InBeta from '../InBeta';

const useStyle = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(3),
        height: 2,
        backgroundColor: theme.palette.divider,
        borderBottomWidth: 'inherit'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 2)
        }
    }
}));

interface Props {
    label: string;
    subtext?: string;
    sellingPoint?: string;
    beta?: boolean;
}

const PageHeader = ({ beta = false, ...rest }: Props): JSX.Element => {
    const classes = useStyle();

    return (
        <Box sx={{ mt: 3 }}>
            <Container>
                <Grid container spacing={2} >
                    {/* <Grid item xs={12}>
                        <Breadcrumbs />
                    </Grid> */}
                    <Grid item xs={12} >
                        <Box className={classes.titleContainer}>
                            <PageTitle {...rest} />
                            {
                                beta && (
                                    <InBeta />
                                )
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Divider classes={{ root: classes.divider }} variant="fullWidth" />
        </Box>
    );
};

export default PageHeader;
