import React, { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { matchSorter } from 'match-sorter';

import { CountryStateType, StateType } from '../../../types';

type Props = TextFieldProps & {
    id: string;
    label: string;
    name: string;
    register: (data: any) => void;
    control: any;
    disabled?: boolean;
}

const AutocompleteStateField = ({ label, name, register, control, id, disabled = false, ...rest }: Props): JSX.Element => {
    const selectedCountry = useWatch<CountryStateType>({
        control,
        name: 'country'
    });

    const states = useMemo(() => selectedCountry?.states || [], [selectedCountry]);
    const filterStates = (options: Array<StateType>, { inputValue }: any) => matchSorter(options, inputValue, { keys: ['state_code', 'name'] });

    return states.length > 0 ? (
        <Controller
            render={(props) => (
                <Autocomplete
                    {...props}
                    id={id}
                    disabled={disabled}
                    disableClearable={disabled}
                    autoHighlight
                    options={states}
                    getOptionLabel={(option) => (option.name || '')}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    filterOptions={filterStates}
                    filterSelectedOptions
                    onChange={(_, data) => {
                        props.onChange(data);
                    }}
                    value={props.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...rest}
                            label={label}
                            disabled={disabled}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'disabled'
                            }}
                        />
                    )}
                />
            )}
            name={name}
            control={control}
            defaultValue={states.length > 0 ? states[0] : ''}
        />
    ) : (
        <TextField
            id={id}
            inputRef={register}
            label={label}
            name={name}
            {...rest}
        />
    );
};

export default AutocompleteStateField;
