import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Box, BoxProps, Button } from '@mui/material';

interface Props extends BoxProps {
    isCollapsedByDefault?: boolean;
    children: React.ReactNode;
}

const PreviewCollapse = ({ isCollapsedByDefault = true, children, ...rest }: Props): JSX.Element => {
    const [isCollapse, setIsCollapse] = useState(isCollapsedByDefault);

    const showPreviewText = intl.get('generic.action.showPreview').d('Show Preview');
    const hidePreviewcloseText = intl.get('generic.action.hidePreview').d('Hide Preview');

    return (
        <Box sx={{ width: '100%' }} {...rest}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} >
                <Button
                    size="small"
                    color="secondary"
                    type="button"
                    onClick={() => {
                        setIsCollapse(v => !v);
                    }}
                >
                    { isCollapse ? hidePreviewcloseText : showPreviewText}
                </Button>
            </Box>
            {isCollapse && children}
        </Box>
    );
};

export default PreviewCollapse;
