import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, ButtonProps } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.button,
        width: '100%',
        // textTransform: 'capitalize',
        padding: theme.spacing(2, 0),
        fontFamily: 'Avenir',
        borderRadius: '0px',
        '& svg': {
            fontSize: theme.typography.button.fontSize,
            margin: theme.spacing(0, 1)
        },
    }
}));

type Props = ButtonProps;

const ActionButton = (props: Props): JSX.Element => {
    const classes = useStyles();
    return <Button classes={classes} {...props} />;
};

export default ActionButton;
