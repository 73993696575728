import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useMutation, useQuery } from '@apollo/client';
import { IconButton, Tooltip } from '@mui/material';
import { IconMdBookmarkAdd, IconMdBookmarkRemove, } from '../../../../Icons';

import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';
import ActionButton from '../../../common/components/ActionButton';

import { useSocketContext } from '../../hooks/useSocketContext';
import { useAuthenticatedUserContext, useNotification } from '../../../common/hooks';
import { MUTATION_WATCH_AUCTION } from '../../mutations';
import { QUERY_AUCTION } from '../../queries';
import { AuctionDataType } from '../../types';

interface Props {
    auctionId?: string;
    onWatch?: () => void;
    iconButton?: boolean;
}

const WatchlistIconButton = ({ auctionId, onWatch, iconButton = true }: Props): ReactElement => {
    const { auction } = useSocketContext();
    const { user } = useAuthenticatedUserContext();
    const notify = useNotification();
    const [watched, setWatched] = useState<boolean>(false);

    const [watchAuction, { loading, error }] = useMutation(MUTATION_WATCH_AUCTION);
    const { loading: loadingAuction, error: errorAuction, data: dataAuction } = useQuery<AuctionDataType>(QUERY_AUCTION, {
        variables: {
            id: auctionId
        },
        skip: !auctionId,
    });

    // Select the auction id from the correct variable
    const auctId = auction ? auction.id : auctionId;

    useEffect(() => {
        // Check to see if we have an auction
        if (auction) {
            // fetch the current watchlist on the auction
            const { watchlist } = auction;
            // Check to see if we have a watchlist and a currently logged in user
            if ((watchlist && watchlist.length > 0) && user) {
                // Try to match the email from the user with an email on the watchlist
                // If the users email is on the watch list, it means they have watched this auction
                setWatched(!!watchlist.find((u) => u.id === user.id));
            }
        } else if (dataAuction && !loadingAuction && !errorAuction) {
            const { auction: dAuction } = dataAuction;
            if (dAuction) {
                // fetch the current watchlist on the auction
                const { watchlist } = dAuction;
                // Check to see if we have a watchlist and a currently logged in user
                if ((watchlist && watchlist.length > 0) && user) {
                    // Try to match the email from the user with an email on the watchlist
                    // If the users email is on the watch list, it means they have watched this auction
                    setWatched(!!watchlist.find((u) => u.id === user.id));
                }
            }
        }
    }, [auction, dataAuction, errorAuction, loadingAuction, user]);

    const handleClick = useCallback(async() => {
        if (user) {
            try {
                if (watched) {
                    await watchAuction({
                        variables: {
                            id: auctId,
                            userId: user.id,
                            watch: false
                        }
                    });
                    setWatched(false);
                } else {
                    await watchAuction({
                        variables: {
                            id: auctId,
                            userId: user.id,
                            watch: true
                        }
                    });
                    setWatched(true);
                }
            } catch (err: any) {
                const message = intl.get('auction.watchList.error').d('Something went wrong!');
                notify({ message, severity: 'error' });
            } finally {
                onWatch && onWatch();
            }
        }
    }, [user, watched, watchAuction, auctId, notify, onWatch]);

    if (error) {
        const message = intl.get('auction.watchList.error').d('Something went wrong!');
        notify({ message, severity: 'error' });
    }

    const watchlistText = intl.get('auction.watchList').d('Watchlist');
    const addWatchlistText = intl.get('auction.watchList.add').d('Add to Watchlist');
    const removeWatchlistText = intl.get('auction.watchList.remove').d('Remove from Watchlist');
    const displayText = watched ? removeWatchlistText : addWatchlistText;

    return iconButton ? (
        <CheckpointIsAuthenticated fullWidth={false}>
            <Tooltip enterTouchDelay={50} title={displayText}>
                <IconButton
                    sx={{ padding: 0, pr: 1 }}
                    onClick={handleClick}
                    aria-label={watchlistText}
                    disabled={loading}
                    size="large">
                    {watched
                        ? <IconMdBookmarkRemove />
                        : <IconMdBookmarkAdd />
                    }
                </IconButton>
            </Tooltip>
        </CheckpointIsAuthenticated>
    ) : (
        <CheckpointIsAuthenticated>
            <ActionButton
                id="auction-button-watchlist"
                fullWidth
                color="secondary"
                variant="contained"
                onClick={handleClick}
                disabled={loading}
                startIcon={watched ? <IconMdBookmarkRemove /> : <IconMdBookmarkAdd />}
            >
                {displayText}
            </ActionButton>
        </CheckpointIsAuthenticated>
    );
};

export default WatchlistIconButton;
