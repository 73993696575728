import React from 'react';
import { Chip, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { CurrentRefinementsProvided } from 'react-instantsearch-core';

import { removeWords, replaceUnderscoreAndLowercase } from '../../helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        textTransform: 'capitalize',
        [theme.breakpoints.down('xl')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: theme.spacing(0),
            marginTop: theme.spacing(0.5)
        }
    },
    chip: {
        margin: theme.spacing(0.5),
    }
}));

const removeFromLabel = ['address.', 'extraDetails.', '.value'];

type Props = CurrentRefinementsProvided;

const CurrentRefinements = ({ items, refine }: Props): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    if (items && items.length <= 0) {
        return <></>;
    }

    return (
        <Paper component="ul" className={classes.root} elevation={0}>
            {items.map(item => {
                const removedWordsFromLabel = removeWords(item.label, removeFromLabel);
                const correctedLabel = replaceUnderscoreAndLowercase(removedWordsFromLabel);
                return (
                    <li key={isMobile ? `${item.label}-mobile` : item.label}>
                        {item.items ? (
                            <React.Fragment>
                                {
                                    item.items.map(nested => {
                                        const correctedNestLabel = replaceUnderscoreAndLowercase(nested.label);
                                        return (
                                            <Chip
                                                key={isMobile ? `${nested.label}-mobile` : nested.label}
                                                className={classes.chip}
                                                label={`${correctedLabel} ${correctedNestLabel}`}
                                                size={isMobile ? 'small' : 'medium'}
                                                onDelete={event => {
                                                    event.preventDefault();
                                                    refine(nested.value);
                                                }}
                                            />
                                        );
                                    })
                                }
                            </React.Fragment>
                        ) : (
                            <Chip
                                key={isMobile ? `${item.label}-mobile` : item.label}
                                className={classes.chip}
                                label={correctedLabel}
                                size={isMobile ? 'small' : 'medium'}
                                onDelete={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}
                            />
                        )}
                    </li>
                );
            })}
        </Paper>
    );
};

export default React.memo(connectCurrentRefinements(CurrentRefinements));
