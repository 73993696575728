import React from 'react';
import { IconButton } from '@mui/material';
import { IconMdClose, } from '../../../../Icons';
import { useSnackbar } from 'notistack';

import { SnackbarNotificationFunctionType, SnackbarNotificationType } from '../../types';


// Notifies the user with a snackbar message
const useNotification = (): SnackbarNotificationFunctionType => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = (key) => (
        <>
            <IconButton
                onClick={() => closeSnackbar(key)}
                color="inherit"
                aria-label="delete"
                size="large">
                <IconMdClose />
            </IconButton>
        </>
    );

    const notify = ({ message, severity, vertical = 'top', horizontal = 'right', persist = false, duration = 2000 }: SnackbarNotificationType) => {
        enqueueSnackbar(message, {
            variant: severity,
            preventDuplicate: true,
            persist,
            anchorOrigin: {
                vertical,
                horizontal
            },
            autoHideDuration: duration,
            action
        });
    };

    return notify;
};

export default useNotification;
