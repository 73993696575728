import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Box, Typography, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TransitionGroup } from 'react-transition-group';

import BidHistoryItem from './Item';
import BidButton from '../../../common/components/Button';
import BidModal from '../BidModal';
import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';

import { useSocketContext } from '../../hooks/useSocketContext';
import { AUCTION_STATUS } from '../../constants';
import AuctionRedirectToBrand from '../AuctionRedirectToBrand';

const useStyles = makeStyles(theme => ({
    box: {
        border: '1px solid #BCBCBC',
        borderRadius: '10px',
        padding: theme.spacing(4)
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        fontSize: 20,
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        // textTransform: 'capitalize',
        [theme.breakpoints.down('xl')]: {
            fontSize: 16
        }
    },
    historyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(1, 0, 0, 0)
        },
    },
    button: {
        padding: theme.spacing(2, 5),
        backgroundColor: 'green',
        [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(1, 3),
        }
    },
    titleContainer: ({ enableBid }: CSSPropsType) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `${enableBid ? '1px solid #BCBCBC' : 'none'}`,
        paddingBottom: `${enableBid ? theme.spacing(1) : '0'}`,
        marginBottom: `${enableBid ? theme.spacing(2) : '0'}`
    })
}));

type CSSPropsType = {
    enableBid: boolean;
}

interface Props {
    limit?: number;
    enableBid?: boolean;
}

const BidHistory = ({ limit = 5, enableBid = false }: Props): JSX.Element => {
    const classes = useStyles({ enableBid });
    const { bids, currency, auction } = useSocketContext();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const bidHistoryText = intl.get('auction.bidHistory.label').d('Bid History');
    const noBidsText = intl.get('auction.bidHistory.noBids').d('There are currently no bids');
    const bidNowText = intl.get('auction.bidNow').d('Bid Now');

    // assign bids to new array so we can manipulate without affecting state
    const bidsForSorting = [...bids];

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const onBid = () => {
        setIsModalOpen(true);
    };

    return (
        <Box className={classes.box}>
            <Box className={classes.titleContainer}>
                <Box sx={{ flex: '0 60%' }}>
                    <Typography className={classes.title}>{bidHistoryText}</Typography>
                </Box>
                {
                    enableBid && auction && (
                        <Box sx={{ flex: '0 40%' }}>
                            <CheckpointIsAuthenticated>
                                <AuctionRedirectToBrand slug={auction.slug} title={auction.title} brand={auction.brand}>
                                    <BidButton
                                        id={auction.id}
                                        className={classes.button}
                                        fullWidth
                                        disabled={auction.status !== AUCTION_STATUS.LIVE}
                                        text={bidNowText}
                                        onClick={onBid}
                                    />
                                </AuctionRedirectToBrand>
                            </CheckpointIsAuthenticated>
                        </Box>
                    )
                }
            </Box>
            <Box>
                {
                    bids && bids.length > 0 ? (
                        <TransitionGroup className={classes.historyContainer}>
                            {
                                // filter the bids so its limited to 5, sorted by newest.
                                bidsForSorting.sort((a, b) => (
                                    b.amount - a.amount
                                )).slice(0, limit).map((bid) => <Collapse key={bid.id}><BidHistoryItem bid={bid}
                                    currency={currency}/></Collapse>)
                            }
                        </TransitionGroup>
                    ) : (
                        <Typography key={'no-bids-currently'} variant="h3">{noBidsText}</Typography>
                    )
                }
            </Box>
            {
                isModalOpen && <BidModal onClose={handleClose}/>
            }
        </Box>
    );
};

export default React.memo(BidHistory);
