import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Box, Fab, useMediaQuery, useScrollTrigger, Zoom } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconMdKeyboardArrowUp, } from '../../../../Icons';

interface Props {
    children: React.ReactNode;
}

const ScrollTop = ({ children }: Props): ReactElement => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Zoom>
    );
};

const BackToTop = (): ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    const backToTopText = intl.get('generic.fab.backToTop').d('Scroll back to top');

    return (
        <ScrollTop>
            <Fab color="primary" size={isMobile ? 'small' : 'large'} aria-label={backToTopText}>
                <IconMdKeyboardArrowUp />
            </Fab>
        </ScrollTop>
    );
};

export default BackToTop;
