import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { IconMdAccountCircle, IconMdStar, IconMdGavel, IconMdList, IconMdPayment, } from '../../../../Icons';
import makeStyles from '@mui/styles/makeStyles';

import { modifyColour } from '../../../common/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    gridItem: {
        '&.row': {
            display: 'flex',
        },
    },
    navItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    redBar: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '4px',
        backgroundColor: theme.palette.primary.main,
        '&.row': {
            left: 0,
            bottom: 'unset',
            width: 'unset',
            height: '4px',
        },
    },
    icon: {
        color: modifyColour(theme.palette.secondary.contrastText, -75),
        '&.active': {
            color: theme.palette.secondary.contrastText,
        },
    },
}));

interface Props {
    direction?: 'row' | 'column';
}

const Navigation = ({ direction }: Props): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const dirClass = direction ? direction : '';

    const pages = [
        // {
        //     title: intl.get('dashboard.navigation.page.dashboard.title').d('Dashboard'),
        //     location: '/dashboard',
        //     exact: true,
        //     Icon: DashboardIcon,
        // },
        // {
        //     title: intl.get('dashboard.navigation.page.profile.title').d('Profile'),
        //     location: '/dashboard/profile',
        //     exact: true,
        //     Icon: AccountCircleIcon,
        // },
        {
            title: intl.get('dashboard.navigation.page.profile.title').d('Profile'),
            location: '/dashboard',
            exact: true,
            Icon: IconMdAccountCircle,
        },
        {
            title: intl.get('dashboard.navigation.page.listings.title').d('Listings'),
            location: '/dashboard/listings',
            exact: false,
            Icon: IconMdList,
        },
        {
            title: intl.get('dashboard.navigation.page.auctions.title').d('Auctions'),
            location: '/dashboard/auctions',
            exact: false,
            Icon: IconMdGavel,
        },
        {
            title: intl.get('dashboard.navigation.page.favourites.title').d('Favourites'),
            location: '/dashboard/favourites',
            exact: false,
            Icon: IconMdStar,
        },
        {
            title: intl.get('dashboard.navigation.page.transactions.title').d('Transactions'),
            location: '/dashboard/transactions',
            exact: false,
            Icon: IconMdPayment,
        }
    ];

    const handleClick = (path: string) => {
        history.push(path);
    };

    return (
        <Box className={`${classes.root} ${dirClass}`}>
            <Grid container direction={direction} alignItems="stretch" spacing={1}>
                {pages.map(page => {
                    // Determine if this page is active
                    const active = page.exact
                        ? location.pathname === page.location
                        : location.pathname.startsWith(page.location);
                    // Active class
                    const actClass = active ? ' active' : '';
                    // The icon to display
                    const { Icon } = page;
                    // Render the page navigation item
                    return (
                        <Grid key={page.location} item className={`${classes.gridItem} ${dirClass}`}>
                            <Box className={`${classes.navItem} ${dirClass}`}>
                                <Tooltip enterTouchDelay={50} title={page.title} placement="right">
                                    <IconButton
                                        className={`${classes.icon} ${dirClass} ${actClass}`}
                                        onClick={() => handleClick(page.location)}
                                        aria-label={page.title}
                                        size="large">
                                        <Icon />
                                    </IconButton>
                                </Tooltip>
                                {active && (
                                    <Box className={`${classes.redBar} ${dirClass}`} />
                                )}
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default Navigation;
