import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Typography
} from '@mui/material';

import { IconMdClose, } from '../../../../Icons';

const StyledDialog = withStyles({
    paper: {
        borderRadius: 0,
    },
})(MuiDialog);

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.secondary.contrastText,
    },
}));

interface Props {
    title: string;
    children: React.ReactNode;
    onClose: () => void;
    processing?: boolean;
    fullWidth?: boolean;
    fullScreen?: boolean;
}

const Dialog = ({ processing, fullWidth, title, children, onClose, fullScreen }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <StyledDialog open={true} onClose={onClose} fullWidth={!!fullWidth} fullScreen={!!fullScreen}>
            <DialogTitle className={classes.title}>
                <Typography variant="h3">{title}</Typography>
                {
                    onClose ? (
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                            size="large">
                            <IconMdClose />
                        </IconButton>
                    ) : null
                }
            </DialogTitle>
            {processing && <LinearProgress color="primary" />}
            <DialogContent>
                {children}
            </DialogContent>
        </StyledDialog>
    );
};

// Default export is the Dialog component
export default Dialog;
