import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';

import { login, loginByToken } from '../../request';
import { useLoggedIn, useNotification } from '../../hooks';
import { BRAND } from '../../constants';

const useStyles = makeStyles(theme => ({
    content: {
        marginTop: theme.spacing(4),
        textAlign: 'center'
    }
}));

interface Props {
    provider: string;
}

const ProviderRedirectPage = ({ provider }: Props): JSX.Element => {
    const classes = useStyles();
    const notify = useNotification();
    const apolloClient = useApolloClient();
    const [isLoggedIn] = useLoggedIn();
    const history = useHistory();
    const [error, setError] = useState<boolean>(false);
    const [hasFetched, setHasFetched] = useState<boolean>(false);

    const successText = intl.get('common.page.providerRedirect.success').d('You have been successfully authenticated. Please wait while we redirect you.');
    const errorText = intl.get('common.page.providerRedirect.error').d('We were unable to authenticate your account. Please try again.');

    useEffect(() => {
        const loginUser = async(fetchUrl: string) => {
            loginByToken(fetchUrl)
                .then((res: any) => {
                    const { jwt, user } = res;
                    if (jwt && jwt.length > 0 && user) {
                        login(jwt, user.id, apolloClient);
                        history.push('/');
                    }
                }).catch((err) => {
                    setError(true);
                });
        };

        const decodedQueryParams = decodeURIComponent(window.location.search);

        if (decodedQueryParams.indexOf('error_code') !== -1) {
            setError(true);
        }

        if (provider && !isLoggedIn && !error && !hasFetched) {
            setHasFetched(true);
            const url = `${process.env.REACT_APP_BACKEND_URL}/auth/${provider}/callback${window.location.search}&b=${BRAND}`;
            loginUser(url);
        }
    }, [history, isLoggedIn, notify, provider, error, hasFetched, apolloClient]);

    return (
        <Box className={classes.content}>
            {
                error ? (
                    <Typography variant="body1">
                        {errorText}
                    </Typography>
                ) : (
                    <Typography variant="body1">
                        {successText}
                    </Typography>
                )
            }
        </Box>
    );
};

export default ProviderRedirectPage;
