import React from 'react';
import intl from 'react-intl-universal';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    badge: {
        height: 30,
        background: '#58c777',
        width: 60,
        padding: theme.spacing(0.5, 1),
        boxShadow: 'inset 0px 0px 0px 4px rgba(255, 255, 255, 0.54)',
        borderRadius: 25,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center'
    },
    text: {
        color: '#fff',
        fontWeight: 'bold',
        lineHeight: '30px'
    }
}));

const InBeta = (): JSX.Element => {
    const classes = useStyles();

    const inBetaText = intl.get('generic.tag.beta').d('Beta');

    return (
        <Box className={classes.badge}>
            <Typography className={classes.text} variant="body1">{inBetaText}</Typography>
        </Box>
    );
};

export default InBeta;
