export {
    FaCcAmex as IconFaCcAmex,
    FaCcDinersClub as IconFaCcDinersClub,
    FaCcDiscover as IconFaCcDiscover,
    FaCcJcb as IconFaCcJcb,
    FaCcMastercard as IconFaCcMastercard,
    FaCcVisa as IconFaCcVisa,
    FaCreditCard as IconFaCreditCard,
    FaFacebook as IconFaFacebook,
    FaGoogle as IconFaGoogle,
    FaInstagram as IconFaInstagram,
    FaLinkedin as IconFaLinkedin,
    FaTwitter as IconFaTwitter,
    FaYoutube as IconFaYoutube,
} from 'react-icons/fa6';

export {
    MdAccessTime as IconMdAccessTime,
    MdAccountCircle as IconMdAccountCircle,
    MdAdd as IconMdAdd,
    MdArrowBackIos as IconMdArrowBackIos,
    MdArrowDownward as IconMdArrowDownward,
    MdArrowDropDown as IconMdArrowDropDown,
    MdArrowForward as IconMdArrowForward,
    MdArrowForwardIos as IconMdArrowForwardIos,
    MdArrowUpward as IconMdArrowUpward,
    MdBlock as IconMdBlock,
    MdBookmarkAdd as IconMdBookmarkAdd,
    MdBookmarkRemove as IconMdBookmarkRemove,
    MdBrightness1 as IconMdBrightness1,
    MdCall as IconMdCall,
    MdCancel as IconMdCancel,
    MdCheck as IconMdCheck,
    MdChevronLeft as IconMdChevronLeft,
    MdChevronRight as IconMdChevronRight,
    MdClose as IconMdClose,
    MdCloudUpload as IconMdCloudUpload,
    MdComment as IconMdComment,
    MdDashboard as IconMdDashboard,
    MdDelete as IconMdDelete,
    MdDescription as IconMdDescription,
    MdDone as IconMdDone,
    MdEdit as IconMdEdit,
    MdError as IconMdError,
    MdExitToApp as IconMdExitToApp,
    MdExpandMore as IconMdExpandMore,
    MdFormatListBulleted as IconMdFormatListBulleted,
    MdGavel as IconMdGavel,
    MdHelp as IconMdHelp,
    MdHome as IconMdHome,
    MdImage as IconMdImage,
    MdInfo as IconMdInfo,
    MdInsertChartOutlined as IconMdInsertChartOutlined,
    MdKeyboardArrowUp as IconMdKeyboardArrowUp,
    MdList as IconMdList,
    MdLockOpen as IconMdLockOpen,
    MdMail as IconMdMail,
    MdMenu as IconMdMenu,
    MdMoney as IconMdMoney,
    MdMoreHoriz as IconMdMoreHoriz,
    MdPayment as IconMdPayment,
    MdRefresh as IconMdRefresh,
    MdReply as IconMdReply,
    MdReport as IconMdReport,
    MdRoom as IconMdRoom,
    MdSchedule as IconMdSchedule,
    MdSettings as IconMdSettings,
    MdShare as IconMdShare,
    MdStar as IconMdStar,
    MdStarBorder as IconMdStarBorder,
    MdTune as IconMdTune,
    MdVerifiedUser as IconMdVerifiedUser,
} from 'react-icons/md';
