import React from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Link,
    Box,
    Container,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yupResolver } from '@hookform/resolvers/yup';

// import Container from '../../../components/Container';
import ForgotPasswordButton from '../../../components/Button';

import { schema } from './validation';
import { AuthenticationActionFunctionType, BrandThemeType } from '../../../types';
import { useNotification } from '../../../hooks';
import { VARIATIONS } from '../../../constants';
import { resendConfirmation } from '../../../request';

const useStyle = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        cursor: 'pointer',
        color: (configTheme: BrandThemeType) => {
            if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                return theme.palette.primary.main;
            } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                return theme.palette.secondary.main;
            }
            return theme.palette.primary.main;
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        paddingBottom: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    bar: {
        marginTop: theme.spacing(2),
        width: '70%',
        height: '4px',
        backgroundColor: theme.palette.divider,
    },
}));

type InputsType = {
    email: string;
}

interface Props {
    configTheme: BrandThemeType;
    onResendConfirmation?: () => void;
    switchAction: AuthenticationActionFunctionType;
    title?: string;
    subtitle?: string;
}

const ResendConfirmation = ({ configTheme, onResendConfirmation, title, subtitle, switchAction }: Props): JSX.Element => {
    const classes = useStyle(configTheme);
    const notify = useNotification();

    const { loginVariation } = configTheme;

    const inheritStyle = {
        backgroundColor: 'inherit',
        color: 'inherit',
    };

    const defaultValues = {
        email: '',
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSubmit = (data: InputsType) => {
        const params = {
            email: data.email
        };

        resendConfirmation(params).then((res) => {
            const { sent } = res;
            if (sent) {
                // Notify the user an email has been sent out
                const message = intl.get('common.form.resendConfirmation.info.emailSent').d('If you have an account, an account confirmation email will be sent out!');
                notify({ message, severity: 'success' });
            } else {
                const message = intl.get('common.form.resendConfirmation.error.emailSent').d('An error has occured. If this issue presists, contact support.');
                // Something happened, and email was not sent.
                notify({ message, severity: 'error' });
            }
        }).catch((error) => {
            if (error === 'missing.email') {
                const message = intl.get('common.form.resendConfirmation.error.noEmail').d('Please provide your email.');
                notify({ message, severity: 'error' });
            } else if (error === 'already.confirmed') {
                const message = intl.get('common.form.resendConfirmation.error.alreadyConfirmed').d('Your account has already been confirmed.');
                notify({ message, severity: 'error' });
            } else if (error === 'blocked.user') {
                const message = intl.get('common.form.resendConfirmation.error.blockedUser').d('Your account is blocked. Please contact support.');
                notify({ message, severity: 'error' });
            } else {
                // Notify user that an email has been sent to their email address. If the users email doesn't exist, we still show the same message.
                // We could check for if the email doesn't exist and display a message. But it's best not to give away wether or not a user exists.
                const message = intl.get('common.form.resendConfirmation.info.emailSent').d('If you have an account, an account confirmation email will be sent out!');
                notify({ message, severity: 'success' });
            }
        });


        // Call the resend confirmation callback if its defined
        // onResendConfirmation && onResendConfirmation();
    };

    const submitText = intl.get('common.form.resendConfirmation.button.submit').d('Submit');
    const signInText = intl.get('common.form.resendConfirmation.link.signIn').d('Sign in');

    const emailText = intl.get('common.form.resendConfirmation.input.email').d('Email Address');

    return (
        <>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
                {
                    title && (
                        <Box sx={{ mb: 1, display: 'inline-block' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h1">{title}</Typography>
                                <Box className={classes.bar}></Box>
                            </Box>
                        </Box>
                    )
                }
                {
                    subtitle && subtitle.length > 0 && (
                        <Typography variant="subtitle1">{subtitle}</Typography>
                    )
                }
            </Box>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="form-resendConfirmation-input-email"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={emailText}
                                    name="email"
                                    autoComplete="email"
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={!!errors.email}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ForgotPasswordButton
                                    id="form-resendConfirmation-button-submit"
                                    type="submit"
                                    fullWidth
                                    text={submitText}
                                    disabled={isSubmitting}
                                    className={classes.submit}
                                    color={loginVariation === VARIATIONS.FILLED_SECONDARY ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Link
                                    id="form-resendConfirmation-link-signIn"
                                    className={classes.link}
                                    onClick={() => switchAction('login')}
                                    variant="body2"
                                >
                                    {signInText}
                                </Link>

                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </>
    );
};

export default ResendConfirmation;
