import React from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Link,
    Box,
    Container,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';

// import Container from '../../components/Container';
import ForgotPasswordButton from '../../../components/Button';

import { schema } from './validation';
import { AuthenticationActionFunctionType, BrandThemeType, BrandForgetPasswordDataType, BrandEmailVariablesType } from '../../../types';
import { useNotification } from '../../../hooks';
import { BRAND, VARIATIONS } from '../../../constants';
import { MUTATION_FORGOT_PASSWORD } from '../../../mutations';

const useStyle = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        cursor: 'pointer',
        color: (configTheme: BrandThemeType) => {
            if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_SECONDARY) {
                return theme.palette.primary.main;
            } else if (configTheme && configTheme.loginVariation === VARIATIONS.FILLED_PRIMARY) {
                return theme.palette.secondary.main;
            }
            return theme.palette.primary.main;
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        paddingBottom: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    bar: {
        marginTop: theme.spacing(2),
        width: '70%',
        height: '4px',
        backgroundColor: theme.palette.divider,
    },
}));

type InputsType = {
    email: string;
}

interface Props {
    configTheme: BrandThemeType;
    onForgetPassword?: () => void;
    switchAction: AuthenticationActionFunctionType;
    title?: string;
    subtitle?: string;
}

const ForgotPassword = ({ configTheme, onForgetPassword, title, subtitle, switchAction }: Props): JSX.Element => {
    const classes = useStyle(configTheme);
    const notify = useNotification();

    const { loginVariation } = configTheme;

    const inheritStyle = {
        backgroundColor: 'inherit',
        color: 'inherit',
    };

    const defaultValues = {
        email: '',
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const [forgotPassword, { loading }] = useMutation<BrandForgetPasswordDataType, BrandEmailVariablesType>(MUTATION_FORGOT_PASSWORD, {
        onError(errors) {
            const { graphQLErrors } = errors;
            if (graphQLErrors.length > 0) {
                const [error] = graphQLErrors;
                const ext = error.extensions;

                if (ext) {
                    const { exception } = ext;
                    const { data } = exception;
                    const { id } = data;

                    if (id === 'Auth.form.error.email.format') {
                        // Invalid email format
                        const message = intl.get('common.form.forgotPassword.error.email.invalid').d('Please provide a valid email address.');
                        notify({ message, severity: 'error' });
                    }
                }
            }
        }
    });

    const onSubmit = (data: InputsType) => {
        forgotPassword({
            variables: {
                email: data.email,
                brand: BRAND
            }
        });

        // Notify user that an email has been sent to their email address. If the users email doesn't exist, we still show the same message.
        // We could check for if the email doesn't exist and display a message. But it's best not to give away wether or not a user exists.
        const message = intl.get('common.form.forgotPassword.info.emailSent').d('If you have an account, a password reset email will be sent out.');
        notify({ message, severity: 'success' });

        // Call the login callback if its defined
        onForgetPassword && onForgetPassword();
    };

    const submitText = intl.get('common.form.forgotPassword.button.submit').d('Submit');
    const signInText = intl.get('common.form.forgotPassword.link.signIn').d('Already have an account?');
    const newAccountText = intl.get('common.form.forgotPassword.link.newAccount').d('Create an account');

    const emailText = intl.get('common.form.forgotPassword.input.email').d('Email Address');

    return (
        <>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
                {
                    title && (
                        <Box sx={{ mb: 1, display: 'inline-block' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h1">{title}</Typography>
                                <Box className={classes.bar}></Box>
                            </Box>
                        </Box>
                    )
                }
                {
                    subtitle && subtitle.length > 0 && (
                        <Typography variant="subtitle1">{subtitle}</Typography>
                    )
                }
            </Box>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="form-forgotPassword-input-email"
                                    inputRef={register}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={emailText}
                                    name="email"
                                    autoComplete="email"
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={!!errors.email}
                                    inputProps={{ style: inheritStyle }}
                                    InputProps={{ style: inheritStyle }}
                                    InputLabelProps={{ style: inheritStyle }}
                                    FormHelperTextProps={{ style: inheritStyle }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ForgotPasswordButton
                                    id="form-forgotPassword-button-submit"
                                    type="submit"
                                    fullWidth
                                    text={submitText}
                                    disabled={loading}
                                    className={classes.submit}
                                    color={loginVariation === VARIATIONS.FILLED_SECONDARY ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Link
                                    id="form-forgotPassword-link-signIn"
                                    className={classes.link}
                                    onClick={() => switchAction('login')}
                                    variant="body2"
                                >
                                    {signInText}
                                </Link>

                            </Grid>
                            <Grid item>
                                <Link
                                    id="form-forgotPassword-link-register"
                                    className={classes.link}
                                    onClick={() => switchAction('register')}
                                    variant="body2"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {newAccountText}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </>
    );
};

export default ForgotPassword;
