import React, { ReactNode } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/system';

import createTheme from '../../../../theme';
import { useBrandConfigurationContext } from '../../hooks';

interface Props {
    children?: ReactNode
}

const ThemeWrapper = ({ children }: Props): JSX.Element => {
    // Load brand configuration into cache
    const brandConfig = useBrandConfigurationContext();
    const { theme: configTheme } = brandConfig;

    const theme = createTheme(configTheme);

    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                {children}
            </StyledEngineProvider>
        </ThemeProvider>
    );
};

export default React.memo(ThemeWrapper);
