import React, { ReactElement, useState, CSSProperties } from 'react';
// import intl from 'react-intl-universal';
import {
    Box,
    TextField,
    IconButton,
    Typography,
    Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { IconMdDelete } from '../../../../Icons';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        minHeight: '200px',
        overflow: 'hidden',
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    blurredImage: {
        position: 'absolute',
        top: '-10px',
        left: '-10px',
        right: '-10px',
        bottom: '-10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(5px)',
    },
    delete: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
        }
    },
    label: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    upload: {
        padding: theme.spacing(1),
        borderRadius: '5px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

interface Props {
    setImage: (file: File | null) => void;
    image?: string;
}

const ImageUpload = ({ image, setImage }: Props): ReactElement => {
    const classes = useStyles();

    const [previewImage, setPreviewImage] = useState(image || '');

    const handleImage = (event: any) => {
        // Get the file from the event
        const file = event.target.files && event.target.files.length && event.target.files[0];
        // Call the parents set image with the file, or null
        setImage(file || null);
        // If a file was chosen
        if (file) {
            // Read the file
            const reader = new FileReader();
            reader.onload = (event: any) => {
                // Set the preview image to display the selected file
                setPreviewImage(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = () => {
        setImage(null);
        setPreviewImage('');
    };

    const imageStyle: CSSProperties = {
        backgroundImage: `url('${previewImage}')`,
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.blurredImage} style={imageStyle} />
            <Box className={classes.image} style={imageStyle} />
            <label htmlFor="imageUpload" className={classes.label}>
                {!previewImage && <Typography variant="button" className={classes.upload}>Upload Photo</Typography>}
                <TextField
                    id="imageUpload"
                    type="file"
                    onChange={handleImage}
                    inputProps={{ accept: '.jpg,.jpeg,.png' }}
                    style={{ display: 'none' }}
                />
            </label>
            {
                previewImage &&
                    <Tooltip enterTouchDelay={50} title="Delete">
                        <IconButton
                            className={classes.delete}
                            onClick={handleDelete}
                            aria-label="delete"
                            size="large">
                            <IconMdDelete />
                        </IconButton>
                    </Tooltip>
            }
        </Box>
    );
};

export default ImageUpload;
