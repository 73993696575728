import React from 'react';
import intl from 'react-intl-universal';

import DynamicFieldText from './fields/Text';
import DynamicFieldRichText from './fields/RichText';
import DynamicFieldSelect from './fields/Select';
import DynamicFieldNumber from './fields/Number';
import DynamicFieldFloat from './fields/Float';
import DynamicFieldMake from './fields/Make';
import DynamicFieldPrice from './fields/Price';
import DynamicFieldBoolean from './fields/Boolean';

import { PropertiesFieldType } from '../../types';
import { FIELD_TYPES } from '../../constants';

type Props = PropertiesFieldType & {
    defaultValue?: any;
    disabled?: boolean;
};

const DynamicField = (props: Props): JSX.Element => {
    const { type } = props;

    const undefinedText = intl.get('paddock.dynamicField.type.undefined', { type }).d(`Undefined Type: ${type}`);

    switch (type) {
        case FIELD_TYPES.TEXT:
            return <DynamicFieldText {...props} />;
        case FIELD_TYPES.SELECT:
            return <DynamicFieldSelect {...props} />;
        case FIELD_TYPES.NUMBER:
            return <DynamicFieldNumber {...props} />;
        case FIELD_TYPES.FLOAT:
            return <DynamicFieldFloat {...props} />;
        case FIELD_TYPES.MAKE:
            return <DynamicFieldMake {...props} />;
        case FIELD_TYPES.PRICE:
            return <DynamicFieldPrice {...props} />;
        case FIELD_TYPES.BOOLEAN:
            return <DynamicFieldBoolean {...props} />;
        case FIELD_TYPES.RICH_TEXT:
            // @ts-expect-error: MUI update issue
            return <DynamicFieldRichText {...props} />;
        default:
            return <>{undefinedText}</>;
    }
};

export default DynamicField;
