import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(1, 4),
        borderRadius: 20,
        [theme.breakpoints.down('xl')]: {
            marginBottom: theme.spacing(2)
        }
    }
}));

interface Props {
    text: string;
}

const Point = ({ text }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper className={classes.container} elevation={2}>
            <Typography variant="body2">{text}</Typography>
        </Paper>
    );
};

export default Point;
