import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lookup } from 'country-data';
import CountryFlag from 'react-country-flag';

import { AddressType } from '../../types';

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: 14,
        fontFamily: 'Avenir',
        color: theme.palette.common.black,
        [theme.breakpoints.down('xl')]: {
            fontSize: 12
        }
    },
    addressContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
    }
}));

interface Props {
    address: AddressType;
}

const CardAddress = ({ address }: Props): JSX.Element => {
    const classes = useStyles();

    const { country, state } = address;
    // Strip out "The" out of country name. This is to insure that we can find the flag in the lookup
    const _country = country ? country.replace(/^the\s/i, '') : '';
    const [result] = lookup.countries({ name: _country });

    return (
        <Box className={classes.addressContainer}>
            {
                !!(result && result.alpha2) && (
                    <CountryFlag
                        countryCode={result.alpha2}
                        svg
                        title={country}
                        style={{
                            width: '1.5em',
                            height: '1.5em'
                        }}
                        aria-label={country}
                    />
                )
            }
            <Typography
                className={classes.text}
                align="left"
                variant="body2"
            >
                {`${state ? state : ''}${state ? `, ${country}` : country}`}
            </Typography>
        </Box>
    );
};

export default React.memo(CardAddress);
