import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Container, FormControlLabel, Switch, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import snapprBannerDesktop from '../../assets/images/snappr_banner_desktop.png';
import snapprBannerMobile from '../../assets/images/snappr_banner_mobile.png';

import Button from '../../../common/components/Button';
import ImageDropzone from './ImageDropzone';

import { useWizardContext } from '../../../common/hooks';
import { useFormDataContext } from '../../../common/hooks/useFormDataContext';
import { schema, snapprSchema } from './validation';
import { ImageStateType, PricingPackageType } from '../../../common/types';
import { useAuctionContext } from '../../hooks/useAuctionContext';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    buttons: {
        margin: theme.spacing(5, 0, 0),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    divider: {
        height: 2,
        width: '100%',
        backgroundColor: theme.palette.primary.main
    },
    snapprBanner: {
        width: '100%',
    }
}));

type InputsType = {
    serviceSnappr: boolean;
    coverIndex: number;
    exterior: Array<ImageStateType>;
    interior: Array<ImageStateType>;
    mechanical: Array<ImageStateType>;
    documents: Array<ImageStateType>;
}

type Props = {
    pricingPackage?: PricingPackageType;
}

const Media = ({ pricingPackage }: Props): JSX.Element => {
    const classes = useStyles();
    const { handleBack, handleNext, activePageIndex, steps } = useWizardContext();
    const { setValues, data } = useFormDataContext();
    const auction = useAuctionContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    const [snappr, setSnappr] = useState<boolean>(!!(data && data.serviceSnappr));
    const [coverIndex, setCoverIndex] = useState<number>((data && data.coverIndex) || 0);

    // Create a state called images using useState hooks and pass the initial value as empty array
    const [exterior, setExterior] = useState<Array<ImageStateType>>(data && data.exterior ? data.exterior : []);
    const [interior, setInterior] = useState<Array<ImageStateType>>(data && data.interior ? data.interior : []);
    const [mechanical, setMechanical] = useState<Array<ImageStateType>>(data && data.mechanical ? data.mechanical : []);
    const [documents, setDocuments] = useState<Array<ImageStateType>>(data && data.documents ? data.documents : []);

    const exteriorDef = exterior;
    const interiorDef = interior;
    const mechanicalDef = mechanical;
    const documentsDef = documents;

    let methods = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues: {
            serviceSnappr: false,
            exterior: exteriorDef,
            interior: interiorDef,
            mechanical: mechanicalDef,
            documents: documentsDef
        }
    });

    const snapprMethods = useForm<InputsType>({
        resolver: yupResolver(snapprSchema),
        defaultValues: {
            serviceSnappr: true,
            exterior: exteriorDef,
            interior: interiorDef,
            mechanical: mechanicalDef,
            documents: documentsDef
        }
    });

    const price = pricingPackage?.snappr?.price;
    // Whether or not snappr is enabled for the package
    const snapprEnabled = !!pricingPackage?.snappr?.enabled;
    // If this is enabled, snappr is mandatory for this package (cannot opt out)
    const snapprForceEnabled = !!pricingPackage?.snappr?.forceEnabled;
    // If snappr is being used (force enabled, or opted in)
    const usingSnappr = snapprForceEnabled || snappr;

    if (usingSnappr) {
        methods = snapprMethods;
    }

    const { handleSubmit, errors, setValue } = methods;

    const onSubmit = (data: InputsType) => {
        data.serviceSnappr = usingSnappr;
        data.coverIndex = coverIndex;
        if (usingSnappr) {
            data.interior = [];
            data.mechanical = [];
            data.documents = [];
        }
        setValues && setValues(data);
        handleNext && handleNext();
    };

    const toggleSnappr = () => {
        const newState = !snappr;
        setValue('serviceSnappr', newState);
        setSnappr(newState);
    };

    const snapprOptIn = intl.get('auction.auctionWizard.snappr.optIn', { price }).d(`I would like to use the Snappr Photography Service ($${price} USD)`);
    const snapprOptedTxt = intl.get('auction.auctionWizard.snappr.optedText').d('Please upload some photos for the approval process');

    const nextText = intl.get('auction.auctionWizard.button.next').d('Next');
    const submitText = intl.get('auction.auctionWizard.button.submit').d('Submit');
    const backText = intl.get('auction.auctionWizard.button.back').d('Back');
    const cancelText = intl.get('auction.auctionWizard.button.cancel').d('Cancel');

    const snapprBanner = isMobile ? snapprBannerMobile : snapprBannerDesktop;

    return (
        <FormProvider {...methods}>
            <Container>
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        {snapprEnabled && (
                            <Box>
                                <img className={classes.snapprBanner} src={snapprBanner} alt="Snappr" />
                                {!snapprForceEnabled && (
                                    <FormControlLabel
                                        control={
                                            <Switch checked={snappr} onChange={toggleSnappr} name="snappr" />
                                        }
                                        label={snapprOptIn}
                                    />
                                )}
                                {(usingSnappr) && (
                                    <Typography>{snapprOptedTxt}</Typography>
                                )}
                            </Box>
                        )}
                        <ImageDropzone
                            key="dropzone-exterior"
                            name="exterior"
                            label={usingSnappr ? 'Images' : 'Exterior'}
                            auction={auction}
                            errors={errors}
                            imageLimit={usingSnappr ? 20 : 75}
                            images={exterior}
                            setImages={setExterior}
                            setValue={setValue}
                            coverIndex={coverIndex}
                            setCoverIndex={setCoverIndex}
                        />
                        {!usingSnappr && (
                            <>
                                {/* <Box className={classes.divider} /> */}
                                <ImageDropzone
                                    key="dropzone-interior"
                                    name="interior"
                                    label="Interior"
                                    auction={auction}
                                    errors={errors}
                                    imageLimit={75}
                                    images={interior}
                                    setImages={setInterior}
                                    setValue={setValue}
                                />
                                {/* <Box className={classes.divider} /> */}
                                <ImageDropzone
                                    key="dropzone-mechanical"
                                    name="mechanical"
                                    label="Mechanical"
                                    auction={auction}
                                    errors={errors}
                                    imageLimit={75}
                                    images={mechanical}
                                    setImages={setMechanical}
                                    setValue={setValue}
                                />
                                {/* <Box className={classes.divider} /> */}
                                <ImageDropzone
                                    key="dropzone-documents"
                                    name="documents"
                                    label="Documents"
                                    auction={auction}
                                    errors={errors}
                                    imageLimit={75}
                                    images={documents}
                                    setImages={setDocuments}
                                    setValue={setValue}
                                />
                            </>
                        )}
                        <Box className={classes.buttons}>
                            <Button
                                id="form-media-input-back-or-cancel"
                                type="button"
                                color={activePageIndex !== 0 ? 'secondary' : 'primary'}
                                onClick={handleBack}
                                text={activePageIndex !== 0 ? backText : cancelText}
                            />
                            <Button
                                id="form-media-input-next-or-submit"
                                color="secondary"
                                type="submit"
                                text={activePageIndex === (steps as number) - 1 ? submitText : nextText}
                            />
                        </Box>
                    </form>
                </div>
            </Container>
        </FormProvider>
    );
};

export default Media;
