import React, { ReactElement, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { IconMdClose, } from '../../../../Icons';
import makeStyles from '@mui/styles/makeStyles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import StripeForm from '../../forms/payment/Stripe';

import { PaymentParamsBaseType } from '../../types';
import { usePaymentConfigurationContext } from '../../hooks';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(4),
    },
    titleBar: {
        position: 'relative',
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.secondary.contrastText,
        fontWeight: 'bold'
    },
    iconStyle: {
        color: theme.palette.secondary.contrastText
    },
    divider: {
        color: theme.palette.divider
    }
}));

interface Props extends PaymentParamsBaseType {
    onClose: () => void;
}

const StripePaymentModal = ({ itemId, itemType, pricingPackage, paymentProvider, paymentType, promotion, onSuccess, onFailure, onClose }: Props): ReactElement => {
    const classes = useStyles();
    const { stripePublishableKey } = usePaymentConfigurationContext();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(new Promise(() => null));
    const titleText = intl.get('common.stripePaymentModal.title').d('Payment');
    const closeText = intl.get('common.icon.close.tooltip').d('Close');

    useEffect(() => {
        if (stripePublishableKey) {
            const promise = loadStripe(stripePublishableKey);
            setStripePromise(promise);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={true} fullWidth>
            <Box className={classes.container}>
                <DialogTitle className={classes.titleBar}>
                    <Typography variant="h2" component="span" className={classes.title}>{titleText}</Typography>
                    <Box className={classes.closeContainer}>
                        <IconButton
                            className={classes.iconStyle}
                            onClick={handleClose}
                            aria-label={closeText}
                            size="large">
                            <IconMdClose />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Elements stripe={stripePromise}>
                            <StripeForm
                                itemId={itemId}
                                itemType={itemType}
                                paymentProvider={paymentProvider}
                                pricingPackage={pricingPackage}
                                paymentType={paymentType}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                promotion={promotion}
                            />
                        </Elements>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default StripePaymentModal;
