import React, { useEffect, useMemo, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import slugify from 'slugify';

import AnimatedLoader from '../../../common/components/AnimatedLoader';
import ErrorMessage from '../../../common/components/ErrorMessage';
import Wizard from '../../../common/components/Wizard';
import CheckPermissions from './CheckPermissions';
import FormBasicDetails from '../../forms/BasicDetails';
import FormAdditionalDetails from '../../forms/AdditionalDetails';
import FormMedia from '../../forms/Media';
import FormSubmission from '../../forms/Submission';

import { AuctionPropertiesByBrandDataType, CreateAuctionDataType, UpdateAuctionDataType } from '../../types';
import { QUERY_AUCTION_PROPERTIES_BY_BRAND } from '../../queries';
import { AuctionProvider } from '../../hooks/useAuctionContext';
import { QUERY_COUNTRY_STATE, QUERY_CAR_MAKE } from '../../../common/queries';
import { MUTATION_UPLOAD_MULTIPLE_IMAGE } from '../../../common/mutations';
import { CarMakeDataType, CountryStateDataType, ImageStateType, PricingPackageType, UploadMultipleImagesDataType } from '../../../common/types';
import { BRAND, TEMPLATE_TYPE, FORM_ACTION, PAYMENT_TYPE } from '../../../common/constants';
import { useFormDataContext } from '../../../common/hooks/useFormDataContext';
import { MUTATION_CREATE_AUCTION, MUTATION_UPDATE_AUCTION } from '../../mutations';
import { useAuthenticatedUserContext, useNotification } from '../../../common/hooks';

const useStyles = makeStyles(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    }
}));

type LocationPathParams = {
    auctionId?: string;
}

interface Props {
    title: string;
    action: FORM_ACTION;
    successRedirect?: string;
    pricingPackage?: PricingPackageType;
    paymentType?: PAYMENT_TYPE;
}

const CONCURRENT_IMAGE_UPLOADS = 15;

const AuctionWizard = ({ title, action, successRedirect, pricingPackage, paymentType }: Props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotification();
    const [doSubmission, setDoSubmission] = useState(false);
    const { auctionId } = useParams<LocationPathParams>();
    const { data: formData } = useFormDataContext();
    const { user } = useAuthenticatedUserContext();

    const formDataRef = useRef<any>(formData);

    // Return the user back if he or she doesn't have the correct state from selecting a package.
    if (action === FORM_ACTION.CREATE && (!pricingPackage || !paymentType)) {
        if (history.length !== 0) {
            history.goBack();
        } else {
            history.push('/');
        }

        const messageText = intl.get('auction.page.wizard.pricingPackage.notSelected').d('Please make sure you select the correct package before you submit an auction.');
        notify({ message: messageText, severity: 'warning', vertical: 'top', horizontal: 'right' });
    }

    useEffect(() => {
        if (formDataRef.current !== formData) {
            if (formData?.name) {
                if (doSubmission) {
                    onRealSubmit();
                    setDoSubmission(false);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doSubmission, formData]);

    const isEdit = action === FORM_ACTION.EDIT;
    const redirect = successRedirect ? successRedirect : '/';

    // init fetching data and put them to the cache
    const { data: dataCountry, loading: loadingCountryState } = useQuery<CountryStateDataType>(QUERY_COUNTRY_STATE);
    const { data: dataMakes, loading: loadingCarMake } = useQuery<CarMakeDataType>(QUERY_CAR_MAKE);

    const { data: dataAuctionProperties, error: errorAuctionProperties, loading: loadingAuctionProperties } = useQuery<AuctionPropertiesByBrandDataType>(QUERY_AUCTION_PROPERTIES_BY_BRAND, {
        variables: {
            brand: BRAND
        }
    });

    const [uploadImages, { loading: loadingImages, error: errorImages }] = useMutation<UploadMultipleImagesDataType>(MUTATION_UPLOAD_MULTIPLE_IMAGE);
    const [createAuction, { loading: loadingCreateAuction, error: errorCreateAuction }] = useMutation<CreateAuctionDataType>(MUTATION_CREATE_AUCTION);
    const [updateAuction, { loading: loadingUpdateAuction, error: errorUpdateAuction }] = useMutation<UpdateAuctionDataType>(MUTATION_UPDATE_AUCTION);

    const isLoadingHelperData = loadingCountryState || loadingCarMake || loadingAuctionProperties;
    const loadingAuction = loadingCreateAuction || loadingUpdateAuction;
    const errorAuction = errorCreateAuction || errorUpdateAuction;

    const auctionProperties = dataAuctionProperties?.auctionPropertiesByBrand;

    const allFields = useMemo(() => {
        if (auctionProperties && auctionProperties.fields) {
            const { fields } = auctionProperties;
            // get the foundCategory fields

            return [...fields];
        }
        return [];
    }, [auctionProperties]);

    // Return loader when uploading either images or auction
    if (loadingImages || loadingAuction) {
        return <AnimatedLoader />;
    }

    const updateAuctionWithBatchedImages = (auctionId, exteriorImagesToUpload, interiorImagesToUpload, mechanicalImagesToUpload, documentsImagesToUpload, coverIndex) => {
        setTimeout(async() => {
            const messageText = intl.get('auction.page.auction.notify.imageUpload').d('Please do not close your browser while your auction is being processed.');
            notify({ message: messageText, severity: 'info', persist: true, vertical: 'bottom', horizontal: 'right' });
            const allImagesToUpload = [exteriorImagesToUpload, interiorImagesToUpload, mechanicalImagesToUpload, documentsImagesToUpload];

            const allUploadedImages: any = [];

            for (let i = 0; i < allImagesToUpload.length; i++) {
                allUploadedImages.push([]);
                const imagesToUpload = allImagesToUpload[i];
                const imageUploadSets = Math.ceil(imagesToUpload.length / CONCURRENT_IMAGE_UPLOADS);
                for (let ii = 0; ii < imageUploadSets; ii++) {
                    const lower = ii * CONCURRENT_IMAGE_UPLOADS;
                    const upper = lower + CONCURRENT_IMAGE_UPLOADS;
                    const imagesSetToUpload = imagesToUpload.slice(lower, upper);
                    // Upload those images
                    const { data: uploadedImagesSet } = await uploadImages({
                        variables: {
                            files: imagesSetToUpload
                        }
                    });

                    if (!loadingImages && uploadedImagesSet?.multipleUpload) {
                        allUploadedImages[i] = [
                            ...allUploadedImages[i],
                            ...uploadedImagesSet.multipleUpload
                        ];
                    }
                }
            }

            // get the uploaded images ids
            const exteriorImageIds = !loadingImages && allUploadedImages[0].map(x => x.id);
            const interiorImageIds = !loadingImages && allUploadedImages[1].map(x => x.id);
            const mechanicalImageIds = !loadingImages && allUploadedImages[2].map(x => x.id);
            const documentsImageIds = !loadingImages && allUploadedImages[3].map(x => x.id);

            const coverImage = exteriorImageIds[coverIndex];

            const auctionData: any = {
                exterior: exteriorImageIds,
                interior: interiorImageIds,
                mechanical: mechanicalImageIds,
                documents: documentsImageIds,
            };

            if (coverImage) {
                auctionData.coverImage = coverImage;
            }

            updateAuction({
                variables: {
                    input: {
                        where: {
                            id: auctionId
                        },
                        data: auctionData
                    }
                }
            }).then(() => {
                notify({ message: 'Your auction has been successfuly processed and submitted.', severity: 'success', persist: true, vertical: 'bottom', horizontal: 'right' });
            }).catch(() => {
                notify({ message: 'An error occurred while processing your auction. Please contact support or try submitting again.', severity: 'error', persist: true, vertical: 'bottom', horizontal: 'right' });
            });
        }, 1);
    };

    const onCancel = () => {
        history.push(redirect);
    };

    const onSubmit = async() => {
        setDoSubmission(true);
    };

    const onRealSubmit = async() => {
        if (formData) {
            const {
                // Auction Base Details
                title,
                reserve,
                currency,
                sellerType,
                country,
                state,
                city,
                userDescription,
                description,
                exterior,
                interior,
                mechanical,
                documents,
                coverIndex,
                name,
                phone,
                email,
                hasAgreedToTerms,
                serviceSnappr,
                // Additional Details
                ...dataFields
            } = formData;

            // Get all the images we need to upload
            const exteriorImagesToUpload = exterior.map((image: ImageStateType) => image.file);
            const interiorImagesToUpload = interior.map((image: ImageStateType) => image.file);
            const mechanicalImagesToUpload = mechanical.map((image: ImageStateType) => image.file);
            const documentsImagesToUpload = documents.map((image: ImageStateType) => image.file);

            const mappedDetails = Object.keys(dataFields).map((k) => {
                let val = dataFields[k];
                const field = allFields.find(f => f.name === k);

                if (k === 'make') {
                    val = dataFields[k].slug;
                }

                return {
                    [k]: {
                        label: field?.label,
                        value: val,
                        hidden: field?.hidden,
                        childName: field?.childName,
                        icon: field?.icon,
                        iconType: field?.iconType,
                        order: field?.order
                    }
                };
            });

            const auctionData: any = {
                title,
                // Slugify and remove all special characters
                slug: slugify(`${(title as string).toLowerCase()}-${new Date().getTime()}`, { strict: true }),
                description,
                userDescription,
                sellerType,
                startPrice: 0.00,
                minIncrementAmount: 0.00,
                address: {
                    country: country ? country.name : '',
                    state: state ? state.name : '',
                    city
                },
                reserve,
                currency,
                dataFields: Object.assign({}, ...mappedDetails),
                user: user?.id,
                contactInfo: {
                    name,
                    phone: phone ? phone : '',
                    email
                },
                hasAgreedToTerms,
                serviceSnappr: !!serviceSnappr,
                brand: BRAND,
                templateType: TEMPLATE_TYPE,
            };

            if (isEdit) {
                delete auctionData.paymentType;
                delete auctionData.packageId;
                delete auctionData.user;
                delete auctionData.brand;
                delete auctionData.templateType;

                const res = await updateAuction({
                    variables: {
                        input: {
                            where: {
                                id: auctionId
                            },
                            data: auctionData
                        }
                    }
                }).then((resp) => {
                    updateAuctionWithBatchedImages(
                        resp?.data?.updateAuction?.auction?.id,
                        exteriorImagesToUpload,
                        interiorImagesToUpload,
                        mechanicalImagesToUpload,
                        documentsImagesToUpload,
                        coverIndex
                    );
                    return resp;
                });
                if (res?.data?.updateAuction?.auction?.id) {
                    history.push(redirect);
                }
            } else if (pricingPackage && paymentType) {
                auctionData.packageId = parseInt(pricingPackage.id);
                auctionData.paymentType = paymentType;

                const res = await createAuction({
                    variables: {
                        input: {
                            data: auctionData
                        }
                    }
                }).then((resp) => {
                    updateAuctionWithBatchedImages(
                        resp?.data?.createAuction?.auction?.id,
                        exteriorImagesToUpload,
                        interiorImagesToUpload,
                        mechanicalImagesToUpload,
                        documentsImagesToUpload,
                        coverIndex
                    );
                    return resp;
                });

                if (res?.data?.createAuction?.auction?.id) {
                    history.push(redirect);
                }
            } else {
                const messageText = intl.get('auction.page.auction.upload.noPackageOrType').d('No package was selected. Please select the auction package before submitting.');
                notify({ message: messageText, severity: 'warning', vertical: 'top', horizontal: 'right' });
            }
        }
    };

    const basicDetailsText = intl.get('auction.auctionWizard.step.basicDetails.label').d('Basic Details');
    const additionalDetailsText = intl.get('auction.auctionWizard.step.additionalDetails.label').d('Additional Details');
    const mediaText = intl.get('auction.auctionWizard.step.media.label').d('Media');
    const submissionText = intl.get('auction.auctionWizard.step.review.label').d('Submission');

    const imageErrorText = intl.get('auction.auctionWizard.submit.imageError').d('An error has occured with the images. If this issue presists, please contact support.');
    const auctionSaveErrorText = intl.get('auction.auctionWizard.submit.auctionSaveError').d('An error has occured with saving the auction. If this issue presists, please contact support.');

    const stepsLabel = [
        basicDetailsText,
        additionalDetailsText,
        mediaText,
        submissionText
    ];

    return (
        <Box className={classes.layout}>
            <CheckPermissions
                update={isEdit}
                userId={user?.id}
                auctionId={auctionId}
            >
                {
                    ({ loading, error, auction }) => (
                        <>
                            {
                                (loading || isLoadingHelperData) && <AnimatedLoader />

                            }
                            {
                                error && (
                                    <ErrorMessage
                                        description={error}
                                    />
                                )
                            }
                            {
                                !loading && !error && !errorAuctionProperties && (
                                    <AuctionProvider value={auction}>
                                        <Wizard
                                            title={title}
                                            stepLabels={stepsLabel}
                                            onCancel={onCancel}
                                            onSubmit={onSubmit}
                                        >
                                            <Wizard.Pages>
                                                {
                                                    dataCountry && (
                                                        <FormBasicDetails countries={dataCountry.countryState.data} />
                                                    )
                                                }
                                                <FormAdditionalDetails auctionProperties={auctionProperties} makes={dataMakes?.carMake?.data} />
                                                <FormMedia pricingPackage={pricingPackage} />
                                                <FormSubmission />
                                            </Wizard.Pages>
                                        </Wizard>
                                        {
                                            errorImages && (
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="body1" align="center" color="error">
                                                        {imageErrorText}
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                        {
                                            errorAuction && (
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="body1" align="center" color="error">
                                                        {auctionSaveErrorText}
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                    </AuctionProvider>
                                )
                            }
                        </>
                    )
                }
            </CheckPermissions>
        </Box>
    );
};

export default AuctionWizard;
