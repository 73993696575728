import React, { ElementType } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button as MUIButton, ButtonProps, CircularProgress } from '@mui/material';

import { LinkParamToType } from '../../types';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.button,
        // // textTransform: 'uppercase',
        borderRadius: 'unset',
        whiteSpace: 'nowrap',
    }
}));

interface Props extends ButtonProps {
    id: string;
    text: string | React.ReactNode | JSX.Element;
    loading?: boolean;
    component?: ElementType;
    to?: string | LinkParamToType;
}

const Button = ({ id, text, loading = false, component: Component, to, ...rest }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <MUIButton
            id={id}
            classes={classes}
            variant="contained"
            startIcon={loading ? <CircularProgress size={20} /> : <></>}
            component={Component ? Component : 'button'}
            to={to ? to : ''}
            {...rest}
        >
            {text}
        </MUIButton>
    );
};

export default Button;
