import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ReactAnnouncement from 'react-announcement';

import logo from '../../assets/logo.svg';

import { BrandAnnouncementDataType, BrandVariablesType } from '../../types';
import { QUERY_BRAND_ANNOUNCEMENT } from '../../queries';
import { getImageUrl } from '../../helpers';
import { BRAND } from '../../constants';
import { useIsMountedRef } from '../../hooks';

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: 'Avenir',
        '& h3': {
            fontFamily: 'Montserrat',
            marginRight: 40
        },
        '& svg': {
            width: 25
        },
    },
}));

const Announcement = (): JSX.Element => {
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();

    const [getAnnouncement, { data, loading, error } ] = useLazyQuery<BrandAnnouncementDataType, BrandVariablesType>(QUERY_BRAND_ANNOUNCEMENT, {
        variables: {
            // if no brand is provided, use the Racing Edge Brand configuration instead
            brand: BRAND
        }
    });

    useEffect(() => {
        if (isMountedRef.current) {
            getAnnouncement();
        }
    }, [getAnnouncement, isMountedRef]);

    if (loading || error) {
        return <></>;
    }

    if (data && data.configurationByBrand) {
        const { announcement } = data.configurationByBrand;

        if (announcement) {
            const { link, subtitle, title, daysToLive, image, secondsBeforeBannerShows } = announcement;

            let imageSource = logo;

            if (image) {
                imageSource = getImageUrl(image, 'small');
            }

            return (
                <Box className={classes.text}>
                    <ReactAnnouncement
                        title={title}
                        subtitle={subtitle}
                        link={link}
                        imageSource={imageSource}
                        daysToLive={daysToLive}
                        secondsBeforeBannerShows={secondsBeforeBannerShows}
                    />
                </Box>
            );
        }
    }

    return <></>;
};


export default Announcement;
