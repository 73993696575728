/* eslint-disable prefer-destructuring */
import React, { useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { UseFormMethods } from 'react-hook-form';
import { Container, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import PaymentButton from '../../../components/Button';
import { FieldCheckbox } from '../../../components/fields';
import SavedCardPaymentButton from '../../../containers/SavedCardPaymentButton';
import ManagePaymentMethods from '../../../../dashboard/components/ManagePaymentMethods';
// import CheckboxField from '../../../components/fields/Checkbox';

import { BRAND, PAYMENT_PROVIDER, PAYMENT_TYPE } from '../../../constants';
import { ConfirmPaymentReturnType, PaymentParamsBaseType, SavedCardType } from '../../../types';
import { displayCurrency } from '../../../helpers';
import { useNotification } from '../../../hooks';
import { confirmPayment } from '../../../request';


const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    errorBox: {
        marginTop: theme.spacing(1),
        color: theme.palette.error.main,
    },
    link: {
        fontWeight: 'bold'
    }
}));

type InputsType = {
    // saveCard: boolean;
    hasAgreedToTerms: boolean;
}

interface Props extends PaymentParamsBaseType {
    formCurrency: string;
    paymentType: PAYMENT_TYPE;
    useSavedCard: boolean;
    setUseSavedCard: React.Dispatch<React.SetStateAction<boolean>>;
    savedCard?: SavedCardType | null;
    savedCardLoading?: boolean;
    form: UseFormMethods<InputsType>;
    amount?: number;
    displayAmount: number;
    paymentIntent: string;
}

const FormBody = ({
    form,
    paymentIntent,
    itemId,
    itemType,
    promotion,
    pricingPackage,
    paymentProvider,
    displayAmount,
    amount,
    formCurrency,
    paymentType,
    renderPaymentOptions,
    savedCard,
    savedCardLoading,
    onFailure,
    onSuccess,
    useSavedCard,
    setUseSavedCard
}: Props): ReactElement => {
    const [isSavedCard, setIsSavedCard] = useState(!!savedCard?.last4);
    const handleIsSavedCard = () => {
        setIsSavedCard(true);
    };

    const classes = useStyles();

    const stripe = useStripe();
    const elements = useElements();
    const notify = useNotification();

    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = form;

    let paymentTypeStr = '';

    if (paymentType === PAYMENT_TYPE.MONTHLY_PAYMENT || paymentType === PAYMENT_TYPE.YEARLY_PAYMENT) {
        paymentTypeStr = paymentType.replace('ly', '').replace(/^./, (firstLetter) => firstLetter.toUpperCase());
    }

    // Format the amount, example result "$9.99 USD"
    const price = displayCurrency(displayAmount, formCurrency);
    // Adds Monthly or Yearly, example result: "$9.99 USD / Monthly"
    const priceLbl = paymentTypeStr ? `${price} / ${paymentTypeStr}` : price;

    const isHold = paymentType === PAYMENT_TYPE.HOLD_PAYMENT;

    // Get the label displayed to the user, example results: "Current Payment: $9.99 USD / Monthly"
    const prefix = `${isHold ? `Hold${useSavedCard ? ' with card' : ''}` : `Pay${useSavedCard ? ' with card' : ''}`}`;
    const paymentText = intl.get('common.form.elements.label.currentPayment', { prefix, amount: priceLbl }).d(`${isHold ? 'Hold' : 'Pay'} - ${priceLbl}`);

    // const nameText = intl.get('common.form.elements.input.name').d('Name on card');
    // const saveCardText = intl.get('common.form.elements.input.saveCard').d('Save this card for future transactions');

    const agreementText = intl.getHTML('common.form.elements.checkbox.agreement').d(
        <span>
            I agree to the <a target="_blank" className={classes.link} href="/page/terms-and-conditions">Terms &amp; Conditions</a> and <a target="_blank" className={classes.link} href="/page/privacy-policy">Privacy Policy</a>
        </span>
    );

    const onSubmit = async(data: InputsType) => {
        window.console.log(data);
        if (!stripe || !elements || !isSavedCard) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        if (useSavedCard) {
            const params = {
                // we pass the created payment intent, so we can continue off of it to create a hold with an existing payment method
                paymentIntent,
                itemId,
                itemType,
                amount,
                packageId: pricingPackage?.id || '',
                paymentType,
                paymentProvider,
                promotion,
                brand: BRAND,
                useSavedCard
            };
            confirmPayment(params)
                .then((res: ConfirmPaymentReturnType) => {
                    onSuccess && onSuccess({ method: PAYMENT_PROVIDER.STRIPE, ...res });
                })
                .catch((err) => {
                    if (err.message) {
                        const { message } = err;
                        notify({ message, severity: 'error' });
                    }
                    onFailure && onFailure();
                }).finally(() => {
                    setIsLoading(false);
                });
        } else {
            stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: window.location.href,
                },
                redirect: 'if_required'
            }).then(({ error }) => {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, your customer will be redirected to
                // your `return_url`. For some payment methods like iDEAL, your customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error) {
                    if (error.type === 'card_error' || error.type === 'validation_error') {
                        notify({ message: error.message as string, severity: 'error' });
                    } else {
                        notify({ message: 'An unexpected error occurred', severity: 'error' });
                    }
                    onFailure && onFailure();
                } else {
                    onSuccess && onSuccess({ method: PAYMENT_PROVIDER.STRIPE, code: 200 });
                }
            }).catch((err) => {
                if (err.message) {
                    notify({ message: err.message, severity: 'error' });
                } else {
                    // eslint-disable-next-line
                    console.log(err);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <Container>
            <>
                {
                    isSavedCard || (
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center', my: 3 }}>
                                <ManagePaymentMethods onSuccess={ handleIsSavedCard }/>
                            </Box>
                        </Grid>
                    )
                }
                <Box className="sr-payment-form">
                    <Box className="sr-form-row" />
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} direction="column">
                            {
                                !!(renderPaymentOptions && renderPaymentOptions.length > 0) && (
                                    <>
                                        <Grid item xs={12}>
                                            {
                                                renderPaymentOptions.map(({ enabled, component, key }) => {
                                                    if (enabled) {
                                                        return (
                                                            <Box key={key} sx={{ mt: 1 }}>
                                                                { component }
                                                            </Box>
                                                        );
                                                    }
                                                    return <></>;
                                                })
                                            }
                                        </Grid>
                                    </>
                                )
                            }

                            <Grid item xs={12}>
                                <SavedCardPaymentButton
                                    card={savedCard}
                                    loading={savedCardLoading}
                                    setUseSavedCard={setUseSavedCard}
                                />
                            </Grid>

                            {
                                !useSavedCard && (
                                    <>
                                        <Grid item xs={12}>
                                            <PaymentElement id="payment-element" />
                                            {/* Disabled for Stripe, as Stripe payment an element doesn't allow
                                                saving a card
                                                after a payment intent has been created */}
                                            {/* We either have to change checkout flow, or use a card element */}
                                            {/* <CheckboxField
                                                id="form-elements-checkbox-saveCard"
                                                name="saveCard"
                                                register={register}
                                                label={saveCardText}
                                            /> */}
                                        </Grid>
                                    </>
                                )
                            }

                            {
                                isHold && (
                                    <Grid item xs={12}>
                                        <FieldCheckbox
                                            id="form-elements-checkbox-agreement"
                                            register = { register }
                                            variant="outlined"
                                            fullWidth
                                            required
                                            color="secondary"
                                            label={agreementText}
                                            name="hasAgreedToTerms"
                                            errorMessage={errors.hasAgreedToTerms?.message}
                                            error={!!(errors.hasAgreedToTerms)}
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item xs={12}>
                                <PaymentButton
                                    id="form-elements-button-submit"
                                    type="submit"
                                    fullWidth
                                    text={paymentText}
                                    disabled={isLoading || !stripe || !isSavedCard }
                                    className={classes.submit}
                                    color="secondary"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </>
        </Container>
    );
};

export default FormBody;
