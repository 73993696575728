import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    roundedBox: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '10px',
        padding: theme.spacing(2, 5),
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(1, 3)
        }
    },
}));

interface Props {
    children?: React.ReactNode;
}

const RoundedBox = ({ children }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Box className={classes.roundedBox}>
            {children}
        </Box>
    );
};

export default RoundedBox;
