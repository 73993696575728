import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { IconMdExpandMore, } from '../../../../Icons';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.body2,
        paddingTop: theme.spacing(0)
    },
    boxContainer: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.between('sm', 'xl')]: {
            width: '40%'
        },
        [theme.breakpoints.up('md')]: {
            width: '100%'
        }
    }
}));

interface Props {
    label: string;
    children: React.ReactNode;
}

const RefinmentListAccordion = ({ label, children }: Props): ReactElement => {
    const classes = useStyles();
    return (
        <Box className={classes.boxContainer}>
            <Accordion>
                <AccordionSummary
                    id={`panel-header-${label}`}
                    expandIcon={<IconMdExpandMore />}
                    aria-controls="panel-content"
                >
                    <Typography variant="h4">{label}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.root }}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default React.memo(RefinmentListAccordion);
