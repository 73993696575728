import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, TextField, TextFieldProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    colorBox: {
        display: 'inline-block',
        width: '25px',
        height: '25px',
        marginRight: theme.spacing(1),
    },
}));

type Props = TextFieldProps & {
    id: string;
    label: string;
    name: string;
    control: any;
}

const ColorField = ({ label, name, control, id, ref, defaultValue, type, size, ...rest }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Controller
            render={({ onChange, value }) => (
                <Box className={classes.root}>
                    <Box className={classes.colorBox} style={{ backgroundColor: value }} />
                    <TextField
                        id={id}
                        label={label}
                        inputRef={ref}
                        value={value}
                        type="text"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        {...rest}
                        onChange={onChange}
                    />
                </Box>
            )}
            name={name}
            control={control}
        />
    );
};

export default ColorField;
