import React from 'react';
// import intl from 'react-intl-universal';
import { Box, Grid } from '@mui/material';

import FeaturedCard from '../../components/FeaturedCard';
import NonFeaturedCard from '../../components/NonFeaturedCard';

import { DirectoryType } from '../../types';

type ItemsPerRow = 1 | 2 | 3 | 4;
type ItemSize = 12 | 6 | 4 | 3;

interface Props {
    // Component Properties
    directories: Array<DirectoryType>;
    component: typeof FeaturedCard | typeof NonFeaturedCard;
    // Optional Properties
    editDirectory?: (directory: DirectoryType, action?: string) => void;
    itemsPerRow?: ItemsPerRow;
}

const AlgoliaResultList = ({ directories, itemsPerRow, editDirectory, component: Component }: Props): JSX.Element => {
    // Determine the Card component from the properties
    const Card = Component;

    // Given items per row being 1/2/3/4, this can only result in values of 12/6/4/3
    const itemSize = (12 / (itemsPerRow || 2)) as ItemSize;

    // The content to display as the result list
    const content = directories.map((directory: any) => (
        <Grid item key={directory.id} xs={12} md={itemSize}>
            <Card directory={directory} editDirectory={editDirectory} />
        </Grid>
    ));

    // Render our Result List
    return (
        <Box sx={{ mb: 4 }}>
            {/* The Content */}
            <Grid container spacing={2}>
                {content}
            </Grid>
        </Box>
    );
};

export default AlgoliaResultList;
