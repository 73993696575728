import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { matchSorter } from 'match-sorter';
import { useQuery, useMutation } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, TextField, Typography, Select, FormHelperText, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { Formik } from 'formik';

import AnimatedLoader from '../../../common/components/AnimatedLoader';
import ImageUpload from '../ImageUpload';
import Hours from '../Hours';
import DirectoryFormCompleted from '../DirectoryFormCompleted';
import PreviewDialog from '../PreviewDialog';
import StripePaymentModal from '../../../common/containers/StripePaymentModal';

import { DirectoryType, HoursType, SocialLinks, CategorySelectionType } from '../../types';
import { COUNTRY_STATE_QUERY } from '../../queries';
import { CREATE_DIRECTORY_MUTATION, UPDATE_DIRECTORY_MUTATION } from '../../mutations';
import { CountryStateType, PricingPackageType, StateType } from '../../../common/types';
import { DIRECTORY_STATUS, PAYMENT_ITEM_TYPE, PAYMENT_PROVIDER, PAYMENT_TYPE } from '../../../common/constants';
import { MUTATION_UPLOAD_MULTIPLE_IMAGE } from '../../../common/mutations';
import { categories } from '../../constants';
import { useAuthenticatedUserContext } from '../../../common/hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        marginTop: theme.spacing(8),
        maxWidth: '1200px',
        margin: 'auto',
    },
    imageTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 0,
        padding: theme.spacing(1, 2),
        display: 'inline-block',
    },
    imageBox: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.error.main,
    },
    title: {

    },
    button: {
        ...theme.typography.button,
    },
    textField: {
        ...theme.typography.body2,
        '& .MuiFormHelperText-root': {
            ...theme.typography.subtitle1
        }
    },
    terms: {
        ...theme.typography.body2,
        marginRight: theme.spacing(0.5)
    },
    box: {
        padding: theme.spacing(4),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
    }
}));

interface FormikProperties {
    id: number;
    status: string;
    featured: boolean;
    name: string;
    category: CategorySelectionType;
    addressCountry: string;
    addressState: string;
    addressCity: string;
    addressPostal: string;
    addressStreet1: string;
    addressStreet2: string;
    hoursOfOperation: HoursType;
    website: string;
    phone: string;
    email: string;
    keywords: string;
    socialLinks: SocialLinks;
    videoURL: string;
    image?: string | number;
    activeSubscription: boolean;
    tos: boolean;
}

type ActionType = 'add' | 'update';

interface Props {
    // Component Properties
    action: ActionType;
    plan?: PricingPackageType;
    directory?: DirectoryType;
    goBack?: () => void;
}

const DirectoryForm = ({ action, plan, directory, goBack }: Props): JSX.Element => {
    const classes = useStyles();
    const { user } = useAuthenticatedUserContext();

    const [created, setCreated] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [directoryId, setDirectoryId] = useState(directory ? directory.id : 0);
    const [missingRequired, setMissingRequired] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);
    const [touchedSubmit, setTouchedSubmit] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const initialImageId = (directory && directory.image && directory.image.id) || '';
    const initialImageUrl = (directory && directory.image && directory.image.url) || '';
    const [uploadedImageId, setUploadedImageId] = useState(initialImageId);
    const [previewDir, setPreviewDir] = useState<any>(null);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreviewOpen = (values: any) => {
        setPreviewDir(values);
        if (imageFile) {
            setPreviewImage(URL.createObjectURL(imageFile));
        }
    };
    const handlePreviewClose = () => {
        setPreviewDir(null);
        setPreviewImage('');
    };

    // PAYMENT
    const [stripePayed, setStripePayed] = useState(false);
    const [stripeCheckoutOpen, setStripeCheckoutOpen] = useState(false);
    const handleStripeClose = () => {
        setStripeCheckoutOpen(false);
    };
    const handleCompletedFormOpenStripe = () => {
        setStripeCheckoutOpen(true);
    };
    const handleStripeSuccess = () => {
        setStripeCheckoutOpen(false);
        setStripePayed(true);
    };

    let isFree = true;
    let paymentType = PAYMENT_TYPE.ONE_TIME_PAYMENT;

    if (plan) {
        // Check if any payment types are enabled...
        if (plan.oneTimePayment && plan.oneTimePayment.enabled) {
            // One time payment
            isFree = false;
            paymentType = PAYMENT_TYPE.ONE_TIME_PAYMENT;
        } else if (plan.monthlyPayment && plan.monthlyPayment.enabled) {
            // Monthly payment
            isFree = false;
            paymentType = PAYMENT_TYPE.MONTHLY_PAYMENT;
        } else if (plan.yearlyPayment && plan.yearlyPayment.enabled) {
            // Yearly payment
            isFree = false;
            paymentType = PAYMENT_TYPE.YEARLY_PAYMENT;
        }
    }

    // The form is completed when either created or updated are set
    const formCompleted = created || updated;

    // Image Upload
    const handleImage = (file: File | null) => {
        setUploadedImageId('');
        setImageFile(file);
    };

    // Create Directory
    const [uploadImage] = useMutation(MUTATION_UPLOAD_MULTIPLE_IMAGE);
    // Create Directory
    const [createDirectory] = useMutation(CREATE_DIRECTORY_MUTATION);
    // Update Directory
    const [updateDirectory] = useMutation(UPDATE_DIRECTORY_MUTATION);
    // Get directory packages
    const { data, loading, error } = useQuery(COUNTRY_STATE_QUERY);

    if (loading) {
        return <AnimatedLoader name="DirectoryForm" />;
    }

    if (error) {
        const fetchPlanError = intl.get('directory.form.planError').d('An error occured while fetching the plans.');
        return (
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1">{fetchPlanError}</Typography>
            </Box>
        );
    }

    const countries: Array<CountryStateType> = data.countryState.data;
    let isFeatured = false;
    if (plan) {
        isFeatured = !!plan.featured;
    } else if (directory) {
        isFeatured = !!directory.featured;
    }

    // Subtitle
    const subtitle = plan && plan.title;

    // Section Labels
    const title = action === 'add'
        ? intl.get('directory.form.titleAdd').d('Add Your Business')
        : intl.get('directory.form.titleUpdate').d('Update');
    const changePlan = intl.get('directory.form.changePlan').d('Change Plan');
    const requiredFieldsText = intl.get('directory.form.error.required').d('Missing required fields.');
    const invalidFieldsText = intl.get('directory.form.error.invalid').d('Invalid fields.');
    const apiErrorText = intl.get('directory.form.error.api').d('An error occurred while processing this request, please try again or contact support if the problem persists.');

    // Labels
    const nameLabel = intl.get('directory.form.input.name.label').d('Name of Company');
    const categoryLabel = intl.get('directory.form.input.category.label').d('Category');

    const countryLabel = intl.get('directory.form.input.country.label').d('Country');
    const stateLabel = intl.get('directory.form.input.state.label').d('State');
    const cityLabel = intl.get('directory.form.input.city.label').d('City');
    const addressLabel1 = intl.get('directory.form.input.address1.label').d('Address 1');
    const addressLabel2 = intl.get('directory.form.input.address2.label').d('Address 2');
    const postalCodeLabel = intl.get('directory.form.input.postalCode.label').d('Postal Code');

    const hoursOfOperationLabel = intl.get('directory.form.input.hoursOfOperation.label').d('Operating Hours');
    const websiteLabel = intl.get('directory.form.input.website.label').d('Website');
    const phoneLabel = intl.get('directory.form.input.phone.label').d('Phone');
    const emailLabel = intl.get('directory.form.input.email.label').d('Email');

    const keywordsLabel = intl.get('directory.form.input.keywords.label').d('Keywords');
    const videoLabel = intl.get('directory.form.input.video.label').d('Video URL');

    // Helper Texts
    const nameHelperText = intl.get('directory.form.input.name.helperText').d('');
    const categoryHelperText = intl.get('directory.form.input.category.helperText').d('');

    const countryHelperText = intl.get('directory.form.input.country.helperText').d('');
    const stateHelperText = intl.get('directory.form.input.state.helperText').d('');
    const cityHelperText = intl.get('directory.form.input.city.helperText').d('');
    const streetHelperText = intl.get('directory.form.input.street.helperText').d('');
    const postalCodeHelperText = intl.get('directory.form.input.postalCode.helperText').d('');

    // const hoursOfOperationHelperText = intl.get('directory.form.input.hoursOfOperation.helperText').d('');
    const websiteHelperText = intl.get('directory.form.input.website.helperText').d('');
    const phoneHelperText = intl.get('directory.form.input.phone.helperText').d('');
    const emailHelperText = intl.get('directory.form.input.email.helperText').d('');

    const keywordsHelperText = isFeatured
        ? intl.get('directory.form.input.keywords.helperText2').d('separated by commas, 10 maximum')
        : intl.get('directory.form.input.keywords.helperText1').d('separated by commas, 2 maximum');
    const videoHelperText = intl.get('directory.form.input.video.helperText').d('youtube link showcasing service or company');

    // Actions
    const cancelAction = intl.get('directory.form.action.cancel').d('Cancel');
    const submitAction = intl.get('directory.form.action.submit').d('Submit');
    const previewAction = intl.get('directory.form.action.preview').d('Preview');

    // Assign the directory items properties (if present) as overriding
    const overrideProps: any = { ...directory };
    if (overrideProps.keywords) {
        // morph the keywords from an array into a string
        overrideProps.keywords = overrideProps.keywords.join(', ');
    }

    const filterCountryOptions = (options: Array<CountryStateType>, { inputValue }: any) => matchSorter(options, inputValue, { keys: ['iso2', 'iso3', 'name'] });
    const filterStateOptions = (options: Array<StateType>, { inputValue }: any) => matchSorter(options, inputValue, { keys: ['state_code', 'name'] });


    const initialValues: FormikProperties = {
        id: 0,
        status: DIRECTORY_STATUS.PENDING,
        featured: isFeatured,
        name: '',
        category: '',
        addressCountry: '',
        addressState: '',
        addressCity: '',
        addressPostal: '',
        addressStreet1: '',
        addressStreet2: '',
        hoursOfOperation: null,
        website: '',
        phone: '',
        email: '',
        keywords: '',
        socialLinks: {
            facebook: '',
            instagram: '',
            youtube: '',
            linkedin: '',
            twitter: '',
        },
        videoURL: '',
        activeSubscription: false,
        ...overrideProps,
        tos: false
    };

    // The form
    const form = (
        <Formik
            initialValues={initialValues}
            validate={(values: any) => {
                const errors: any = {};
                let req = false;
                let inv = false;
                if (!values.name) {
                    req = true;
                    errors.name = 'Required';
                }
                if (!values.category) {
                    req = true;
                    errors.category = 'Required';
                }
                if (!values.addressCountry) {
                    req = true;
                    errors.addressCountry = 'Required';
                }
                if (!values.addressState) {
                    req = true;
                    errors.addressState = 'Required';
                }
                if (!values.addressCity) {
                    req = true;
                    errors.addressCity = 'Required';
                }
                if (values.keywords) {
                    const count = values.keywords.split(',').length;
                    const max = isFeatured ? 10 : 2;
                    if (count > max) {
                        inv = true;
                        errors.keywords = `Too many keywords, maximum: ${max}`;
                    }
                }
                if (!values.tos) {
                    req = true;
                    errors.tos = 'Required';
                }
                setMissingRequired(req);
                setInvalidFields(inv);
                return errors;
            }}
            onSubmit={async(values, { setSubmitting }) => {
                // Construct the variables submitted to Create Directory
                const directoryVars = {
                    // Deconstruct all form values
                    ...values,
                    // Deconstruct the social links, flattening their values on the root object
                    ...values.socialLinks,
                    // Parse keywords string into array
                    keywords: values.keywords.split(',').map(keyword => keyword.trim()).filter(keyword => !!keyword),
                    // Set featured flag
                    featured: isFeatured,
                    // Set the userId
                    userId: user?.id
                };
                if (action === 'update' && directory) {
                    directoryVars.id = directory.id;
                }
                // Set imageId with the value from the state
                let imageId = uploadedImageId;

                // Check if we have already have the uploaded imageId
                if (imageFile && !uploadedImageId) {
                    let err = false;
                    // Upload the image
                    await uploadImage({
                        variables: {
                            files: imageFile
                        }
                    }).then(res => {
                        // Get the responses image Id
                        imageId = res.data.multipleUpload[0].id;
                        // Set the states imageId, so we can prevent uploading
                        // the same image again if this has already been called
                        setUploadedImageId(imageId);
                    }).catch(() => {
                        err = true;
                        // Failed to Upload image
                        setApiError(true);
                        // No longer submitting (image upload failed)
                        setSubmitting(false);
                    });
                    if (err) {
                        return;
                    }
                }

                // If we have an image Id, add it to the submitted variables
                directoryVars.image = imageId;

                // Determine create or update
                if (action === 'add') {
                    // Add Directory
                    createDirectory({ variables: directoryVars }).then((res: any) => {
                        // Created
                        const dir: DirectoryType = res;
                        // Set the Id
                        setDirectoryId(dir.id);
                        // If it's not free
                        if (!isFree) {
                            // Open stripe checkout
                            setStripeCheckoutOpen(true);
                        }
                        // Set created
                        setCreated(true);
                    }).catch(() => {
                        // Failed to Create
                        setApiError(true);
                    }).then(() => {
                        // Submission done
                        setSubmitting(false);
                    });
                } else {
                    // Update Directory
                    updateDirectory({ variables: directoryVars }).then(() => {
                        // Updated
                        if (!isFree) {
                            // Open stripe checkout
                            setStripeCheckoutOpen(true);
                        }
                        // Set updated
                        setUpdated(true);
                    }).catch(() => {
                        // Failed to Create
                        setApiError(true);
                    }).then(() => {
                        // Submission done
                        setSubmitting(false);
                    });
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
            }) => {
                let states: Array<StateType> = [];
                if (values.addressCountry) {
                    let country: CountryStateType | null = null;
                    for (let i = 0; i < countries.length; i++) {
                        if (countries[i].name === values.addressCountry) {
                            country = countries[i];
                            break;
                        }
                    }
                    if (country) {
                        states = country && country.states;
                    }
                }

                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.textField}
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={nameLabel}
                                    helperText={(errors.name && touched.name) ? errors.name : nameHelperText || ' '}
                                    error={!!(errors.name && touched.name)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" color="secondary" error={!!(errors.category && touched.category)} fullWidth>
                                    <InputLabel>{categoryLabel}</InputLabel>
                                    <Select
                                        name="category"
                                        value={values.category}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label={categoryLabel}
                                    >
                                        {categories.map(category => (
                                            <MenuItem key={category} value={category}>{intl.get(`directory.category.${category}`).d(category)}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{(errors.category && touched.category) ? errors.category : categoryHelperText || ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.textField}
                                    name="website"
                                    value={values.website}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={websiteLabel}
                                    helperText={(errors.website && touched.website) ? errors.website : websiteHelperText || ' '}
                                    error={!!(errors.website && touched.website)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.textField}
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={phoneLabel}
                                    helperText={(errors.phone && touched.phone) ? errors.phone : phoneHelperText || ' '}
                                    error={!!(errors.phone && touched.phone)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.textField}
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={emailLabel}
                                    helperText={(errors.email && touched.email) ? errors.email : emailHelperText || ' '}
                                    error={!!(errors.email && touched.email)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.textField}
                                    name="keywords"
                                    value={values.keywords}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={keywordsLabel}
                                    helperText={(errors.keywords && touched.keywords) ? errors.keywords : keywordsHelperText || ' '}
                                    error={!!(errors.keywords && touched.keywords)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" color="secondary" error={!!(errors.addressCountry && touched.addressCountry)} fullWidth>
                                    {/* <InputLabel>{countryLabel}</InputLabel> */}
                                    <Autocomplete
                                        fullWidth
                                        filterOptions={filterCountryOptions}
                                        options={countries}
                                        value={countries.find((i) => i.name === values.addressCountry) || null}
                                        getOptionLabel={(option) => option.name}
                                        onBlur={() => {
                                            setFieldTouched('addressCountry', true);
                                        }}
                                        onChange={(_, value) => {
                                            setFieldValue('addressCountry', value?.name ?? '', true);
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            className={classes.textField}
                                            label={countryLabel}
                                            variant="outlined"
                                            name="addressCountry"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password'
                                            }}
                                            color="secondary"
                                        />}
                                    />
                                    <FormHelperText>{(errors.addressCountry && touched.addressCountry) ? errors.addressCountry : countryHelperText || ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                {states && states.length
                                    /* There is a list of states, display dropdown */
                                    ? (
                                        <FormControl variant="outlined" color="secondary" error={!!(errors.addressState && touched.addressState)} fullWidth>
                                            {/* <InputLabel>{stateLabel}</InputLabel> */}
                                            <Autocomplete
                                                options={states}
                                                filterOptions={filterStateOptions}
                                                getOptionLabel={(option) => option.name}
                                                value={states.find((i) => i.name === values.addressState) || null}
                                                onBlur={() => {
                                                    setFieldTouched('addressState', true);
                                                }}
                                                onChange={(_, value) => {
                                                    setFieldValue('addressState', value?.name ?? '', true);
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    className={classes.textField}
                                                    fullWidth
                                                    name="addressState"
                                                    label={stateLabel}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                    variant="outlined"
                                                />}
                                            />
                                            <FormHelperText>{(errors.addressState && touched.addressState) ? errors.addressState : stateHelperText || ' '}</FormHelperText>
                                        </FormControl>
                                    )
                                    /* No list of states, display text field */
                                    : (
                                        <TextField
                                            className={classes.textField}
                                            name="addressState"
                                            value={values.addressState}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label={stateLabel}
                                            helperText={(errors.addressState && touched.addressState) ? errors.addressState : stateHelperText || ' '}
                                            error={!!(errors.addressState && touched.addressState)}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    className={classes.textField}
                                    name="addressCity"
                                    value={values.addressCity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={cityLabel}
                                    helperText={(errors.addressCity && touched.addressCity) ? errors.addressCity : cityHelperText || ' '}
                                    error={!!(errors.addressCity && touched.addressCity)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    className={classes.textField}
                                    name="addressStreet1"
                                    value={values.addressStreet1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={addressLabel1}
                                    helperText={(errors.addressStreet1 && touched.addressStreet1) ? errors.addressStreet1 : streetHelperText || ' '}
                                    error={!!(errors.addressStreet1 && touched.addressStreet1)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    className={classes.textField}
                                    name="addressStreet2"
                                    value={values.addressStreet2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={addressLabel2}
                                    helperText={(errors.addressStreet2 && touched.addressStreet2) ? errors.addressStreet2 : streetHelperText || ' '}
                                    error={!!(errors.addressStreet2 && touched.addressStreet2)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    className={classes.textField}
                                    name="addressPostal"
                                    value={values.addressPostal}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={postalCodeLabel}
                                    helperText={(errors.addressPostal && touched.addressPostal) ? errors.addressPostal : postalCodeHelperText || ' '}
                                    error={!!(errors.addressPostal && touched.addressPostal)}
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ maxWidth: '300px' }}>
                                    <Hours
                                        value={values.hoursOfOperation}
                                        onChange={(newValue: HoursType) => {
                                            setFieldValue('hoursOfOperation', newValue);
                                        }}
                                        label={hoursOfOperationLabel}
                                    />
                                </Box>
                            </Grid>
                            {/* FEATURED DETAILS */}
                            {isFeatured && (
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            className={classes.textField}
                                            name="socialLinks.facebook"
                                            value={values.socialLinks.facebook}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Facebook"
                                            helperText={' '}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            className={classes.textField}
                                            name="socialLinks.instagram"
                                            value={values.socialLinks.instagram}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Instagram"
                                            helperText={' '}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            className={classes.textField}
                                            name="socialLinks.youtube"
                                            value={values.socialLinks.youtube}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="YouTube"
                                            helperText={' '}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            className={classes.textField}
                                            name="socialLinks.linkedin"
                                            value={values.socialLinks.linkedin}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Linked In"
                                            helperText={' '}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            className={classes.textField}
                                            name="socialLinks.twitter"
                                            value={values.socialLinks.twitter}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Twitter"
                                            helperText={' '}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            className={classes.textField}
                                            name="videoURL"
                                            value={values.videoURL}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label={videoLabel}
                                            placeholder={'https://www.youtube.com/watch?v=xxxxxx'}
                                            helperText={(errors.videoURL && touched.videoURL) ? errors.videoURL : videoHelperText || ' '}
                                            error={!!(errors.videoURL && touched.videoURL)}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ImageUpload setImage={handleImage} image={initialImageUrl} />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <FormControl error={!!errors.tos}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="tos"
                                                    checked={values.tos}
                                                    onChange={handleChange}
                                                    color="primary"
                                                />
                                            }
                                            className={classes.terms}
                                            label="I agree to the"
                                        />
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => window.open('https://www.racingedge.com/terms-conditions/', '_blank')}
                                        >
                                            Terms &amp; Conditions
                                        </Typography>
                                    </Box>
                                    <FormHelperText>{errors.tos ? errors.tos : ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 5, textAlign: 'center' }}>
                                    {touchedSubmit && (
                                        <Box sx={{ mb: 1 }}>
                                            <Typography variant="body2" className={classes.errorText}>{missingRequired && requiredFieldsText}</Typography>
                                            <Typography variant="body2" className={classes.errorText}>{invalidFields && invalidFieldsText}</Typography>
                                            <Typography variant="body2" className={classes.errorText}>{apiError && apiErrorText}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center" alignItems="center">
                                <Grid item xs={4}>
                                    <Box>
                                        <Button
                                            className={classes.button}
                                            color="primary"
                                            type="button"
                                            variant="contained"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                goBack && goBack();
                                            }}
                                        >
                                            {cancelAction}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Button
                                            className={classes.button}
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setApiError(false);
                                                setTouchedSubmit(true);
                                            }}
                                        >
                                            {submitAction}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Button
                                            className={classes.button}
                                            type="button"
                                            disabled={isSubmitting}
                                            color="secondary"
                                            onClick={() => {
                                                setTouchedSubmit(false);
                                                handlePreviewOpen(values);
                                            }}
                                        >
                                            {previewAction}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );

    let formCompletedElements = <></>;
    if (formCompleted && plan) {
        if (stripeCheckoutOpen) {
            formCompletedElements = (
                <StripePaymentModal
                    itemId={`${directoryId}`}
                    itemType={PAYMENT_ITEM_TYPE.DIRECTORY}
                    paymentProvider={PAYMENT_PROVIDER.STRIPE}
                    pricingPackage={plan}
                    paymentType={paymentType}
                    onClose={handleStripeClose}
                    onSuccess={handleStripeSuccess}
                />
            );
        } else {
            formCompletedElements = (
                <DirectoryFormCompleted updated={updated} isFree={isFree} stripePayed={stripePayed} openStripe={handleCompletedFormOpenStripe} />
            );
        }
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <Box sx={{ mb: 4, textAlign: 'center' }}>
                    <Box sx={{ mb: 1 }}>
                        <Typography variant="h1" className={classes.title}>{title}</Typography>
                    </Box>
                    {!!subtitle && (
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="h2" color="secondary" className={classes.title}>{subtitle}</Typography>
                        </Box>
                    )}
                    {action === 'add' && (
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="body1" color="primary" style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => {
                                goBack && goBack();
                            }}>{changePlan}</Typography>
                        </Box>
                    )}
                </Box>
                {form}
            </Box>
            {formCompletedElements}
            {!!previewDir && <PreviewDialog directory={previewDir} onClose={handlePreviewClose} featured={isFeatured} previewImage={previewImage} fullWidth />}
        </Box >
    );
};

export default DirectoryForm;
