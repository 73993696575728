import React from 'react';
import intl from 'react-intl-universal';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';

import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import FancyImageDescriptionLink from '../../components/FancyImageDescriptionLink';

import { DealerServicesPageDataType } from '../../types';
import { QUERY_DEALER_SERVICES_PAGE } from '../../queries';

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    }
}));

const DealerServicesPage = (): JSX.Element => {
    const classes = useStyles();

    const { data: dataDealerServices } = useQuery<DealerServicesPageDataType>(QUERY_DEALER_SERVICES_PAGE);

    const defaultHeaderText = intl.get('common.page.dealerServices.header').d('Dealer Services');

    const data = dataDealerServices && dataDealerServices.dealerService;
    let headerText = defaultHeaderText;

    if (data && data.title) {
        headerText = data.title;
    }

    return (
        <>
            <PageHeader label={headerText} />
            <Container>
                {
                    data && data.imageDescriptionLink && (
                        <Box className={classes.box}>
                            {
                                data.imageDescriptionLink.map((item) => <FancyImageDescriptionLink key={item.id} {...item} />)
                            }
                        </Box>
                    )
                }
            </Container>
        </>
    );
};

export default React.memo(DealerServicesPage);
