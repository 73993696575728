import React, { useEffect, useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { Button, Fab, Fade, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { CheckpointIsAuthenticated } from '../checkpoints';
import { IS_RACING_EDGE } from '../../constants';
import { useBrandConfigurationContext } from '../../hooks';

const useStyles = makeStyles(theme => ({
    sellMyVehicle: {
        margin: theme.spacing(2),
        position: 'fixed',
        bottom: 0,
        left: 0,
        // z-index should be large enough so that the button can float the page
        zIndex: 1000,
        borderRadius: '10px',
        width: 'unset',
        height: 'unset',

    },
    buttonText: {
        ...theme.typography.button,
    },
    green: {
        backgroundColor: 'green'
    }
}));

const _window = window;

const SellMyVehicleFAB = (): ReactElement => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
    const { pathname } = useLocation();
    const brandConfig = useBrandConfigurationContext();
    const [showSellMyVehicle, setShowSellMyVehicle] = useState<boolean>(true);

    const { isFloatingSellButtonDisabled } = brandConfig;

    // Check to see if we are currently on the pricing packages page
    const onPricingPackagePage = pathname.indexOf('/pricing') !== -1;

    useEffect(() => {
        // Bind the scroll event
        (_window as any).__fabScrollEvent = () => {
            setShowSellMyVehicle(false);
            clearTimeout((_window as any).__fabTimeout);
            (_window as any).__fabTimeout = setTimeout(() => setShowSellMyVehicle(true), 2000);
        };
        window.addEventListener('scroll', (_window as any).__fabScrollEvent);
        return function cleanup() {
            // Check if the scroll event was defined
            if ((_window as any).__fabScrollEvent) {
                // Unbind the scroll event
                window.removeEventListener('scroll', (_window as any).__fabScrollEvent);
            }
        };
    }, []);

    const sellMyVehicleText = intl.get('generic.fab.sellMyVehicle').d('Sell My Vehicle');

    return !onPricingPackagePage && !isFloatingSellButtonDisabled ? (
        <Fade in={showSellMyVehicle}>
            <Fab href={IS_RACING_EDGE ? '/how-to-sell' : '/pricing'} size={isMobile ? 'small' : 'large'} className={classes.sellMyVehicle}>
                <CheckpointIsAuthenticated>
                    <Button color="secondary" variant="contained" className={`${classes.buttonText} ${IS_RACING_EDGE ? classes.green : ''}`}>{sellMyVehicleText}</Button>
                </CheckpointIsAuthenticated>
            </Fab>
        </Fade>
    ) : (
        <></>
    );
};

export default SellMyVehicleFAB;
