import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { SocialLinksType } from '../../types';
import { Box } from '@mui/material';
import { IconFaFacebook, IconFaInstagram, IconFaLinkedin, IconFaTwitter, IconFaYoutube, } from '../../../../Icons';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex'
    },
    logo: {
        height: '80px',
        // Mobile height
        [theme.breakpoints.down('xl')]: {
            height: '50px',
        }
    },
    icon: ({ iconColor }: CSSProps) => {
        let color = theme.palette.text.primary;

        if (iconColor) {
            color = iconColor;
        }

        return {
            color,
            margin: theme.spacing(1),
            // Mobile height
            [theme.breakpoints.down('xl')]: {
                margin: theme.spacing(0.5),
                fontSize: '1rem',
            }
        };
    }
}));

interface CSSProps {
    iconColor?: string;
}

interface Props {
    socialLinks: SocialLinksType;
    iconColor?: string;
}

const SocialLinks = ({ socialLinks, iconColor }: Props): ReactElement => {
    const classes = useStyles({ iconColor });

    if (socialLinks) {
        const { facebook, twitter, linkedin, instagram, youtube } = socialLinks;
        return (
            <Box className={classes.container}>
                {
                    facebook && (
                        <a href={facebook} target="_blank" rel="noreferrer">
                            <IconFaFacebook className={classes.icon}/>
                        </a>
                    )
                }
                {
                    twitter && (
                        <a href={twitter} target="_blank" rel="noreferrer">
                            <IconFaTwitter className={classes.icon}/>
                        </a>
                    )
                }
                {
                    linkedin && (
                        <a href={linkedin} target="_blank" rel="noreferrer">
                            <IconFaLinkedin className={classes.icon}/>
                        </a>
                    )
                }
                {
                    instagram && (
                        <a href={instagram} target="_blank" rel="noreferrer">
                            <IconFaInstagram className={classes.icon}/>
                        </a>
                    )
                }
                {
                    youtube && (
                        <a href={youtube} target="_blank" rel="noreferrer">
                            <IconFaYoutube className={classes.icon}/>
                        </a>
                    )
                }
            </Box>
        );
    }

    return <></>;
};

export default SocialLinks;
