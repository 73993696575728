import React from 'react';
import intl from 'react-intl-universal';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { FieldCheckbox } from '../../../common/components/fields';
import Button from '../../../common/components/Button';

import { useWizardContext } from '../../../common/hooks';
import { schema } from './validation';
import { useFormDataContext } from '../../../common/hooks/useFormDataContext';
import { useAuctionContext } from '../../hooks/useAuctionContext';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    buttons: {
        margin: theme.spacing(5, 0, 0),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    termsAndConditions: {
        width: '100%'
    },
    link: {
        textDecoration: 'unset',
        fontWeight: 'bold',
    }
}));

type InputsType = {
    name: string;
    phone?: string;
    email: string;
    hasAgreedToTerms: boolean;
}

const Submission = (): JSX.Element => {
    const classes = useStyles();
    const { handleBack, handleNext, activePageIndex, steps, submitting } = useWizardContext();
    const { setValues } = useFormDataContext();
    const auction = useAuctionContext();

    let nameDef = '';
    let phoneDef = '';
    let emailDef = '';

    if (auction) {
        // Get the Auction user to set our defaults
        const { firstName, lastName, phone: userPhone, email: userEmail } = auction.user;

        if (firstName) {
            nameDef = `${firstName}`;
            if (lastName) {
                nameDef = `${firstName} ${lastName}`;
            }
        }

        if (userPhone) {
            phoneDef = userPhone;
        }

        if (userEmail) {
            emailDef = userEmail;
        }

        // if the auction already had previous contact info, use that instead
        if (auction.contactInfo) {
            const { email, name, phone } = auction.contactInfo;
            if (name) {
                nameDef = name;
            }
            if (phone) {
                phoneDef = phone;
            }
            if (email) {
                emailDef = email;
            }
        }
    }

    const defaultValues: InputsType = {
        name: nameDef,
        phone: phoneDef,
        email: emailDef,
        // User always needs to accept terms
        hasAgreedToTerms: false,
    };

    const methods = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { handleSubmit, register, formState: { errors } } = methods;

    const onSubmit = (data: InputsType) => {
        setValues && setValues(data);
        handleNext && handleNext();
    };

    const nextText = intl.get('auction.auctionWizard.button.next').d('Next');
    const submitText = intl.get('auction.auctionWizard.button.submit').d('Submit');
    const backText = intl.get('auction.auctionWizard.button.back').d('Back');
    const cancelText = intl.get('auction.auctionWizard.button.cancel').d('Cancel');

    const nameText = intl.get('auction.form.submission.input.name').d('Full Name');
    const phoneText = intl.get('auction.form.submission.select.phone').d('Phone Number');
    const emailText = intl.get('auction.form.submission.input.email').d('Email');
    const agreementText = intl.getHTML('auction.form.submission.select.agreement').d(
        <span>
            I agree to the <a target="_blank" className={classes.link} href="/page/terms-and-conditions">Terms &amp; Conditions</a> and <a target="_blank" className={classes.link} href="/page/privacy-policy">Privacy Policy</a>
        </span>
    );

    return (
        <FormProvider {...methods}>
            <Container>
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction="row" spacing={4} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="form-submission-input-name"
                                    inputRef={register}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    autoFocus
                                    color="secondary"
                                    label={nameText}
                                    name="name"
                                    helperText={errors.name?.message}
                                    error={!!errors.name}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField
                                    id="form-submission-input-email"
                                    inputRef={register}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    autoFocus
                                    color="secondary"
                                    label={emailText}
                                    name="email"
                                    helperText={errors.email?.message}
                                    error={!!errors.email}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField
                                    id="form-submission-input-phone"
                                    inputRef={register}
                                    variant="outlined"
                                    fullWidth
                                    autoFocus
                                    color="secondary"
                                    label={phoneText}
                                    name="phone"
                                    helperText={errors.phone?.message}
                                    error={!!errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FieldCheckbox
                                    id="form-submission-input-agreement"
                                    register={register}
                                    variant="outlined"
                                    fullWidth
                                    color="secondary"
                                    label={agreementText}
                                    name="hasAgreedToTerms"
                                    errorMessage={errors.hasAgreedToTerms?.message}
                                    error={!!(errors.hasAgreedToTerms)}
                                />
                            </Grid>
                        </Grid>
                        <Box className={classes.buttons}>
                            <Button
                                id="form-submission-input-back-or-cancel"
                                type="button"
                                variant="contained"
                                color={activePageIndex !== 0 ? 'secondary' : 'primary'}
                                onClick={handleBack}
                                text={activePageIndex !== 0 ? backText : cancelText}
                            />
                            <Button
                                id="form-submission-input-next-or-submit"
                                color="secondary"
                                variant="contained"
                                type="submit"
                                disabled={submitting}
                                text={activePageIndex === (steps as number) - 1 ? submitText : nextText}
                            />
                        </Box>
                    </form>
                </div>
            </Container>
        </FormProvider>
    );
};

export default Submission;
