import React from 'react';
import intl from 'react-intl-universal';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    DialogTitle,
    DialogContent,
    Dialog as MuiDialog,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';

import FeaturedCard from '../FeaturedCard';
import NonFeaturedCard from '../NonFeaturedCard';

import { DirectoryType } from '../../types';

const Dialog = withStyles({
    paper: {
        borderRadius: 0,
    },
})(MuiDialog);

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold'
    },
    button: {
        ...theme.typography.button,
    },
}));

// Define the PreviewDialogs Property Types
export interface Props {
    // Component Properties
    fullWidth?: boolean;
    featured: boolean;
    directory: DirectoryType;
    onClose: () => void;
    previewImage?: string;
}

// The PreviewDialog component
const PreviewDialog = ({ featured, fullWidth, directory, onClose, previewImage }: Props): JSX.Element => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const Card = featured ? FeaturedCard : NonFeaturedCard;

    const title = intl.get('directory.form.preview.title').d('Preview');
    const closeAction = intl.get('generic.action.close').d('Close');

    // Render a dialog with the contents determined by the action
    return (
        <Dialog open={true} onClose={handleClose} fullWidth={!!fullWidth}>
            <DialogTitle>
                <Typography variant="h2" className={classes.title}>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Card directory={directory} previewImage={previewImage} />
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} color="primary" variant="contained" onClick={handleClose}>{closeAction}</Button>
            </DialogActions>
        </Dialog>
    );
};

// Default export is the PreviewDialog component
export default PreviewDialog;
