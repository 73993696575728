import React, { useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import makeStyles from '@mui/styles/makeStyles';
import { Box, FormControl, FormLabel, TextField, Typography, InputAdornment, Grid } from '@mui/material';
import NumberFormat from 'react-number-format';

import BidNowButton from '../../containers/BidNowButton';

import { useSocketContext } from '../../hooks/useSocketContext';
import { localizeCurrency } from '../../../common/helpers';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    gridContainer: {
        alignItems: 'end'
    },
    label: {
        fontWeight: 'bold',
        fontFamily: 'Avenir',
        fontSize: 20,
        color: theme.palette.common.black,
        margin: theme.spacing(4, 1, 1)
    },
    bidInput: {
        marginBottom: theme.spacing(1)
    },
    adornment: {
        backgroundColor: `${theme.palette.common.black}3`,
        padding: `Calc(${theme.spacing(2)} + 0.5px) 1em`,
        // color: theme.palette.common.white,
        fontWeight: 'bold',
        marginRight: '-1em',
        borderTopRightRadius: `${theme.shape.borderRadius}px`,
        borderBottomRightRadius: `${theme.shape.borderRadius}px`
    },
    button: {
        margin: theme.spacing(4, 1, 'calc(1.57em + 2px)'),
        [theme.breakpoints.down('xl')]: {
            margin: theme.spacing(0, 0, 1)
        },
    }
}));

interface Props {
    isMaxBid?: boolean;
    autoFocus?: boolean;
    label?: string;
    placeholder?: string;
    subtext?: string;
}

// If the min increment amount is undefined or 0, set it to 1
const MIN_INC_FAIL_SAFE = 1;

const BidNowField = ({ isMaxBid = false, autoFocus = false, label, placeholder, subtext }: Props): ReactElement => {
    const classes = useStyles();
    const { topBid, minIncrementAmount, currency } = useSocketContext();

    const minBid = minIncrementAmount ? minIncrementAmount + topBid : topBid + MIN_INC_FAIL_SAFE;
    const minimumBid = localizeCurrency(minBid, currency);

    const [bidAmount, setBidAmount] = useState<number>(minBid);

    const id = `bidNow-field${isMaxBid ? '-max' : ''}`;
    let placeholderText = intl.get('auction.bidNowField.placeholder.default').d('Enter your bid');
    let labelText = intl.get('auction.bidNowField.label.default').d('Place Bid');
    const minimumText = intl.get('auction.bidNowField.minimum').d('Minimum');

    if (label) {
        labelText = label;
    }

    if (placeholder) {
        placeholderText = placeholder;
    }

    const handleChange = (value?: number) => {
        // const val = event.target.value;
        if (value) {
            setBidAmount(value);
        }
    };

    return (
        <FormControl className={classes.formControl} component="fieldset">
            <Grid className={classes.gridContainer} container>
                <Grid md={8} xs={12} className={classes.bidInput}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <FormLabel className={classes.label}>{labelText}</FormLabel>
                        {
                            subtext && (
                                <Typography variant="caption">{subtext}</Typography>
                            )
                        }
                    </Box>
                    <NumberFormat
                        id={id}
                        name={id}
                        allowNegative={false}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        onValueChange={(v) => handleChange(v.floatValue)}
                        value={bidAmount}
                        customInput={TextField}
                        color="secondary"
                        autoFocus={autoFocus}
                        placeholder={placeholderText}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            endAdornment:
                                <InputAdornment position={'end'}><p className={classes.adornment}>{currency}</p></InputAdornment>
                        }}
                    />
                    <Typography variant="subtitle2">{minimumText}: {minimumBid}</Typography>
                </Grid>
                <Grid md={4} xs={12}>
                    <Box
                        className={classes.button}
                        sx={{ margin: {
                            xs: 0
                        }
                        }}>
                        <BidNowButton
                            id={`${id}-button`}
                            amount={bidAmount}
                        />
                    </Box>
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default React.memo(BidNowField);
