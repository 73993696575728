import React from 'react';
import { Container as MUIContainer, ContainerProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        maxWidth: '95vw',
    }
}));

interface Props extends ContainerProps {
    children?: React.ReactNode;
}

const Container = ({ children, ...rest }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <MUIContainer className={classes.container} {...rest}>
            {children}
        </MUIContainer>
    );
};

export default Container;
