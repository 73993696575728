import React, { useCallback, useEffect, useReducer, useState } from 'react';
import intl from 'react-intl-universal';
import { Box, Paper, Typography, Stepper, Step, StepLabel, Button, Container, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useWizardContext, WizardContext } from '../../hooks/useWizardContext';
import { scrollToTop } from '../../helpers';
import { WIZARD_SUBMITTING_STATE_DELAY } from '../../constants';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0),
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        width: '100%'
    },
    mobileTitle: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    divider: {
        border: `2px solid ${theme.palette.primary.main}`,
        width: '25%',
    }
}));

type ReducerActionsType = {
    NEXT_PAGE: string;
    PREV_PAGE: string;
    SET_STEPS: string;
}

type ReducerActionType = {
    type: string;
    payload?: any;
}

type ReducerStateType = {
    activePageIndex: number;
    steps: number;
}

interface ButtonNextProps {
    disabled?: boolean
}

interface Props {
    title: string;
    stepLabels: Array<string>;
    onSubmit?: () => void;
    onCancel?: () => void;
    children?: React.ReactNode;
}

const defaultInitialState: ReducerStateType = {
    activePageIndex: 0,
    steps: 0
};

const actions: ReducerActionsType = {
    NEXT_PAGE: 'NEXT_PAGE',
    PREV_PAGE: 'PREV_PAGE',
    SET_STEPS: 'SET_STEPS'
};

const defaultReducer = (state: ReducerStateType, action: ReducerActionType): ReducerStateType => {
    const { activePageIndex } = state;
    switch (action.type) {
        case actions.NEXT_PAGE:
            return { ...state, activePageIndex: activePageIndex + 1 };
        case actions.PREV_PAGE:
            return { ...state, activePageIndex: activePageIndex - 1 };
        case actions.SET_STEPS:
            return { ...state, steps: action.payload };
        default:
            return state;
    }
};

const ButtonPrevious = () => {
    const classes = useStyles();
    const { activePageIndex, handleBack } = useWizardContext();

    const backText = intl.get('common.wizard.button.back').d('Back');
    const cancelText = intl.get('common.wizard.button.cancel').d('Cancel');

    return (
        <Box>
            <Button
                color={activePageIndex !== 0 ? 'secondary' : 'primary'}
                onClick={handleBack}
                fullWidth
                variant="contained"
                className={classes.button}
            >
                {activePageIndex !== 0 ? backText : cancelText}
            </Button>
        </Box>
    );
};

const ButtonNext = ({ disabled }: ButtonNextProps) => {
    const classes = useStyles();
    const { activePageIndex, handleNext, steps } = useWizardContext();

    const nextText = intl.get('common.wizard.button.next').d('Next');
    const submitText = intl.get('common.wizard.button.submit').d('Submit');

    if (!steps) {
        return <></>;
    }

    return (
        <Box>
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleNext}
                disabled={!!disabled}
                className={classes.button}
            >
                {activePageIndex === steps - 1 ? submitText : nextText}
            </Button>
        </Box>
    );
};

const Pages = ({ children }) => {
    const { activePageIndex, setSteps } = useWizardContext();
    const pages = React.Children.toArray(children);
    const steps = React.Children.count(children);

    useEffect(() => {
        setSteps && setSteps(steps);
    }, [setSteps, steps]);

    const currentStep = pages[activePageIndex ? activePageIndex : 0];

    return (
        <Container>
            {currentStep}
        </Container>
    );
};

const Wizard = ({ title, stepLabels, onSubmit, onCancel, children }: Props): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const [{ steps, activePageIndex }, dispatch] = useReducer(defaultReducer, defaultInitialState);
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
    const [submitting, setSubmitting] = useState<boolean>(false);

    const setSteps = useCallback(
        (n) => {
            dispatch({ type: actions.SET_STEPS, payload: n });
        },
        [dispatch]
    );

    const handleNext = async() => {
        if (activePageIndex === steps - 1) {
            onSubmit && onSubmit();
            setSubmitting(true);
            // set the submitting state to false after 10 seconds
            setTimeout(() => {
                setSubmitting(false);
            }, WIZARD_SUBMITTING_STATE_DELAY);
        } else {
            dispatch({ type: actions.NEXT_PAGE });
        }
        scrollToTop('wizard-pages-title');
    };

    const handleBack = () => {
        if (activePageIndex === 0) {
            onCancel && onCancel();
        } else {
            dispatch({ type: actions.PREV_PAGE });
        }
        scrollToTop('wizard-pages-title');
    };

    const context = {
        activePageIndex,
        submitting,
        steps,
        setSteps,
        handleNext,
        handleBack,
    };

    return (
        <WizardContext.Provider value={context}>
            <Paper className={classes.paper}>
                <Typography id="wizard-pages-title" variant="h2" align="center">
                    {title}
                </Typography>
                {
                    isMobile ? (
                        <Box>
                            <Box sx={{ mt: 2, mb: 4 }}>
                                <Typography variant="h4" className={classes.mobileTitle}>{stepLabels[activePageIndex]}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                    <Divider className={classes.divider} variant="middle" />
                                </Box>
                            </Box>
                            { children }
                        </Box>
                    ) : (
                        <>
                            <Stepper activeStep={activePageIndex} className={classes.stepper}>
                                {stepLabels.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            { children }
                        </>
                    )
                }
            </Paper>
        </WizardContext.Provider>
    );
};

Wizard.Pages = Pages;
Wizard.ButtonNext = ButtonNext;
Wizard.ButtonPrevious = ButtonPrevious;

export default Wizard;
