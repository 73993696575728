import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import intl from 'react-intl-universal';

const acceptTermsText = intl.get('common.form.register.checkbox.acceptTerms.1').d('I agree to the');
const acceptTerms2Text = intl.get('common.form.register.checkbox.acceptTerms.2').d('Terms & Conditions');

interface Props {
    startText?:string;
}

const LinkTerms:React.FC<Props> = ({ startText }) => (<Typography>
    {startText ? startText : acceptTermsText}
    {' '}
    <RRLink to="/page/terms-and-conditions" target="_blank">{acceptTerms2Text}</RRLink>
</Typography>);

export default LinkTerms;
