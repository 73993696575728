import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useForm } from 'react-hook-form';
import {
    Grid,
    TextField,
    Box,
    Container,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yupResolver } from '@hookform/resolvers/yup';

import SubmitButton from '../../../common/components/Button';
import PoweredBy from '../../../common/components/PoweredBy';

import { useAuthenticatedUserContext, useBrandConfigurationContext, useNotification } from '../../../common/hooks';
import { DEFAULT_CONTACT_PHONE_NUMBER, VARIATIONS } from '../../../common/constants';
import { sendEmail } from '../../../common/request';
import { schema } from './validation';

const useStyle = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        cursor: 'pointer'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
    bar: {
        marginTop: theme.spacing(2),
        width: '70%',
        height: '4px',
        backgroundColor: theme.palette.divider,
    },
}));

type InputsType = {
    subject: string;
    body: string;
}

interface Props {
    onSend?: () => void;
    subtitle: string;
    sellerEmail: string;
    embedded?: boolean;
}

const EmailSeller = ({ onSend, sellerEmail, subtitle, embedded = false }: Props): JSX.Element => {
    const classes = useStyle();
    const brandConfig = useBrandConfigurationContext();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const notify = useNotification();
    const { user } = useAuthenticatedUserContext();
    const potentialBuyer = user?.email || '';

    const { companyName, contactUsConfiguration, theme: configTheme } = brandConfig;
    const { email: contactEmail, phoneNumber } = contactUsConfiguration;
    const { loginVariation } = configTheme;

    const inheritStyle = {
        backgroundColor: 'inherit',
        color: 'inherit',
    };

    const currentCompanyName = companyName ? companyName : 'Racing Edge';
    const contactPhone = phoneNumber && phoneNumber.length > 0 ? phoneNumber : DEFAULT_CONTACT_PHONE_NUMBER;

    const defaultValues = {
        subject: '',
        body: '',
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<InputsType>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSubmit = (data: InputsType) => {
        if (!potentialBuyer) {
            const message = intl.get('auction.form.emailSeller.sendEmail.nouser').d('Must be logged in to send email.');
            notify({ message, severity: 'error' });
            return;
        }
        setSubmitting(true);

        const templateVariables = {
            'v:header': 'Auction Correspondence',
            'v:subheader': `${subtitle}`,
            'v:content': `${data.body} - Sent by: ${potentialBuyer}`,
            'v:contactPhone': contactPhone
        };

        const emailObj = {
            to: sellerEmail,
            from: `${currentCompanyName} Official <${contactEmail}>`,
            subject: `Auction [${subtitle}] Correspondence`,
            template: 'general',
            'o:tag': ['auction', 'emailSeller', subtitle],
            ...templateVariables
        };

        sendEmail(emailObj)
            .then((res) => {
                const message = intl.get('auction.form.emailSeller.sendEmail.success').d('Email sent');
                notify({ message, severity: 'success' });

                setSubmitting(false);
                onSend && onSend();
            })
            .catch((err) => {
                const { message } = err;
                notify({ message, severity: 'error' });
                setSubmitting(false);
            });
    };

    const titleText = intl.get('auction.forms.emailSeller.title').d('Email Seller');
    const sendText = intl.get('auction.form.emailSeller.button.submit').d('Send');
    const subjectText = intl.get('auction.form.emailSeller.input.subject').d('Subject');
    const bodyText = intl.get('auction.form.emailSeller.input.body').d('Message');

    const formJSX = (
        <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            id="form-emailSeller-input-subject"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={subjectText}
                            name="subject"
                            autoFocus={!embedded}
                            helperText={errors.subject ? errors.subject.message : ''}
                            error={!!errors.subject}
                            inputProps={{ style: inheritStyle }}
                            InputProps={{ style: inheritStyle }}
                            InputLabelProps={{ style: inheritStyle }}
                            FormHelperTextProps={{ style: inheritStyle }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="form-emailSeller-input-body"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={5}
                            name="body"
                            label={bodyText}
                            type="message"
                            helperText={errors.body ? errors.body.message : ''}
                            error={!!errors.body}
                            inputProps={{ style: inheritStyle }}
                            InputProps={{ style: inheritStyle }}
                            InputLabelProps={{ style: inheritStyle }}
                            FormHelperTextProps={{ style: inheritStyle }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} md={4}>
                                <SubmitButton
                                    id="form-emailSeller-button-send"
                                    type="submit"
                                    fullWidth
                                    text={sendText}
                                    disabled={submitting}
                                    className={classes.submit}
                                    color={loginVariation === VARIATIONS.FILLED_SECONDARY ? 'primary' : 'secondary'}
                                />
                            </Grid>
                            {
                                embedded && (
                                    <Grid item>
                                        <Box sx={{ maxWidth: 150 }}>
                                            <PoweredBy />
                                        </Box>
                                    </Grid>
                                )
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </form>
        </div>
    );

    return (
        <>
            <Box sx={{ mb: embedded ? 0 : 3, textAlign: embedded ? 'left' : 'center' }}>
                <Box sx={{ mb: 1, display: 'inline-block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h1" component="h2">{titleText}</Typography>
                        {!embedded && <Box className={classes.bar}></Box>}
                    </Box>
                </Box>
                {
                    !embedded && subtitle.length > 0 && (
                        <Typography variant="subtitle1">{subtitle}</Typography>
                    )
                }
            </Box>
            {
                embedded ? (
                    <>
                        {formJSX}
                    </>
                ) : (
                    <Container maxWidth="xs">
                        {formJSX}
                    </Container>
                )
            }
        </>
    );
};

export default EmailSeller;
