import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import { ImageLinkType } from '../../types';
import { getImageUrl, isRelativeUrl } from '../../helpers';

// Define the classes for use in the Header component
const useStyles = makeStyles((theme) => ({
    svg: {
        width: '100%'
    }
}));

interface Props {
    ad: ImageLinkType
    linkClass?: string
    logoClass?: string
    alt?: string
    id?: string
}

// The LogoLink component
const LogoLink = ({ ad, linkClass, logoClass, alt, id }: Props): JSX.Element => {
    const classes = useStyles();

    const { image, url } = ad;

    const logoImage = getImageUrl(image, 'small');
    const isSVG = logoImage.indexOf('.svg') !== -1;

    const logoText = intl.get('generic.alt.logo').d('Logo');

    if (url && url.length > 0) {
        const isRelative = isRelativeUrl(url);

        // Render the LogoLink
        return isRelative ? (
            <Link id={id ? id : ''} className={`${linkClass ? linkClass : ''} ${isSVG ? classes.svg : ''}`} to={url}>
                <img className={`${logoClass ? logoClass : ''} ${isSVG ? classes.svg : ''}`} src={logoImage} alt={alt ? alt : logoText} />
            </Link>
        ) : (
            <a id={id ? id : ''} className={`${linkClass ? linkClass : ''} ${isSVG ? classes.svg : ''}`} href={url}>
                <img className={`${logoClass ? logoClass : ''} ${isSVG ? classes.svg : ''}`} src={logoImage} alt={alt ? alt : logoText} />
            </a>
        );
    }

    return (
        <>
            <img className={`${logoClass ? logoClass : ''} ${isSVG ? classes.svg : ''}`} src={logoImage} alt={alt ? alt : logoText} />
        </>
    );
};

export default LogoLink;
