import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';

import { BRAND, PAYMENT_TYPE } from '../../constants';
import { createCROPayment } from '../../request';
import { IntentOptionsType, PaymentParamsBaseType } from '../../types';

type Props = PaymentParamsBaseType;

const CryptoPaymentButton = ({ itemId, itemType, pricingPackage, paymentType, paymentProvider, promotion, onSuccess, onFailure }: Props): JSX.Element => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [paymentId, setPaymentId] = useState<string | undefined>(undefined);
    const [hasRendered, setHasRendered] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!initialized) {
            if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
                const props: IntentOptionsType = {
                    itemId,
                    itemType,
                    packageId: pricingPackage?.id || '',
                    paymentType,
                    paymentProvider,
                    brand: BRAND,
                    promotion
                };

                createCROPayment(props)
                    .then(({ id }) => {
                        setPaymentId(id);
                    })
                    .catch((err) => {
                        onFailure && onFailure();
                    });
            }
            setInitialized(true);
        }
    }, [initialized, itemId, itemType, onFailure, paymentProvider, paymentType, pricingPackage?.id, promotion]);

    useEffect(() => {
        if (paymentId && initialized && !hasRendered) {
            (window as any).cryptopay.Button({
                createPayment: function(actions) {
                    return actions.payment.fetch(paymentId);
                },
                onApprove: function(data) {
                    onSuccess(data);
                }
            }).render('#cro-pay-button');
            setHasRendered(true);
            setLoading(false);
        }
    }, [hasRendered, initialized, onSuccess, paymentId]);

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            { loading && <Skeleton variant="rectangular" height={50} width="100%" /> }
            <Box id="cro-pay-button"/>
        </Box>
    );
};

export default CryptoPaymentButton;
