import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { stripHtml } from 'string-strip-html';

const useStyles = makeStyles(theme => ({
    description: {
        // Height of 3 lines
        height: 65,
        fontSize: 16,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        boxOrient: 'vertical',
        color: theme.palette.common.black,
        [theme.breakpoints.down('xl')]: {
            fontSize: 12,
            height: 53,
        }
    },
}));

interface Props {
    description: string;
    excerpt?: string;
}

const CardDescription = ({ description, excerpt }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Typography
            className={classes.description}
            align="left"
            variant="body2"
        >
            {
                excerpt ? excerpt : stripHtml(description ? description : '').result
            }
        </Typography>
    );
};

export default CardDescription;
