import React from 'react';
// import intl from 'react-intl-universal';
import { Box } from '@mui/material';

import FAQ from '../../../common/containers/FAQ';
import AlgoliaResults from '../../containers/AlgoliaResults';
import CategoryInfo from '../../containers/CategoryInfo';

import { FAQ_PAGE } from '../../../common/constants';

const HomePage = (): JSX.Element => (
    <>
        <Box sx={{ mt: 2 }}>
            <AlgoliaResults />
            <CategoryInfo />
        </Box>
        <FAQ page={FAQ_PAGE.DIRECTORY} />
    </>
);

export default HomePage;
