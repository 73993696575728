import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

import Offering from '../Offering';

import { PictureInfoType } from '../../types';

const useStyles = makeStyles(theme => ({
    offeringContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
        }
    }
}));

interface Props {
    offerings: Array<PictureInfoType>;
}

const Offerings = ({ offerings }: Props): JSX.Element => {
    const classes = useStyles();

    return offerings.length > 0 ? (
        <Box className={classes.offeringContainer}>
            {
                offerings.map((offering, index) => <Offering key={offering.id} {...offering} index={index} />)
            }
        </Box>
    ) : (
        <></>
    );
};

export default Offerings;
