import React, { useState, CSSProperties } from 'react';
import intl from 'react-intl-universal';
import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { IconMdStarBorder, IconMdStar, } from '../../../../Icons';
import { Box, IconButton, Tooltip } from '@mui/material';

import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';
import AuthenticationDialog from '../../../common/containers/AuthenticationDialog';

import { DirectoryType } from '../../types';
import { CREATE_DIRECTORY_BOOKMARK_QUERY, DELETE_DIRECTORY_BOOKMARK_QUERY } from '../../mutations';
import { useAuthenticatedUserContext, useLoggedIn } from '../../../common/hooks';
import { addBookmarkId, removeBookmarkId } from '../../helpers';
import useBookmarkIds from '../../hooks/useBookmarkIds';
import { WHITE_LABEL_BRANDS } from '../../../common/constants';

type VoidFunctionType = () => void;

interface Props {
    // Component Properties
    directory: DirectoryType;
    background?: boolean;
}

const Bookmark = ({ directory, background }: Props): JSX.Element => {
    const theme = useTheme();
    const { user } = useAuthenticatedUserContext();
    const [isLoggedIn] = useLoggedIn();
    const [bookmarkIds] = useBookmarkIds();
    // Flag which determines if the add/remove call is currently being processed
    const [processing, setProcessing] = useState(false);

    // Wether or not the dialog is currently open
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);

    // Login dialog callback function - executed when the user logs in
    const [loginCallback, setLoginCallback] = useState<VoidFunctionType | undefined>(undefined);

    // Add Bookmark
    const [createBookmark] = useMutation(CREATE_DIRECTORY_BOOKMARK_QUERY);
    // Remove Bookmark
    const [deleteBookmark] = useMutation(DELETE_DIRECTORY_BOOKMARK_QUERY);

    // True if this current item is bookmarked
    const isBookmarked = !!bookmarkIds.find((id) => id === directory.id);

    const addFavourite = intl.get('directory.favourites.bookmarks.add').d('Add Favourite');
    const removeFavourite = intl.get('directory.favourites.bookmarks.remove').d('Remove Favourite');

    const doBookmark = () => {
        // Flag that add/remove processing
        setProcessing(true);
        // Check if already a bookmark
        if (!isBookmarked) {
            // Add the bookmark
            createBookmark({
                variables: {
                    directoryId: Number(directory.id),
                    userId: Number(user?.id || 0),
                    brand: process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND : WHITE_LABEL_BRANDS.RACING_EDGE
                }
            }).then(() => {
                // Successfully added bookmark
                addBookmarkId(directory.id);
                setProcessing(false);
            }).catch(() => {
                // Failed to add bookmark
                // TODO: friendly notification
                setProcessing(false);
            });
        } else {
            // Remove the bookmark
            deleteBookmark({
                variables: {
                    directoryId: directory.id,
                    userId: user?.id,
                }
            }).then(() => {
                // Successfully removed bookmark
                removeBookmarkId(directory.id);
                setProcessing(false);
            }).catch(() => {
                // Failed to remove bookmark
                // TODO: friendly notification
                setProcessing(false);
            });
        }
    };

    // Function to handle clicking the bookmark
    const handleClick = (event: any) => {
        // Prevents the containing element from responding to this click
        // i.e. stops search results from expanding/collapsing when adding/removing bookmark
        event.stopPropagation();
        // if not logged in, prompt
        if (!isLoggedIn) {
            // If passing a function to a 'setState' method it is executed
            // and its returned value is what is set, so since we want to
            // set a callback, we need to use a function to return it.
            setLoginCallback(() => doBookmark);
            // Open the login dialog
            setLoginDialogOpen(true);
        } else {
            doBookmark();
        }
    };

    const iconBtnStyle: CSSProperties = {};
    if (background) {
        iconBtnStyle.background = theme.palette.mode === 'light' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)';
    }

    return (
        <Box>
            {loginDialogOpen && <AuthenticationDialog action="login" onClose={() => setLoginDialogOpen(false)} onLogin={loginCallback} />}
            <CheckpointIsAuthenticated>
                <Tooltip enterTouchDelay={50} title={isBookmarked ? removeFavourite : addFavourite}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        color="secondary"
                        style={iconBtnStyle}
                        disabled={processing}
                        aria-label="favourite"
                    >
                        {isBookmarked
                            ? <IconMdStar />
                            : <IconMdStarBorder />
                        }
                    </IconButton>
                </Tooltip>
            </CheckpointIsAuthenticated>
        </Box>
    );
};

export default Bookmark;
