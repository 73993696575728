import React, { ReactElement, useState } from 'react';
import intl from 'react-intl-universal';
import { IconButton, Tooltip } from '@mui/material';
import { IconMdRefresh, } from '../../../../Icons';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    refresh: {
        margin: 'auto',
    },
    spin: {
        margin: 'auto',
        animation: '$spin 1s 1',
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    }
}));

interface Props {
    onRefresh: () => void;
}

const RefreshButton = ({ onRefresh }: Props): ReactElement => {
    const classes = useStyles();
    const [spin, setSpin] = useState<boolean>(false);

    const handleRefresh = () => {
        setSpin(true);
        setTimeout(() => {
            onRefresh();
            setSpin(false);
        }, 1000);
    };

    const refreshText = intl.get('common.refresh.tooltip').d('Refresh Data');

    return (
        <Tooltip enterTouchDelay={50} title={refreshText}>
            <IconButton onClick={handleRefresh} disabled={spin} size="large">
                <IconMdRefresh
                    className={spin ? classes.spin : classes.refresh}
                />
            </IconButton>
        </Tooltip>
    );
};

export default RefreshButton;
