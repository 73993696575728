import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { QuoteType } from '../../types';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    },
    authorContainer: {
        border: `1px ${theme.palette.primary.main} solid`,
        borderRadius: 20,
        padding: theme.spacing(0.5, 2),
        margin: theme.spacing(1, 0),
    },
    author: {
        fontSize: 12,
        color: theme.palette.common.black,
        [theme.breakpoints.down('xl')]: {
            fontSize: 9,
        }
    },
    text: {
        width: '100%',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 27,
        fontWeight: 'bold',
        color: theme.palette.common.black,
        [theme.breakpoints.down('xl')]: {
            fontSize: 15,
        }
    }
}));

type Props = QuoteType;

const Quote = ({ text, author }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Typography className={classes.text}>&ldquo;{text}&rdquo;</Typography>
            {
                author && (
                    <Box className={classes.authorContainer}>
                        <Typography className={classes.author}>- {author}</Typography>
                    </Box>
                )
            }
        </Box>
    );
};

export default Quote;
