import React from 'react';
import intl from 'react-intl-universal';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';

import poweredByLogo from '../../assets/poweredBy-logo.svg';

const useStyles = makeStyles(theme => ({
    poweredBy: {
        fontWeight: 100,
    },
    poweredByCompany: {
        ...theme.typography.subtitle2,
        fontWeight: 'bold',
        fontFamily: 'Usuzi, sans-serif'
    },
}));

interface Props {
    type?: 'logo' | 'text';
}

const PoweredBy = ({ type = 'logo' }: Props): JSX.Element => {
    const classes = useStyles();

    const poweredByText = intl.get('common.page.header.whitelabel.poweredBy').d('Powered By');
    const companyNameText = intl.get('common.companyName').d('RACINGEDGE');

    let poweredByJsx = <></>;

    switch (type) {
        case 'logo': {
            poweredByJsx = <img src={poweredByLogo} alt={`${poweredByText} ${companyNameText}`} width="100%" height="100%" />;
            break;
        }
        case 'text': {
            poweredByJsx = <Typography variant="subtitle2" className={classes.poweredBy}>{poweredByText} <span className={classes.poweredByCompany}>{companyNameText}</span></Typography>;
            break;
        }
    }

    return (
        <Box>
            {poweredByJsx}
        </Box>
    );
};

export default PoweredBy;
