import React from 'react';
import intl from 'react-intl-universal';
import makeStyles from '@mui/styles/makeStyles';
import { Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, FormHelperText, InputLabel, SelectProps, Typography } from '@mui/material';

const useStyle = makeStyles(theme => ({
    menuItem: {
        // textTransform: 'capitalize',
        textAlign: 'left'
    },
}));


type ItemType = {
    key: string | number;
    label: string;
    value: any;
}

interface Props extends SelectProps {
    id: string;
    label: string;
    name: string;
    control: any;
    items: Array<ItemType>;
    required?: boolean;
    error?: boolean;
    errorMessage?: string;
}

const SelectField = ({ label, name, control, id, items, required, error, errorMessage, ...rest }: Props): JSX.Element => {
    const classes = useStyle();

    const placeholderText = intl.get('common.field.select.placeholder.none').d('Select an option');

    return (
        <Controller
            control={control}
            name={name}
            render={(props) => (
                <FormControl
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    required={required}
                    error={error}
                >
                    <InputLabel variant="outlined" id={`${name}-select-label`}>{label}</InputLabel>
                    <Select
                        {...props}
                        id={id}
                        label={label}
                        labelId={`${name}-select-label`}
                        {...rest}
                    >
                        <MenuItem value="">
                            <em>{placeholderText}</em>
                        </MenuItem>
                        {
                            items && items.map(({ key, label, value }) => <MenuItem key={key} value={value}><Typography className={classes.menuItem} variant="body1">{label}</Typography></MenuItem>)
                        }
                    </Select>
                    {error ? (<FormHelperText>{errorMessage}</FormHelperText>) : <></>}
                </FormControl>
            )}
        />
    );
};

export default SelectField;
