import React, { ReactElement, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, FormControlLabel, FormHelperText, Switch, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { validateBooleanField } from '../../validation';
import { PropertiesFieldType } from '../../../../types';
import { IconMdInfo } from '../../../../../../Icons';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    root: {
        marginLeft: theme.spacing(0)
    },
    label: {
        ...theme.typography.body2
    },
    infoIcon: {
        width: 20,
        height: 20,
        '& svg': {
            width: 20,
            height: 20,
        }
    },
}));


type Props = PropertiesFieldType & {
    defaultValue?: any;
    disabled?: boolean;
};

const BooleanField = ({ id, name, label, required, tooltip, defaultValue, disabled = false }: Props): ReactElement => {
    const classes = useStyles();
    const { control, errors } = useFormContext();

    const validate = useMemo(() => validateBooleanField({ required }), [required]);

    return (
        <FormControl
            fullWidth
            variant="outlined"
            color="secondary"
            required={required}
            error={!!errors[name]}
        >
            <Box className={`${tooltip ? classes.container : ''}`}>
                <FormControlLabel
                    label={label}
                    disabled={disabled}
                    classes={{ root: classes.root, label: classes.label }}
                    control={
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={defaultValue ? defaultValue : false}
                            render={({ onChange, value }) => (
                                <Switch
                                    id={id}
                                    disabled={disabled}
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    size="small"
                                    color="primary"
                                />
                            )}
                            rules={{
                                validate
                            }}
                        />
                    }
                />
                {tooltip && (
                    <Box className={classes.infoIcon}>
                        <Tooltip enterTouchDelay={50} title={tooltip} aria-label={tooltip}>
                            <IconMdInfo />
                        </Tooltip>
                    </Box>
                )}
                {errors[name] ? (<FormHelperText>{errors[name]?.message}</FormHelperText>) : <></>}
            </Box>
        </FormControl>
    );
};

export default BooleanField;
