import moment from 'moment';
import { lazy } from 'react';
import intl from 'react-intl-universal';
import { History, Location } from 'history';
import Cookies, { CookieAttributes } from 'js-cookie';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
    ActivePeriodType,
    CoverType,
    ExchangeRatesContextType,
    PotentialDateType,
    PricingPackageType,
    SizeType
} from './types';
import { CRYPTO_CURRENCIES, CRYPTO_CURRENCIES_SYMBOLS, FORCED_REFRESH_COOKIE, MAPPED_CURRENCY } from './constants';
import { AnyKeyType } from '../paddock/types';

export const isRelativeUrl = (url: string): boolean => !!url.match(/^\//);
export const isValidDomain = (url: string): boolean => !!url.match(/(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/);

// Retry a promise before rejecting
// eslint-disable-next-line @typescript-eslint/ban-types
export const retry = (fn: Function, retriesLeft = 5, interval = 1500): Promise<any> => new Promise<any>((resolve, reject) => {
    fn()
        .then(resolve)
        .catch((error: Error) => {
            setTimeout(() => {
                if (retriesLeft === 1) {
                    // reject('maximum retries exceeded');
                    reject(error);
                    return;
                }

                // Passing on "reject" is the important part
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
            }, interval);
        });
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const lazyWithRetry = (componentImport) =>
    lazy(async() => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                FORCED_REFRESH_COOKIE
            ) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                FORCED_REFRESH_COOKIE,
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    FORCED_REFRESH_COOKIE,
                    'true'
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });

// Set a cookie
export const setCookie = (cookieName: string, cookieValue: string | boolean, options: CookieAttributes): void => {
    // set the regular cookie
    Cookies.set(cookieName, cookieValue.toString(), options);
};

export const getCookie = (cookieName: string): string | undefined => Cookies.get(cookieName);

export const displayCurrency = (amount: number, currency: string): string => {
    const number = new Intl.NumberFormat(navigator.language, { style: 'currency', currency }).format(amount);

    return `${number} ${currency}`;
};

export function localizeUTCDateTime(dateToLocalize: PotentialDateType, format = 'YYYY-MM-DD HH:mm:ss'): string {
    // Get the string to format
    const convertDate = typeof dateToLocalize === 'string'
        ? dateToLocalize
        : (dateToLocalize && dateToLocalize.toString && dateToLocalize.toString()) || '';
    // Empty, undefined, or unstringable
    if (!convertDate) {
        return '';
    }
    // Format
    return moment.utc(convertDate).local().format(format);
}

// Get the image source url based on a given size. If the size does not exist on the image,
// return back the next available size. If no url is found, return undefined
export const getImageUrl = (image: CoverType | undefined, size: SizeType): string => {
    let selectedUrl: string | undefined = undefined;

    if (image) {
        selectedUrl = image.url;

        if (image.formats) {
            const { thumbnail, small, medium, large } = image.formats;

            if (size === 'thumbnail') {
                if (thumbnail) {
                    selectedUrl = thumbnail.url;
                } else if (small) {
                    selectedUrl = small.url;
                } else if (medium) {
                    selectedUrl = medium.url;
                } else if (large) {
                    selectedUrl = large.url;
                }
            } else if (size === 'small') {
                if (small) {
                    selectedUrl = small.url;
                } else if (medium) {
                    selectedUrl = medium.url;
                } else if (large) {
                    selectedUrl = large.url;
                }
            } else if (size === 'medium') {
                if (medium) {
                    selectedUrl = medium.url;
                } else if (large) {
                    selectedUrl = large.url;
                }
            } else if (size === 'large') {
                if (large) {
                    selectedUrl = large.url;
                }
            }
        }
    }

    return selectedUrl ? selectedUrl : '';
};

/**
 * Provide a Hex colour and a decimal amount to modify the colour by
 *
 * @param {String} hex the colour to modify, example: #FFF or #000000
 * @param {Number} amount the amount to modify it by in decimal values, positive numbers lighten, negative darken
 */
export const modifyColour = (hex: string, amount: number): string => {
    // The value to return
    let darker = hex;
    // Match the hex string (#A1B2C3) R:A1 G:B2 B:C3
    // or (#FFF) R:FF G:FF B:FF
    const hexMatch = hex.match(/#?([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})/i)
        || hex.match(/#?([0-9A-F])([0-9A-F])([0-9A-F])/i);
    if (hexMatch) {
        const [r, g, b] = hexMatch.filter(hex => hex.charAt(0) !== '#').map(hex => {
            // Convert the Hex into Decimal (0-255)
            const decimalColor = parseInt(hex.length === 2 ? hex : `${hex}${hex}`, 16);
            // Add the amount, and convert back into Hex
            const modified = Math.max(0, Math.min(255, decimalColor + amount)).toString(16);
            // Return the Hex
            return modified.length === 2 ? modified : `0${modified}`;
        });
        darker = `#${r}${g}${b}`;
    }
    return darker;
};

export const hexToRGB = (hex: string): string => {
    const match = hex.match(/#?([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})/i);
    if (!match) {
        return '255, 255, 255';
    }
    const [, r, g, b] = match;
    return `${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}`;
};

export const rgbaToHex = (rgba: string): string => {
    const matchHex = rgba.match(/^#?([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})|^#([0-9A-F]{3})/i);

    // A hex was passed in, just return it.
    if (matchHex) {
        return rgba;
    }

    const matchedRGB = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

    if (matchedRGB && matchedRGB.length === 4) {
        return `#${(`0${parseInt(matchedRGB[1], 10).toString(16)}`).slice(-2)}${(`0${parseInt(matchedRGB[2], 10).toString(16)}`).slice(-2)}${(`0${parseInt(matchedRGB[3], 10).toString(16)}`).slice(-2)}`;
    }

    return '#FFFFFF';
};

export const numberFormat = (value: number, currency: string, locale?: string): string => {
    const localeStr = locale ? locale : 'en-US';
    return new Intl.NumberFormat(localeStr, {
        style: 'currency',
        currency
    }).format(value);
};

export const insertUrlParams = (key: string, value: string): void => {
    if (window.history) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
};

export const parseQueryStringValue = (queryString: string | null | undefined): Array<string> => {
    if (queryString && queryString.length > 0) {
        const values = queryString.split(',');
        return values.map((val) => val.trim());
    }

    return [];
};

export const parseQueryNumberValue = (queryString: string | null | undefined, defaultValue: number): number => {
    if (queryString && queryString.length > 0) {
        return parseInt(decodeURI(queryString));
    }

    return defaultValue;
};

export const randomArraySort = (arr: Array<any>): Array<any> => {
    const randomArr = [...arr];

    for (let i = randomArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        const temp = randomArr[i];
        randomArr[i] = randomArr[j];
        randomArr[j] = temp;
    }

    return randomArr;
};

export const scrollToTop = (scrollToId: string): void => {
    const containerBox = document.getElementById(scrollToId);
    const offsetTop = containerBox?.offsetTop;

    if (offsetTop) {
        // eslint-disable-next-line no-restricted-globals
        scroll({
            top: offsetTop - 100,
            behavior: 'smooth'
        });
    }
};

export const checkLocalStorage = (): boolean => {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e: any) {
        return false;
    }
};

const runtimeAuthFunc = () => {
    let jwt = '';

    const setJwt = (_jwt: string) => jwt = _jwt;
    const getJwt = () => jwt;

    return {
        setJwt,
        getJwt
    };
};

export const regexFrom = (strings: Array<string>, flags = 'gi'): RegExp =>
    new RegExp(
        // Escape special characters
        // eslint-disable-next-line no-useless-escape
        strings.map(s => s.replace(/[()[\]{}*+?^$|#.,\/\\\s-]/g, '\\$&'))
            // Sort for maximal munch
            .sort((a, b) => b.length - a.length)
            .join('|'),
        flags
    );

export const removeWords = (text: string, wordsToRemove: Array<string>): string => {
    let modifiedText = text;

    for (let i = 0; i < wordsToRemove.length; i++) {
        const wordToRemove = wordsToRemove[i];
        modifiedText = modifiedText.replace(wordToRemove, '');
    }

    return modifiedText;
};

export const replaceUnderscoreAndLowercase = (text: string): string => text.replace(/_/g, ' ').toLowerCase();

export const setCookieAndLocalStorage = (name: string, value: string): void => {
    if (checkLocalStorage()) {
        localStorage.setItem(name, value);
    } else {
        runtimeAuth.setJwt(value);
    }
};

export const getCookieAndLocalStorage = (name: string): string | null | undefined => {
    let value: string | null | undefined;

    if (checkLocalStorage()) {
        value = localStorage.getItem(name);
    } else {
        value = Cookies.get(name);
    }

    return value;
};

export const localizeCurrency = (value: number | null, currency: string | undefined): string => {
    const cur = currency ? currency : 'USD';
    const loc = MAPPED_CURRENCY[cur.toUpperCase()] ? MAPPED_CURRENCY[cur.toUpperCase()] : 'en-US';
    const val = value ? value : 0;

    const formatter = new Intl.NumberFormat([loc, 'en-US'], {
        style: 'currency',
        currency: cur
    });
    return formatter.format(val);
};

export const propsAsString = (obj: AnyKeyType): string => Object.keys(obj).map((key) => obj[key]).join(', ').replaceAll(', $', '');

export const preciseRoundNumber = (num: number, dec: number): string => {
    const num_sign = num >= 0 ? 1 : -1;

    return (Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec);
};

export const getFormattedActivePeriod = (activePeriod: ActivePeriodType): string | undefined => {
    const { seconds, minutes, hours, days, months, years } = activePeriod;
    const formattedActivePeriodStr = (seconds && `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`)
        || (minutes && `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`)
        || (hours && `${hours} ${hours > 1 ? 'hours' : 'hour'}`)
        || (days && `${days} ${days > 1 ? 'days' : 'day'}`)
        || (months && `${months} ${months > 1 ? 'months' : 'month'}`)
        || (years && `${years} ${years > 1 ? 'years' : 'year'}`);

    if (formattedActivePeriodStr) {
        return formattedActivePeriodStr;
    }

    return undefined;
};

export const maybePluralize = (count: number, noun: string, suffix = 's'): string => `${noun}${count !== 1 ? suffix : ''}`;

export const getValueTextFromPricingPackage = (pricingPackage: PricingPackageType, key: string, label: string): string => {
    const upToText = intl.get('common.pricing.table.upTo.label').d('Up to');
    const noText = intl.get('common.pricing.table.no.label').d('No');

    switch (pricingPackage[key]) {
        case 0:
            // No Video / No Images
            return `${noText} ${maybePluralize(2, label)}`;
        case 1:
            return `${pricingPackage[key]} ${maybePluralize(1, label)}`;
        default:
            return `${upToText} ${pricingPackage[key]} ${maybePluralize(2, label)}`;
    }
};

export const isTouchDevice = (): boolean => 'ontouchstart' in window;

export const runtimeAuth = runtimeAuthFunc();

export const titleToId = (title: string | undefined): string => {
    if (title) {
        // LowerCased, special characters replaced with hyphens
        const identifier = title.toLowerCase().replace(/\W+/g, '-').replace(/-+/g, '-').replace(/-$/, '');
        // If the parsed identifier is not empty
        if (identifier) {
            return identifier;
        }
    }
    // No title provided, give a unique id
    return `id-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
};

export const getCryptoCurrencySymbol = (currency: string): string => {
    const hasCurrency = Object.keys(CRYPTO_CURRENCIES_SYMBOLS).includes(currency as CRYPTO_CURRENCIES_SYMBOLS);

    if (hasCurrency) {
        return CRYPTO_CURRENCIES_SYMBOLS[currency];
    }

    return '';
};

export const getFormattedAmountFromExchange = (baseAmount: number, baseCurrency: string, targetCurrency: string, exchangeRates: ExchangeRatesContextType): string => {
    const isCryptoCurrency = Object.values(CRYPTO_CURRENCIES).includes(targetCurrency as CRYPTO_CURRENCIES);

    const symbol = isCryptoCurrency ? getCryptoCurrencySymbol(targetCurrency) : getSymbolFromCurrency(targetCurrency);

    if (exchangeRates && exchangeRates.length > 0) {
        const foundRates = exchangeRates && exchangeRates.find(currExchangeRate => currExchangeRate.asset_id_base === targetCurrency);

        if (foundRates) {
            const { rates } = foundRates;
            const foundRate = rates && rates.find(currRate => currRate.asset_id_quote === baseCurrency);

            if (foundRate) {
                return `${symbol}${preciseRoundNumber(foundRate.rate * baseAmount, 4)}`;
            }
        }
    }

    return `${symbol}0.00`;
};

export const getItemsFromArray = <T extends unknown>(arr: Array<T>, limit: number): Array<{item: T, originalPosition: number}> => {
    if (arr.length === 0) {
        return [];
    }

    if (limit > arr.length) {
        throw Error('Limit is larger than the length of the array.');
    }

    // clone our array and add their original positions to an obj
    const clonedArrWithPositions = arr.map((item, index) => ({
        item,
        originalPosition: index
    }));

    // return back an array based on the limit provided
    return clonedArrWithPositions.slice(0, limit);
};

export const createQueryParams = <T extends Record<string, unknown>>(params: T, location: Location, history?: History): void => {
    const searchParams = new URLSearchParams(location.search);

    for (const [key, value] of Object.entries(params)) {
        searchParams.set(key, `${value}`);
    }

    if (history) {
        history.replace(`${location.pathname}?${searchParams.toString()}`);
    }
};
