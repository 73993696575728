import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormControlLabel, FormHelperText, Switch, SwitchProps, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconMdInfo } from '../../../../../Icons';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(0)
    },
    label: {
        ...theme.typography.body2
    },
    infoIcon: {
        width: 20,
        height: 20,
        '& svg': {
            width: 20,
            height: 20,
        }
    },
}));

interface Props extends SwitchProps {
    id: string;
    label: string;
    name: string;
    control: any;
    tooltip?: string;
    required?: boolean;
    error?: boolean;
    errorMessage?: string;
}

const SwitchField = ({ label, name, control, id, required, error, errorMessage, tooltip, ...rest }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <FormControl
            fullWidth
            variant="outlined"
            color="secondary"
            required={required}
            error={error}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    classes={{ root: classes.root, label: classes.label }}
                    control={
                        <Controller
                            name={name}
                            control={control}
                            render={({ onChange, value }) => (
                                <Switch
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    size="small"
                                    color="primary"
                                    {...rest}
                                />
                            )}
                        />
                    }
                    label={label}
                />
                {tooltip && (
                    <Box className={classes.infoIcon}>
                        <Tooltip enterTouchDelay={50} title={tooltip} aria-label={tooltip}>
                            <IconMdInfo />
                        </Tooltip>
                    </Box>
                )}
                {error ? (<FormHelperText>{errorMessage}</FormHelperText>) : <></>}
            </Box>
        </FormControl>
    );
};

export default SwitchField;
