import React, { ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from '@sentry/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import ErrorMessage from '../../components/ErrorMessage';
import ExchangeRatesProvider from '../../hooks/useExchangeRatesContext';

const useStyles = makeStyles((theme) => ({
    containerRoot: {
        ...theme.typography.body1
    },
    variantSuccess: {
        backgroundColor: `${theme.palette.success} !important`
    },
    variantError: {
        backgroundColor: `${theme.palette.error} !important`
    },
    variantWarning: {
        backgroundColor: `${theme.palette.warning} !important`
    },
    variantInfo: {
        backgroundColor: `${theme.palette.info} !important`
    }
}));

interface Props {
    children?: ReactNode
}

const ExtraConfigurationWrapper = ({ children }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <ErrorBoundary
            fallback={<ErrorMessage />}
            beforeCapture={(scope) => {
                // Tag the Error boundary to know where it came from
                scope.setTag('location', 'ExtraConfigurationWrapper');
            }}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ExchangeRatesProvider>
                    <SnackbarProvider
                        maxSnack={2}
                        dense
                        classes={{
                            containerRoot: classes.containerRoot,
                            variantSuccess: classes.variantSuccess,
                            variantError: classes.variantError,
                            variantWarning: classes.variantWarning,
                            variantInfo: classes.variantInfo,
                        }}
                    >
                        { children }
                    </SnackbarProvider>
                </ExchangeRatesProvider>
            </LocalizationProvider >
        </ErrorBoundary>
    );
};

export default React.memo(ExtraConfigurationWrapper);
