import React from 'react';
import intl from 'react-intl-universal';
import { Box, Button, Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NOT_FOUND } from '../../constants';

const useStyles = makeStyles((theme) => ({
    videoBtn: {
        ...theme.typography.button,
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '0',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
}));

interface Props {
    value: string;
}

const VideoDisplay = ({ value }: Props): JSX.Element => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let videoSrc = value;
    if (videoSrc && videoSrc.indexOf('youtube.com') !== NOT_FOUND) {
        videoSrc = videoSrc.replace(/watch\?v=/, 'embed/');
    } else {
        videoSrc = '';
    }

    // The available screen width is based on the inner width minus the spacing
    // SPACING: 6 * 8 = 48
    // 6 Representing the number of units, 3 margin for both left and right
    // 8 Representing the number of pixels a spacing unit constitues
    const available = window.innerWidth - 48;

    // The ratio we can multiply the width by to get the height
    const ratio = 0.5625; // Standard 16:9 Resolution
    // Get the width (maximum 1280)
    const width = Math.min(available, 1280);
    // Calculate the height based on the determined width
    const height = width * ratio;

    if (!videoSrc) {
        return (<></>);
    }

    const video = intl.get('directory.featured.video').d('Video');

    return (
        <Box>
            <Button className={classes.videoBtn} onClick={handleOpen} fullWidth>{video}</Button>
            <Dialog onClose={handleClose} open={open} maxWidth={false}>
                <iframe title="embeddedVideo" width={width} height={height} src={videoSrc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Dialog>
        </Box>
    );
};

export default VideoDisplay;
