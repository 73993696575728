import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Box, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CardAddress from '../../../common/components/CardAddress';
// import SellerRating from '../SellerRating';
// import MailToButton from '../../../common/components/MailToButton';
import EmailSellerButton from '../../components/EmailSellerButton';
import WatchlistIconButton from '../../components/WatchlistIconButton';
import SocialLinks from '../../../common/components/SocialLinks';
import { CoverType, UserType } from '../../../common/types';

const useStyles = makeStyles(theme => ({
    box: {
        border: '1px solid #BCBCBC',
        borderRadius: '10px',
        padding: theme.spacing(2, 3)
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        height: '100%'
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(2)
    },
    imageContainer: {
        alignSelf: 'center'
    },
    avatar: {
        width: 200,
        height: 150,
        [theme.breakpoints.down('xl')]: {
            width: 150,
            height: 100,
        }
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        wordWrap: 'break-word'
    },
    website: {
        color: theme.palette.text.primary
    },
    username: {
        display: 'inline',
        fontWeight: 'bold',
    },
    header: {
        // textTransform: 'capitalize',
        fontWeight: 'bold',
        fontFamily: 'Avenir'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(2),
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(2, 0, 0, 0)
        },
    },
    rating: {

    },
    divider: {
        backgroundColor: theme.palette.common.black
    },
    social: {
        marginTop: theme.spacing(4),
        '& > div > a:nth-child(1) > svg': {
            marginLeft: 0,
        }
    }
}));

interface Props {
    image?: CoverType;
    seller: UserType;
    auctionTitle: string;
}

const SellerProfile = ({ seller, auctionTitle }: Props): ReactElement => {
    const classes = useStyles();

    const { username, website, address, email, socialLinks } = seller;

    const forSaleText = intl.get('auction.sellerProfile.forSale').d('For sale by');

    return (
        <Box className={classes.box}>
            <Box className={classes.container}>
                <Box className={classes.topContainer}>
                    <Typography className={classes.header} variant="h1" component="div">
                        {forSaleText}{' '}
                        <Box className={classes.username}>
                            {
                                website ? (
                                    <a rel="noopener noreferrer" target="_blank" href={`//${website}`} className={classes.website}>
                                        {username}
                                    </a>
                                ) : username
                            }
                        </Box>
                    </Typography>
                </Box>
                <Divider className={classes.divider} />
                {
                    socialLinks && (<Box className={classes.social}><SocialLinks socialLinks={socialLinks} /></Box>)
                }
                <Box className={classes.bottomContainer}>
                    <Box className={classes.infoContainer}>
                        {
                            address && (
                                <Box sx={{ mb: 1 }}>
                                    <CardAddress address={address} />
                                </Box>
                            )
                        }
                        {/* TODO: Seller Rating Section */}
                        {/* <Box className={classes.rating}>
                            <SellerRating seller={seller} />
                        </Box> */}
                        <Box className={classes.buttons}>
                            <WatchlistIconButton iconButton={false} />
                            <EmailSellerButton
                                sellerEmail={email}
                                subtitle={auctionTitle}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(SellerProfile);
