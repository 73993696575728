/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
// import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ImageDescriptionLinkType } from '../../types';
import { getImageUrl, isRelativeUrl } from '../../helpers';

const useStyles = makeStyles(theme => ({
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(4, 0)
    },
    container: {
        width: '90%',
        maxWidth: 1000,
        margin: theme.spacing(0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    top: {

    },
    bottom: {
        width: '50%',
        height: 150,
        boxShadow: '0px 3px 10px #00000029',
        backgroundColor: '#FFFFFF',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // do a negative top margin of half the height the box
        marginTop: theme.spacing(-12),
        zIndex: 100,
    },
    content: {
        marginBottom: theme.spacing(2)
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    description: {
        textAlign: 'center'
    },
    linkContainer: {
        border: '2px solid black',
        padding: theme.spacing(1, 0),
        width: '50%'
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    image: {
        width: '100%',
        height: 400,
        objectFit: 'cover'
    },
    bold: {
        fontWeight: 'bold'
    }
}));

type Props = ImageDescriptionLinkType;

interface DynamicLinkProps {
    name: string;
    url: string;
}

const DynamicLink = ({ name, url }: DynamicLinkProps): JSX.Element => {
    const classes = useStyles();
    const isRelative = isRelativeUrl(url);

    // Render the LogoLink
    return isRelative ? (
        <Link id={`dealerService-page-${name}-link`} className={classes.link} to={url}>
            <Typography className={classes.bold} variant="h4">{name}</Typography>
        </Link>
    ) : (
        <a id={`dealerService-page-${name}-link`} className={classes.link} href={url}>
            <Typography className={classes.bold} variant="h4">{name}</Typography>
        </a>
    );
};

const FancyImageDescriptionLink = ({ description, image, linkText, title, url }: Props): JSX.Element => {
    const classes = useStyles();

    const imageSource = getImageUrl(image, 'large');

    return (
        <Box className={classes.section} component="section">
            <Box className={classes.container}>
                <Box className={classes.top}>
                    <img src={imageSource} alt={image?.alternativeText} className={classes.image} />
                </Box>
                <Box className={classes.bottom}>
                    <Box className={classes.content}>
                        <Box className={classes.title}>
                            <Typography className={classes.bold} variant="h2">{title}</Typography>
                        </Box>
                        <Box className={classes.description}>
                            <Typography variant="body1">{description}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.linkContainer}>
                        <DynamicLink name={linkText} url={url} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FancyImageDescriptionLink;
