import React, { ReactElement, useMemo } from 'react';
import intl from 'react-intl-universal';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { PropertiesFieldType } from '../../../../types';
import { validateSelectField } from '../../validation';
import { IconMdInfo } from '../../../../../../Icons';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    infoIcon: {
        width: 20,
        height: 20,
        marginLeft: theme.spacing(1),
        '& svg': {
            width: 20,
            height: 20,
        }
    },
    menuItem: {
        // textTransform: 'capitalize',
        textAlign: 'left'
    },
}));


type Props = PropertiesFieldType & {
    defaultValue?: any;
    disabled?: boolean;
};

const SelectField = ({ id, name, label, required, options, tooltip, defaultValue, placeholder, disabled = false }: Props): ReactElement => {
    const classes = useStyles();
    const { control, errors } = useFormContext();

    const validate = useMemo(() => validateSelectField({ required }), [required]);

    const placeholderText = intl.get('common.field.select.placeholder.none').d('Select an option');

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ''}
            render={(props) => (
                <FormControl
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    required={required}
                    error={!!errors[name]}
                >
                    <InputLabel variant="outlined" required={required} id={`${name}-select-label`}>{label}</InputLabel>
                    <Box className={`${tooltip ? classes.container : ''}`}>
                        <Select
                            {...props}
                            id={id}
                            label={label}
                            labelId={`${name}-select-label`}
                            fullWidth
                            disabled={disabled}
                            required={required}
                            color="secondary"
                            placeholder={placeholder}
                        >
                            <MenuItem value="">
                                <em>{placeholderText}</em>
                            </MenuItem>
                            {
                                options && options.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        <Typography className={classes.menuItem} variant="body1">
                                            {option.name}
                                        </Typography>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        {tooltip && (
                            <Box className={classes.infoIcon}>
                                <Tooltip enterTouchDelay={50} title={tooltip} aria-label={tooltip}>
                                    <IconMdInfo />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    {errors[name] ? (<FormHelperText>{errors[name]?.message}</FormHelperText>) : <></>}
                </FormControl>
            )}
            rules={{
                validate
            }}
        />
    );
};

export default SelectField;
