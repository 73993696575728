import React, { ReactElement, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { IconMdClose, } from '../../../../Icons';
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

// import SquareButton from '../SquareButton';
// import StripeButton from '../StripeButton';
import CryptoPaymentButton from '../../containers/CryptoPaymentButton';
import SquareForm from '../../forms/payment/Square';
import StripeForm from '../../forms/payment/Stripe';

import {
    PaymentParamsBaseType,
    PromotionCodeDataType,
    PromotionCodeType,
    PromotionCodeVariableType
} from '../../types';
import { PAYMENT_PROVIDER, PAYMENT_TYPE } from '../../constants';
import { QUERY_PROMO_CODE } from '../../queries';
import { useNotification, usePaymentProvider } from '../../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    titleBar: {
        position: 'relative',
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.secondary.contrastText,
        fontWeight: 'bold',
        [theme.breakpoints.down('xl')]: {
            fontSize: 14
        }
    },
    iconStyle: {
        color: theme.palette.secondary.contrastText
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

interface Props extends Omit<PaymentParamsBaseType, 'paymentProvider'> {
    onClose: () => void;
}

const PaymentMethodModal = ({ itemId, itemType, amount, pricingPackage, paymentType, onSuccess, onFailure, onClose, isPromoEnabled = true }: Props): ReactElement => {
    const classes = useStyles();
    const notify = useNotification();
    const theme = useTheme();
    const paymentProvider = usePaymentProvider();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [promoCode, setPromoCode] = useState<string | null>(null);
    const [promotion, setPromotion] = useState<PromotionCodeType | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { loading, error, refetch } = useQuery<PromotionCodeDataType, PromotionCodeVariableType>(QUERY_PROMO_CODE, {
        variables: {
            code: promoCode as string,
            pricingPackageId: pricingPackage?.id || ''
        },
        skip: !pricingPackage || !promoCode || !isClicked,
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (error) {
            setErrorMessage(error.message);
        }
    }, [error]);

    const handleClose = () => {
        setPromotion(null);
        onClose();
    };

    const handleClick = () => {
        setErrorMessage(null);
        setPromotion(null);
        if (promoCode) {
            setIsClicked(true);
            refetch && refetch({
                code: promoCode
            }).then((res) => {
                const { promotionByCode } = res.data;

                if (promotionByCode && promotionByCode.id) {
                    setPromotion(promotionByCode);
                    const message = intl.get('common.paymentMethodModal.applied.success').d('Promo code applied');
                    notify({ message, severity: 'success' });
                } else {
                    const noPromotionText = intl.get('common.paymentMethodModal.error.noPromotion').d('Invalid promotion code');
                    setErrorMessage(noPromotionText);
                }
            }).finally(() => {
                setIsClicked(false);
            });
        } else {
            const noPromoText = intl.get('common.paymentMethodModal.error.noPromo').d('Please enter a promotion code');
            setErrorMessage(noPromoText);
        }
    };

    const handleChange = (event) => {
        const val = event.target.value;
        setPromoCode(val);
    };

    const closeText = intl.get('common.icon.close.tooltip').d('Close');
    const titleText = intl.get('common.paymentMethodModal.title').d('Payment');
    const promoCodeText = intl.get('common.paymentMethodModal.promoCode').d('Promo Code');
    const applyText = intl.get('common.paymentMethodModal.button.apply').d('Apply');
    const noPaymentsAvailableText = intl.get('common.paymentMethodModal.noPayments').d('Payment\'s are currently disabled. Please try again later.');

    // Crypto can only be used for one time payments
    const isCryptoEnabled = !!(paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT && process.env.REACT_APP_CRO_PUBLISHABLE_KEY);

    const options = [
        {
            enabled: isCryptoEnabled,
            component: <CryptoPaymentButton
                itemId={itemId}
                itemType={itemType}
                paymentProvider={PAYMENT_PROVIDER.CRYPTO_DOT_COM}
                pricingPackage={pricingPackage}
                paymentType={paymentType}
                onSuccess={onSuccess}
                onFailure={onFailure}
                promotion={promotion}
            />,
            key: 'crypto.com'
        }
    ];

    let paymentForm = (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h3">{noPaymentsAvailableText}</Typography>
        </Box>
    );

    if (paymentProvider === PAYMENT_PROVIDER.SQUARE) {
        paymentForm = (
            <SquareForm
                itemId={itemId}
                itemType={itemType}
                amount={amount}
                paymentProvider={paymentProvider}
                pricingPackage={pricingPackage}
                paymentType={paymentType}
                onSuccess={onSuccess}
                onFailure={onFailure}
                promotion={promotion}
                renderPaymentOptions={options}
            />
        );
    } else if (paymentProvider === PAYMENT_PROVIDER.STRIPE) {
        paymentForm = (
            <StripeForm
                itemId={itemId}
                itemType={itemType}
                amount={amount}
                paymentProvider={paymentProvider}
                pricingPackage={pricingPackage}
                paymentType={paymentType}
                onSuccess={onSuccess}
                onFailure={onFailure}
                promotion={promotion}
                renderPaymentOptions={options}
            />
        );
    }

    return (
        <Dialog onClose={handleClose} open={true} fullWidth fullScreen={isMobile}>
            <DialogTitle className={classes.titleBar}>
                <Typography variant="h2" component="span" className={classes.title}>{titleText}</Typography>
                <Box className={classes.closeContainer}>
                    <IconButton
                        className={classes.iconStyle}
                        onClick={handleClose}
                        aria-label={closeText}
                        size="large">
                        <IconMdClose />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Container>
                    { paymentForm }
                </Container>
            </DialogContent>
            <DialogActions classes={{ root: classes.root }}>
                <Grid container direction="row" justifyContent="center" spacing={3}>
                    {
                        isPromoEnabled && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Box sx={{ width: '100%', mt: 2, display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end' }}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            label={promoCodeText}
                                            margin="normal"
                                            onChange={handleChange}
                                            helperText={errorMessage ? errorMessage : ''}
                                            error={!!errorMessage}
                                        />
                                        {
                                            !loading ? (
                                                <Button variant="text" type="button" onClick={handleClick}>{applyText}</Button>
                                            ) : (
                                                <CircularProgress size={20} />
                                            )
                                        }
                                    </Box>
                                </Grid>
                            </>
                        )
                    }

                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentMethodModal;
