import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { IconMdHome, IconMdExitToApp, } from '../../../../Icons';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useApolloClient } from '@apollo/client';

import { useAuthenticatedUserContext, useBrandConfigurationContext } from '../../../common/hooks';
import { logout } from '../../../common/request';
import { getImageUrl } from '../../../common/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    title: {
        color: theme.palette.secondary.contrastText
    },
    logo: {
        width: '70px',
        maxHeight: '35px',
        objectFit: 'contain',
        pointerEvents: 'none',
    },
    button: {
        ...theme.typography.button,
        color: theme.palette.secondary.contrastText
    }
}));

const Header = (): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const apolloClient = useApolloClient();
    const brandConfig = useBrandConfigurationContext();
    const { setUser } = useAuthenticatedUserContext();

    const { theme: configTheme } = brandConfig;
    const { dashboardLogo } = configTheme.images;
    const dashboardLogoUrl = getImageUrl(dashboardLogo.image, 'small');

    // Navigate to site home
    const handleHome = () => {
        history.push('/');
    };

    const handleLogout = () => {
        logout(apolloClient);
        setUser && setUser(null);
    };

    const homeTooltipText = intl.get('dashboard.tooltip.home').d('Home');
    const logoutTooltipText = intl.get('dashboard.tooltip.logout').d('Logout');

    return (
        <Box className={classes.root}>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ m: 1, width: '40px' }} >
                            <Button className={classes.button} onClick={handleHome} size="small">
                                <img src={dashboardLogoUrl} alt="dashboard logo" className={classes.logo} />
                            </Button>
                        </Box>
                        {/* <Box>
                            <Typography variant="h5" className={classes.title}>{title}</Typography>
                        </Box> */}
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }} >
                        <Tooltip enterTouchDelay={50} title={homeTooltipText} placement="bottom" >
                            <IconButton
                                color="inherit"
                                onClick={handleHome}
                                aria-label={homeTooltipText}
                                size="large">
                                <IconMdHome />
                            </IconButton>
                        </Tooltip>
                        <Tooltip enterTouchDelay={50} title={logoutTooltipText} placement="bottom">
                            <IconButton
                                color="inherit"
                                onClick={handleLogout}
                                aria-label={logoutTooltipText}
                                size="large">
                                <IconMdExitToApp />
                            </IconButton>
                        </Tooltip>
                        {/* <ManageAccount /> */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Header;
