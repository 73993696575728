import React from 'react';
import intl from 'react-intl-universal';
import { Box, Typography, CircularProgress } from '@mui/material';
import { connectStateResults } from 'react-instantsearch-dom';

const NoResults = ({ allSearchResults, isSearchStalled }) => {
    // If there is only one search index, check its number of hits
    if (allSearchResults && allSearchResults.nbHits > 0) {
        return null;
    }
    // Gets all the index names:
    // Example: ["development_racingedge_auction", "development_racingedge_auction-parts"]
    const indexNames = allSearchResults && Object.keys(allSearchResults);
    if (!indexNames) {
        // No indices, don't display.
        return null;
    } else {
        // Sum the results of every index
        let totalHits = 0;
        for (const indexName of indexNames) {
            totalHits += allSearchResults[indexName].nbHits;
        }
        // Check the total hits
        if (totalHits > 0) {
            // If there are results in any index, don't display
            return null;
        }
    }

    // eslint-disable-next-line no-constant-condition
    if (isSearchStalled) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CircularProgress disableShrink color="secondary" size={60} />
            </Box>
        );
    }

    const noResultsTitleText = intl.get('auction.page.home.search.noResults.title').d('There are currently no results for what you\'re looking for');
    // const noResultsHelper1Text = intl.get('auction.page.home.search.noResults.helperText.1').d('Please try:');
    // const noResultsHelper2Text = intl.get('auction.page.home.search.noResults.helperText.2').d('searching again using different words');
    // const noResultsHelper3Text = intl.get('auction.page.home.search.noResults.helperText.3').d('removing your filters');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">{noResultsTitleText}</Typography>
            {/* <Box sx={{ mt: 1 }}>
                <Typography className={classes.bold} variant="h3">{noResultsHelper1Text}</Typography>
                <ul>
                    <li><Typography variant="body1">{noResultsHelper2Text}</Typography></li>
                    <li><Typography variant="body1">{noResultsHelper3Text}</Typography></li>
                </ul>
            </Box> */}
        </Box>
    );
};

export default connectStateResults(NoResults);
