import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: '#f2faf5',
        textAlign: 'center',
        padding: theme.spacing(0, 1)
    },
    label: {
        color: 'limegreen',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xl')]: {
            fontSize: 9
        }
    },
    pill: {
        borderRadius: '25px'
    }
}));

interface Props {
    text: string;
    pill?: boolean;
}

const LimegreenLabel = ({ text, pill = false }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={`${classes.background} ${pill && classes.pill}`}>
            <Typography className={classes.label} variant="overline">{text}</Typography>
        </Box>
    );
};

export default LimegreenLabel;
