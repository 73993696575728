import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Button from '../../../common/components/Button';
import DynamicField from '../../../common/components/DynamicField';

import { AuctionPropertiesType } from '../../types';
import { useWizardContext } from '../../../common/hooks';
import { CarMakeType } from '../../../common/types';
import { useFormDataContext } from '../../../common/hooks/useFormDataContext';
import { useAuctionContext } from '../../hooks/useAuctionContext';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    buttons: {
        margin: theme.spacing(5, 0, 0),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

interface Props {
    auctionProperties?: AuctionPropertiesType;
    makes?: Array<CarMakeType>;
}

const AdditionalDetails = ({ auctionProperties, makes }: Props): JSX.Element => {
    const classes = useStyles();
    const { handleBack, handleNext, activePageIndex, steps } = useWizardContext();
    const { setValues, data } = useFormDataContext();
    const auction = useAuctionContext();

    const allFields = useMemo(() => {
        if (auctionProperties && auctionProperties.fields) {
            const { fields } = auctionProperties;
            // get the foundCategory fields

            return [...fields];
        }
        return [];
    }, [auctionProperties]);

    const methods = useForm();

    const { handleSubmit } = methods;

    const onSubmit = (data) => {
        setValues && setValues(data);
        handleNext && handleNext();
    };

    const nextText = intl.get('auction.auctionWizard.button.next').d('Next');
    const submitText = intl.get('auction.auctionWizard.button.submit').d('Submit');
    const backText = intl.get('auction.auctionWizard.button.back').d('Back');
    const cancelText = intl.get('auction.auctionWizard.button.cancel').d('Cancel');

    return (
        <FormProvider {...methods}>
            <Container>
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction="row" spacing={4} alignItems="center">
                            {
                                allFields.map((field) => {
                                    const { id, enabled, name, size } = field;

                                    if (enabled) {
                                        let defaultValue: any = data ? data[name] : undefined;

                                        if (auction) {
                                            const { dataFields } = auction;
                                            defaultValue = dataFields && dataFields[name] && dataFields[name].value;
                                            if (name === 'make') {
                                                const selectedMake = dataFields && dataFields[name] && makes ? makes.find((m) => m.slug === dataFields[name].value) : undefined;
                                                defaultValue = selectedMake;
                                            }
                                        }
                                        return (
                                            <Grid key={`${id}-${name}`} item {...size}>
                                                <DynamicField
                                                    {...field}
                                                    id={`form-additionalDetails-dynamicField-${name}`}
                                                    defaultValue={defaultValue}
                                                />
                                            </Grid>
                                        );
                                    }

                                    return <></>;
                                })
                            }
                        </Grid>
                        <Box className={classes.buttons}>
                            <Button
                                id="form-additionalDetails-input-back-or-cancel"
                                type="button"
                                variant="contained"
                                color={activePageIndex !== 0 ? 'secondary' : 'primary'}
                                onClick={handleBack}
                                text={activePageIndex !== 0 ? backText : cancelText}
                            />
                            <Button
                                id="form-additionalDetails-input-next-or-submit"
                                color="secondary"
                                variant="contained"
                                type="submit"
                                text={activePageIndex === (steps as number) - 1 ? submitText : nextText}
                            />
                        </Box>
                    </form>
                </div>
            </Container>
        </FormProvider>
    );
};

export default AdditionalDetails;
