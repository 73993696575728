import React from 'react';
import intl from 'react-intl-universal';
import { Box, Button, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { CheckpointIsAuthenticated } from '../../../common/components/checkpoints';

import { PricingPackageType } from '../../../common/types';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold'
    },
    button: {
        ...theme.typography.button,
    },
    box: {
        margin: theme.spacing(1),
        textAlign: 'center',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        padding: theme.spacing(4),
    }
}));

interface Props {
    // Component Properties
    packageInfo: PricingPackageType;
    setPlan: (plan: PricingPackageType) => void;
}

const Plan = ({ packageInfo, setPlan }: Props): JSX.Element => {
    const classes = useStyles();

    // Select plan function
    const handleClick = () => {
        setPlan(packageInfo);
    };

    // Get the title
    const { title } = packageInfo;
    // Default 'Free' if no payments types enabled
    let price = 'Free';
    // Check if any payment types are enabled...
    if (packageInfo.oneTimePayment && packageInfo.oneTimePayment.enabled) {
        // One time payment
        price = `${packageInfo.currency} $${packageInfo.oneTimePayment.price}`;
    } else if (packageInfo.monthlyPayment && packageInfo.monthlyPayment.enabled) {
        // Monthly payment
        price = `${packageInfo.currency} $${packageInfo.monthlyPayment.price}/month`;
    } else if (packageInfo.yearlyPayment && packageInfo.yearlyPayment.enabled) {
        // Yearly payment
        price = `${packageInfo.currency} $${packageInfo.yearlyPayment.price}/year`;
    }

    const selectText = intl.get('directory.plan.button.select').d('Select');

    return (
        <Box className={classes.box}>
            <Box sx={{ mb: 1 }}>
                <Typography variant="h1" className={classes.title}>{title}</Typography>
            </Box>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h3" color="primary">{price}</Typography>
            </Box>
            {packageInfo.sellingPoints && packageInfo.sellingPoints.map((sellingPoint) => (
                <Box sx={{ mb: 1 }} key={sellingPoint.text}>
                    <Box sx={{ mb: 1 }}>
                        <Typography variant="body1">{sellingPoint.text}</Typography>
                    </Box>
                    <Divider />
                </Box>
            ))}
            <Box sx={{ mt: 6 }}>
                <CheckpointIsAuthenticated>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={handleClick}>
                        {selectText}
                    </Button>
                </CheckpointIsAuthenticated>
            </Box>
        </Box>
    );
};

export default Plan;
