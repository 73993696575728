import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import intl from 'react-intl-universal';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Box,
    Breadcrumbs as MUIBreadcrumbs,
    Link,
    Typography
} from '@mui/material';
import { PADDOCK_LINK } from '../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
        ...theme.typography.body1
    },
    active: {
        fontWeight: 'bold'
    }
}));

const Breadcrumbs = (): JSX.Element => {
    const classes = useStyles();
    const { pathname } = useLocation();
    let pathnames = pathname.split('/').filter((x) => x);
    let basePath = '/';

    let homeText = intl.get('common.breadcrumb.item.home').d('Home');
    const breadcrumbNameMap: { [key: string]: string } = {
        [`/${PADDOCK_LINK}`]: intl.get('common.breadcrumb.item.paddock').d('Paddock'),
        [`/${PADDOCK_LINK}/group`]: intl.get('common.breadcrumb.item.listingGroup').d('Listing Group'),
    };

    // CMS Breadcrumbs, custom home text and base path
    if (pathnames.length >= 1 && pathnames[0] === 'cms') {
        // remap home to take you to the cms home
        homeText = intl.get('common.breadcrumb.item.cms').d('CMS Dashboard');
        basePath = '/cms/';
        // remove the /cms/ entry as the home has been remapped to navigate there
        pathnames.splice(0, 1);
    // Check Media pathnames
    // available media pathnames need to be displayed in Breadcrumbs:
    // 'Media', 'Articles', ${articleName}, ${tagName}, ${categoryName}
    // so that we need to filter out ['tag', 'category', ${articleId}]
    } else if (pathnames.length >= 1 && pathnames[0] === 'media') {
        pathnames = pathnames.filter((pathname) => ((pathname !== 'tag') && (pathname !== 'category')));
    // Check for paddock pathnames
    // Change 'group' to 'Listing Group' and remove listingGroupId parameter
    } else if (pathnames[0] === PADDOCK_LINK) {
        pathnames = pathnames.reduce((acc: string[], item, index) => {
            if (pathnames[index - 1] === 'group' && index === pathnames.length - 1) {
                return acc;
            }
            return [...acc, ...[item]];
        }, []);
    } else if (pathnames[0] === 'page') {
        pathnames = pathnames.filter((pathname) => pathname !== 'page');
    } else if (pathnames[0] === 'directory') {
        pathnames = pathnames.filter((pathname) => pathname !== 'category');
    } else if (pathnames[0] === 'reset-password') {
        // If we are at /reset-password/random-password-reset-code
        // We do not want the code shown as a breadcrumb
        pathnames = ['reset-password'];
    }

    return (
        <Box sx={{ mt: 2 }}>
            <MUIBreadcrumbs aria-label="breadcrumb">
                <Link id="breadcrumb-link-home" className={classes.root} component={RouterLink} to={basePath}>
                    {homeText}
                </Link>
                {
                    pathnames.map((value, index) => {
                        let isLinkable = true;
                        let isActive = false;
                        const to = `${basePath}${pathnames.slice(0, index + 1).join('/')}`;
                        const displayName = breadcrumbNameMap[to] || value.replace(/-|_/g, ' ');

                        // this is the last path in the breadcrumb
                        if (index === pathnames.length - 1) {
                            isLinkable = false;
                            isActive = true;
                        } else if (value === 'lot') {
                            isLinkable = false;
                        }

                        return !isLinkable ? (
                            <Typography variant="h5" className={`${classes.root} ${isActive ? classes.active : ''}`} key={to}>
                                { displayName }
                            </Typography>
                        ) : (
                            <Link id={`breadcrumb-link-${value}`} variant="h6" className={classes.root} component={RouterLink} to={to} key={to} >
                                { displayName }
                            </Link>
                        );
                    })
                }
            </MUIBreadcrumbs>
        </Box>
    );
};

export default Breadcrumbs;
