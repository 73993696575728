import React from 'react';
import intl from 'react-intl-universal';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from '@mui/material';

import {
    IS_BID_VIN,
    IS_EXOTIC_CAR_AUCTIONS,
    IS_RCLUB,
    NO_LOGIN_WHITELABEL,
    TEMPLATE_TYPE
} from '../../constants';
import radical_logo from '../../assets/images/logos/logo-radical.png';
import re_icon_logo from '../../assets/logo.svg';
import nx_logo from '../../assets/images/nxgem-logo.png';
// import logoMP4 from '../../assets/images/animated-logo.mp4';
// import logoWebM from '../../assets/images/animated-logo.webm';
import logo from '../../assets/images/animated-logo.gif';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 999999,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    svg: {
        margin: 'auto',
        display: 'block',
        width: '200px',
        height: '200px',
    },
    bar: {
        width: '10px',
        height: '40px',
    },
    image: {
        maxWidth: '500px',
    },
    embeddedImage: {
        maxHeight: '75px',
        maxWidth: '250px',
        animationName: '$fadeLogo',
        animationDuration: '1s',
    },
    '@keyframes fadeLogo': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.65 },
        '100%': { opacity: 1 }
    },
}));

type Props = {
    name?: string;
}

const AnimatedLoader = ({ name }: Props): JSX.Element => {
    const classes = useStyles();

    const loadingText = intl.get('generic.alt.loading').d('Loading...');

    // Disabled video as on some browsers it was being stopped and flickered through
    // let loadingElement = (
    //     <video autoPlay loop muted className={classes.image}>
    //         <source src={logoWebM} type="video/webm" />
    //         <source src={logoMP4} type="video/mp4" />
    //         {loadingText}
    //     </video>
    // );

    let loadingElement = (
        <div className={classes.root}>
            <img className={classes.image} src={logo} alt={loadingText} />
        </div>
    );

    if (TEMPLATE_TYPE === 'NX_GEM') {
        loadingElement = <img className={classes.image} src={nx_logo} alt={loadingText} />;
    }

    const GENERIC_LOADER = IS_RCLUB
        || IS_EXOTIC_CAR_AUCTIONS
        || IS_BID_VIN;

    if (NO_LOGIN_WHITELABEL || GENERIC_LOADER) {
        loadingElement = <CircularProgress />;
    }

    // If this is an embedded page, display only the logo (without text)
    const _window: any = window;
    if (_window.__embedded === true) {
        let loadingLogoSrc = re_icon_logo;
        if (_window.__embedded_loader === 'radical') {
            loadingLogoSrc = radical_logo;
        }
        loadingElement = <img className={classes.embeddedImage} src={loadingLogoSrc} alt={loadingText} />;
    }

    // If this Loader component is being rendered by the ThemeWrapper
    // keep it on top of the others with a greater zIndex to prevent
    // the loading screen from flickering
    const extraStyle: any = {};
    if (name === 'ThemeWrapper') {
        extraStyle.zIndex = 1000000;
    }

    return (
        <div className={classes.root} style={extraStyle}>
            {loadingElement}
        </div>
    );
};

export default React.memo(AnimatedLoader);
