import React, { useEffect, useState, ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions, Appearance, Stripe } from '@stripe/stripe-js';


import Button from '../../../common/components/Button';
import ManageStripePaymentMethodsDialog from '../../containers/ManageStripePaymentMethodsDialog';
import ManageSquarePaymentMethodsDialog from '../../containers/ManageSquarePaymentMethodsDialog';

import { BRAND, PAYMENT_PROVIDER } from '../../../common/constants';
import { createSetupIntent } from '../../../common/request';
import { BasicIntentOptionsType } from '../../../common/types';
import { useIsMountedRef, useNotification, usePaymentProvider, usePaymentConfigurationContext } from '../../../common/hooks';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
}));

const ManagePaymentMethods = (onSuccess?: any): ReactElement => {
    const classes = useStyles();
    const notify = useNotification();
    const { stripePublishableKey } = usePaymentConfigurationContext();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(new Promise(() => null));
    const paymentProvider = usePaymentProvider();
    const isMountedRef = useIsMountedRef();
    const [open, setOpen] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (stripePublishableKey) {
            const promise = loadStripe(stripePublishableKey);
            setStripePromise(promise);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMountedRef.current) {
            if (paymentProvider === PAYMENT_PROVIDER.STRIPE) {
                const paymentIntentProps: BasicIntentOptionsType = {
                    paymentProvider,
                    brand: BRAND,
                    isBasic: true,
                    saveCard: true,
                    useSavedCard: false,
                };

                createSetupIntent(paymentIntentProps)
                    .then(({ clientSecret }) => {
                        if (clientSecret) {
                            setClientSecret(clientSecret);
                        } else {
                            const message = intl.get('common.form.elements.paymentIntent.clientSecret.error').d('Something went wrong. Please try again.');
                            notify({ message, severity: 'error' });
                        }
                    })
                    .catch((err) => {
                        const { message } = err;
                        notify({ message, severity: 'error' });
                    });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef, paymentProvider]);

    const handleSuccess = () => {
        const message = intl.get('dashboard.profile.managePaymentMethods.saved.success').d('You\'re card has been saved.');
        notify({ message, severity: 'info' });
        setOpen(false);
        onSuccess && typeof onSuccess.onSuccess === 'function' && onSuccess.onSuccess();
    };

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const managePaymentMethodsText = intl.get('dashboard.profile.button.managePaymentMethods').d('Manage Payment Methods');

    const appearance: Appearance = {
        theme: 'stripe',
    };

    const options: StripeElementsOptions = {
        clientSecret,
        appearance
    };

    let dialogJSX = (
        <ManageSquarePaymentMethodsDialog
            onClose={handleDialogClose}
            onSuccess={handleSuccess}
        />
    );

    if (paymentProvider === PAYMENT_PROVIDER.STRIPE) {
        dialogJSX = (
            <Elements options={options} stripe={stripePromise}>
                <ManageStripePaymentMethodsDialog
                    onClose={handleDialogClose}
                    onSuccess={handleSuccess}
                />
            </Elements>
        );
    }

    return (
        <Box className={classes.root}>
            <Button
                id="dashboard-profile-button-managePaymentMethods"
                color="secondary"
                text={managePaymentMethodsText}
                onClick={handleDialogOpen}
            />
            {
                open && dialogJSX
            }
        </Box>
    );
};

export default React.memo(ManagePaymentMethods);
