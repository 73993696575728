import React, { useState, useCallback } from 'react';
import intl from 'react-intl-universal';
import { Grid, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';

const useStyles = makeStyles(theme => ({
    input: {
        ...theme.typography.body2,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '& .MuiFormHelperText-root': {
            ...theme.typography.subtitle1
        },
        [theme.breakpoints.down('xl')]: {
            paddingBottom: theme.spacing(0),
        }
    },
    button: {
        ...theme.typography.button,
        fontWeight: 'bold',
        minHeight: '53px',
        maxHeight: '53px',
        height: '100%',
        borderRadius: '10px'
    },
    formContainer: {
        width: '100%'
    }
}));

type Props = SearchBoxProvided;

const SearchBox = ({ refine, currentRefinement, isSearchStalled }: Props): JSX.Element => {
    const classes = useStyles();
    const [search, setSearch] = useState<string>(currentRefinement);

    const handleOnChange = useCallback((e) => {
        setSearch(e.target.value);
    }, [setSearch]);

    const handleOnSubmit = (event) => {
        event.preventDefault();
        refine(search);
    };

    const placeholderText = intl.get('generic.placeholder.lookingFor').d('What are you looking for?');
    const searchText = intl.get('generic.button.submit.search').d('Search');

    return (
        <form className={classes.formContainer} onSubmit={handleOnSubmit}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={9} lg={10}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={placeholderText}
                        value={search}
                        onChange={handleOnChange}
                        color="secondary"
                        className={classes.input}
                    />
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <Button className={classes.button} disabled={isSearchStalled} fullWidth variant="contained" color="primary" type="submit">{searchText}</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default React.memo(connectSearchBox(SearchBox));
