import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import { Box, Container, Dialog, IconButton, Slide, Tooltip } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { IconMdClose, } from '../../../../Icons';
import { Configure, InstantSearch } from 'react-instantsearch-core';

import FilterList from '../FilterList';
import PageTitle from '../../../common/components/PageTitle';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    const { children, ...otherProps } = props;
    return <Slide direction="up" ref={ref} {...otherProps} ><>{ children }</></Slide>;
});

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    searchClient: any;
    searchState: any;
    indexName: string;
    onSearchStateChange: (...args: any[]) => any;
    createURL: (state: Record<string, any>) => string;
    fullScreen?: boolean;
    hitsFilter: string;
    hitsPerPage: number;
}

const FilterListDialog = ({ open, setOpen, searchClient, searchState, onSearchStateChange, indexName, createURL, fullScreen = false, hitsFilter, hitsPerPage }: Props): ReactElement => {
    const filterTitle = intl.get('auction.page.home.search.filters.title.mobile').d('Filter');
    const closeToolTip = intl.get('common.icon.close.tooltip').d('Close');

    return (
        <Dialog fullScreen={fullScreen} open={open} TransitionComponent={Transition} maxWidth="xl" >
            <Container>
                <InstantSearch
                    indexName={indexName}
                    searchClient={searchClient}
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                    createURL={createURL}
                >
                    <Configure
                        hitsPerPage={hitsPerPage}
                        filters={hitsFilter}
                    />
                    <Box sx={{ mb: 2, minWidth: 300 }}>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box>
                                <PageTitle label={filterTitle} />
                            </Box>
                            <Tooltip enterTouchDelay={50} title={closeToolTip}>
                                <IconButton
                                    onClick={(): void => setOpen(false)}
                                    aria-label={closeToolTip}
                                    size="large">
                                    <IconMdClose />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <FilterList onClose={() => setOpen(false)} aria-label={closeToolTip} />
                    </Box>
                </InstantSearch>
            </Container>
        </Dialog>
    );
};

export default FilterListDialog;
