import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    title: {
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        // // textTransform: 'uppercase',
        WebkitLineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        maxWidth: 400,
        [theme.breakpoints.down('xl')]: {
            height: 35,
            maxWidth: 225,
            fontSize: 14
        }
    },
}));

interface Props {
    text: string;
}

const CardTitle = ({ text }: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <Typography
            className={classes.title}
            component="h3"
            align="left"
            variant="h4"
        >
            {text}
        </Typography>
    );
};

export default CardTitle;
