import React, { useCallback, useMemo, useRef, ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import intl from 'react-intl-universal';
import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import LG from 'lightgallery/react';
import { LightGallery } from 'lightgallery/lightgallery';
import { GalleryItem } from 'lightgallery/lg-utils';
import { InitDetail } from 'lightgallery/lg-events';
// import gallery styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
// import light gallery plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullScreen from 'lightgallery/plugins/fullscreen';

import { getImageUrl, getItemsFromArray } from '../../../common/helpers';
import { AuctionType } from '../../types';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
            '& > * + *': {
                margin: theme.spacing(1, 0, 0, 0)
            },
        }
    },
    galleryContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'row',
            '& > * + *': {
                margin: theme.spacing(1, 0, 0, 0)
            },
        }
    },
    galleryItem: {
        width: '100%',
        maxWidth: '32%',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '100%',
        }
    },
    clickable: {
        cursor: 'pointer',
        position: 'relative',
        '& span': {
            opacity: 1,
            color: '#fff',
            whiteSpace: 'nowrap',
            // fontSize: 18,
            fontWeight: 'bold',
            // // textTransform: 'uppercase',
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: 'center',
            zIndex: 2,
            [theme.breakpoints.down('xl')]: {
                fontSize: 9,
                whiteSpace: 'unset',
                top: '50%',
            }
        },
    },
    tint: {
        position: 'absolute',
        // Keep spacing the same as the grid! If you change it, change this to match!
        top: theme.spacing(1),
        left: theme.spacing(1),
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    imageItem: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    hero: {
        width: '100%',
        height: '100%'
    },
    sections: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // Lobotomized Owl selector. Gap isn't supported fully yet, so we are using this selector instead to mimic a gap
        '& > * + *': {
            margin: theme.spacing(0, 0, 0, 1)
        },
        [theme.breakpoints.down('xl')]: {
            '& > * + *': {
                margin: theme.spacing(0, 0, 0, 1)
            },
            marginTop: theme.spacing(2),
        }
    },
    sectionItem: {
        cursor: 'pointer',
        margin: theme.spacing(0, 2)
    },
    sectionText: {
        fontWeight: 'bold',
        color: '#999',
        // // textTransform: 'uppercase',
        '&:hover': {
            color: 'white'
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '12px !important'
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '10px !important'
        }
    }
}));


interface Props {
    auction: AuctionType;
}

const LG_LICENSE_KEY = process.env.REACT_APP_LIGHTGALLERY_JS_LICENSE_KEY || '0000-0000-000-0000';

const AuctionGallery = ({ auction }: Props): ReactElement => {
    const classes = useStyles();
    const lightGalleryRef = useRef<LightGallery | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    const { exterior, interior, mechanical, documents, slug } = auction;

    // the number of images displayed initially before launching the gallery
    const GALLERY_DISPLAYED_IMAGES_LENGTH = isMobile ? 6 : 9;

    const galleryId = `gallery-${slug}`;

    // Section positions within a gallery
    const secExterior = 0;
    const secInterior = exterior?.length || 0;
    const secMechanical = secInterior + (interior?.length || 0);
    const secDocuments = secMechanical + (mechanical?.length || 0);

    const allImages = useMemo(() => {
        // if we have exterior images and more than 0, remove the first item in the array.
        // the reason we are doing this is that we are using the first item in exterior images as the hero
        const modifiedExterior = exterior && exterior.length > 0 && exterior.slice(1);
        return [
            ...(modifiedExterior || []),
            ...(interior || []),
            ...(mechanical || []),
            ...(documents || [])
        ];
    }, [documents, exterior, interior, mechanical]);

    const hasGallery = (allImages.length > GALLERY_DISPLAYED_IMAGES_LENGTH);

    // generate dynamic markup for all of our images to be used in the gallery
    const dynamicItems: Array<GalleryItem> = useMemo(() => allImages.map((image) => {
        const imgSrc = getImageUrl(image, 'original');
        const imgThumb = getImageUrl(image, 'small');

        const galleryItem: GalleryItem = {
            src: imgSrc,
            thumb: `${imgThumb}?fit=fillmax&auto=format.compress`,
        };

        return galleryItem;
    }), [allImages]);

    // our array of images to display before going into the light gallery
    const displayImages = useMemo(() => {
        try {
            if (hasGallery) {
                return getItemsFromArray(allImages, GALLERY_DISPLAYED_IMAGES_LENGTH);
            } else {
                return allImages;
            }
        } catch (e: any) {
            // eslint-disable-next-line
            console.log('displaying images', e);
        }

        return [];
    }, [GALLERY_DISPLAYED_IMAGES_LENGTH, allImages, hasGallery]);

    const onInit = useCallback((detail: InitDetail) => {
        if (detail) {
            lightGalleryRef.current = detail.instance;
        }
    }, []);

    const handleClick = (index?: number) => {
        if (lightGalleryRef && lightGalleryRef.current) {
            lightGalleryRef.current.openGallery(index || 0);
        }
    };

    const moveToSlide = (slide: number) => {
        if (lightGalleryRef && lightGalleryRef.current) {
            lightGalleryRef.current.slide(slide);
        }
    };

    const handleOnAfterOpen = () => {
        const toolbarId = 'toolbar-sections';

        const sectionsJSX: ReactElement | null = (
            <Box id={toolbarId} className={classes.sections}>
                <Box className={classes.sectionItem}>
                    <Box id="section__exterior">
                        <Typography variant="h6" className={classes.sectionText}>Exterior</Typography>
                    </Box>
                </Box>
                <Box className={classes.sectionItem}>
                    <Box id="section__interior">
                        <Typography variant="h6" className={classes.sectionText}>Interior</Typography>
                    </Box>
                </Box>
                <Box className={classes.sectionItem}>
                    <Box id="section__mechanical">
                        <Typography variant="h6" className={classes.sectionText}>Mechanical</Typography>
                    </Box>
                </Box>
                <Box className={classes.sectionItem}>
                    <Box id="section__documents">
                        <Typography variant="h6" className={classes.sectionText}>Documents</Typography>
                    </Box>
                </Box>
            </Box>
        );

        const lgToolbarClassName = 'lg-toolbar';
        const toolbars = document.getElementsByClassName(lgToolbarClassName);
        // eslint-disable-next-line prefer-destructuring
        const toolbar = toolbars[0];
        const doesExist = document.getElementById(toolbarId);

        if (toolbar && !doesExist) {
            // Convert our jsx into html string
            const renderedJSX = ReactDOMServer.renderToString(sectionsJSX);
            // append our html string to the 3rd party html
            toolbar.insertAdjacentHTML('beforeend', renderedJSX);

            // attach our click events since renderToString doesn't attach any click events
            document.getElementById('section__exterior')?.addEventListener('click', () => moveToSlide(secExterior));
            document.getElementById('section__interior')?.addEventListener('click', () => moveToSlide(secInterior));
            document.getElementById('section__mechanical')?.addEventListener('click', () => moveToSlide(secMechanical));
            document.getElementById('section__documents')?.addEventListener('click', () => moveToSlide(secDocuments));
        }
    };

    const viewAllText = intl.get('auction.gallery.viewAll').d('View All Images');

    return (
        <React.Fragment key={auction.id}>
            {/* Gallery */}
            {
                (displayImages && displayImages.length > 1) && (
                    <Grid container spacing={1}>
                        {
                            displayImages.map((dItem, index, arr) => {
                                if (hasGallery) {
                                    const { item: dImage, originalPosition } = dItem;
                                    const { id, alternativeText } = dImage;
                                    const dImageSrc = getImageUrl(dImage, 'small');
                                    const isLastItem = arr.length - 1 === index;
                                    return (
                                        <Grid item xs={6} md={4} key={`${id}-i-${index}`} className={`${classes.clickable}`} onClick={() => {
                                            handleClick(originalPosition);
                                        }}>
                                            <img className={classes.imageItem} src={dImageSrc} alt={alternativeText} />
                                            {
                                                isLastItem && (
                                                    <>
                                                        <Box className={classes.tint} />
                                                        <Typography variant="h3" component="span">{`${viewAllText} >`}</Typography>
                                                    </>
                                                )
                                            }
                                        </Grid>
                                    );
                                } else {
                                    const { id, alternativeText } = dItem;
                                    const dImageSrc = getImageUrl(dItem, 'small');

                                    return (
                                        <Grid item xs={6} md={4} key={`${id}-i-${index}`}>
                                            <img className={classes.imageItem} src={dImageSrc} alt={alternativeText} />
                                        </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                )
            }
            <LG
                galleryId={galleryId}
                plugins={[lgThumbnail, lgZoom, lgFullScreen]}
                onInit={onInit}
                onAfterOpen={handleOnAfterOpen}
                speed={500}
                download={false}
                closeOnTap={true}
                closable={true}
                dynamic={true}
                dynamicEl={dynamicItems}
                licenseKey={LG_LICENSE_KEY}
            />
        </React.Fragment>
    );
};

export default React.memo(AuctionGallery);
