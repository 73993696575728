import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import NumberFormat from 'react-number-format';

type Props = TextFieldProps & {
    id: string;
    label: string;
    name: string;
    control: any;
    prefix?: string;
}

const PriceField = ({ label, name, control, id, ref, defaultValue, type, size, prefix = '$', ...rest }: Props): JSX.Element => (
    <Controller
        render={({ onChange, value }) => (
            <NumberFormat
                id={id}
                allowNegative={false}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator
                onValueChange={(v) => onChange(v.floatValue)}
                prefix={prefix}
                value={value}
                label={label}
                customInput={TextField}
                {...rest}
            />
        )}
        name={name}
        control={control}
    />
);

export default PriceField;
